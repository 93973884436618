import * as React from "react";
import { TextInputProps } from "grommet";
import { StyledTextInput } from "../../styles/Inputs";
import { toCurrency } from "../../utils/currency-conversion";

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  onStateChange?: (arg1: string) => void;
  value?: string;
  separator?: "." | ",";
}

const CurrencyInput: React.FunctionComponent<TextInputProps & Props> = (
  props,
) => {
  const { onStateChange, value = "", separator = ".", ...inputProps } = props;
  const [localValue, setValue] = React.useState<string>(value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valueAsCurrency = toCurrency(event.target.value, separator);

    setValue(valueAsCurrency);

    if (onStateChange) {
      onStateChange(valueAsCurrency);
    }
  };

  return (
    <StyledTextInput
      type="text"
      pattern="[0-9]+([,\.][0-9]+)?"
      size="small"
      {...inputProps}
      value={localValue}
      onChange={handleChange}
      step="any"
      min="0"
    />
  );
};

export default CurrencyInput;

import React from "react";
import { FieldArray, Field, FieldProps } from "formik";
import { Text, Box } from "grommet";
import { Header, AddFieldButton, TrashButton } from "../../components";
import {
  StyledLabel,
  StyledTextInput,
  StyledSelect,
} from "../../styles/Inputs";
import { Wrapper, RowBox } from "../../styles/Wrappers";
import { unquantifiableValueOptions } from "@rr/utils/calculator/groups";
import fieldTypes from "../../data/fieldTypes";
import { unquantifiableValuePlaceholders } from "../../data/placeholders";

interface Props {
  values?: {
    [key: string]: string;
  }[];
}

const UnquantifiableValue: React.FunctionComponent<Props> = React.memo(
  (props) => {
    const { values } = props;
    return (
      <Wrapper>
        <Header
          heading="Unquantifiable Value"
          size="medium"
          level={4}
          amount={""}
        />
        <Text
          margin={{ vertical: "1rem" }}
          size="small"
          dangerouslySetInnerHTML={{
            __html:
              "There may remain some <u>business considerations</u> that you are unable to quantify monetarily, but rather have a long-term value for your organization.  Fill in your own based on the project you are considering.  Then, select the weight of business value of these factors from the drop down menu.",
          }}
        />
        <FieldArray name="unquantifiableValue">
          {(arrayHelpers) => (
            <React.Fragment>
              {values &&
                values.length > 0 &&
                values.map((value, index) => (
                  <div key={index}>
                    <RowBox>
                      <Box width="80%">
                        <StyledLabel
                          display={index !== 0 ? "none" : "flex"}
                          htmlFor="description"
                        >
                          {"Description"}
                        </StyledLabel>
                      </Box>
                      <Box width="20%">
                        <StyledLabel
                          display={index !== 0 ? "none" : "flex"}
                          htmlFor="valueWeight"
                        >
                          {"Value Weight"}
                        </StyledLabel>
                      </Box>
                    </RowBox>
                    <RowBox>
                      <TrashButton onClick={() => arrayHelpers.remove(index)} />
                      <Box width="80%" margin={{ right: ".35rem" }}>
                        <Field
                          name={`unquantifiableValue.${index}.description`}
                          render={({ field }: FieldProps) => (
                            <StyledTextInput
                              {...field}
                              placeholder={
                                unquantifiableValuePlaceholders[index] || ""
                              }
                              id="description"
                              size="small"
                              type="text"
                            />
                          )}
                        />
                      </Box>
                      <Box width="20%">
                        <Field
                          name={`unquantifiableValue.${index}.valueWeight`}
                          render={({ field }: FieldProps) => (
                            <StyledSelect
                              {...field}
                              options={unquantifiableValueOptions}
                              id="valueWeight"
                              size="small"
                              placeholder="Select"
                              onChange={(e) => {
                                field.onChange(e);
                                arrayHelpers.replace(index, {
                                  ...value,
                                  valueWeight: e.value,
                                });
                              }}
                            />
                          )}
                        />
                      </Box>
                    </RowBox>
                  </div>
                ))}
              {values && (
                <AddFieldButton
                  onClick={() =>
                    arrayHelpers.push(
                      fieldTypes["implementationAndMaintenance"],
                    )
                  }
                  text="Add unquantifiable value"
                />
              )}
            </React.Fragment>
          )}
        </FieldArray>
      </Wrapper>
    );
  },
);

UnquantifiableValue.displayName = "UnquantifiableValue";

export default UnquantifiableValue;

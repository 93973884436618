import { FaExclamationCircle, FaCheckCircle } from "react-icons/fa";

import ReadyImage from "frontend-app/src/images/img-ora-youre-ready.svg";
import AlmostThereImage from "frontend-app/src/images/img-ora-almost-there.svg";
import WorkToDoImage from "frontend-app/src/images/img-ora-work-to-do.svg";

export const assessmentMap = {
  "You're ready": {
    icon: FaCheckCircle,
    color: "#4BAE8B",
    sideImage: ReadyImage,
    shortBlurb:
      "Congratulations! Your answers indicate that you are ‘ready’ to achieve success in your pro bono endeavors.",
    topBlurb:
      "Congratulations! Your answers indicate that you are ‘ready’ to achieve success in your pro bono endeavors. Review your results in the key areas below to understand the benchmarks that contribute to your readiness.",
    sideBlurb:
      "You’re in a great place to take on a skills-based volunteering project! Next step is to identify which project will yield the most impact for your organization.",
  },
  "Almost there": {
    icon: FaExclamationCircle,
    color: "#E6A161",
    sideImage: AlmostThereImage,
    shortBlurb:
      "You’re headed in the right direction, but would benefit from addressing a few dimensions of readiness before diving into a pro bono project.",
    topBlurb:
      "You’re headed in the right direction, but would benefit from addressing a few dimensions of readiness before diving into pro bono. You’re not alone - many organizations fall into this category. Review your scores across the different benchmarks to determine areas of focus and utilize the provided resources to help get you to a “ready” place. You might consider utilizing an <a target='_blank' href='https://www.capacitycommons.org/guidebook/prepare/organizational-readiness-consultant-job-description/'>external consultant</a>  to support you in your preparation efforts.\n\nOnce your organization has taken some time to strengthen your readiness across key dimensions and you feel ready to dive back in, feel free to take the assessment again or proceed to <a target='_blank' href='https://capacitycommons.org/guidebook/scope/shape-your-project/'>identify your project</a>. ",
    sideBlurb:
      "Your organization would benefit from some additional preparation to ensure success on your pro bono endeavors. Read on below for tips and resources.",
  },
  "Work to do": {
    icon: FaExclamationCircle,
    color: "#C35D5C",
    sideImage: WorkToDoImage,
    shortBlurb:
      "It looks like your organization is not yet ready to get the most out of pro bono support. We recommend you address some key dimensions of readiness before diving into a pro bono project.",
    topBlurb:
      "It looks like your organization is not yet ready to get the most out of pro bono support. You’re not alone - many organizations fall into this category. This tool does not evaluate the strength of your organization, but rather the factors that lead to successful skilled volunteer engagements. Review your scores across the different benchmarks to determine areas of focus, then utilize the provided resources to help get to a “ready” place. You might consider utilizing an <a target='_blank' href='https://www.capacitycommons.org/guidebook/prepare/organizational-readiness-consultant-job-description/'>external consultant</a> to support you in your preparation efforts.\n\nOnce your organization has taken some time to strengthen your overall stability and you are ready to dive back in, feel free to take the assessment again or proceed to <a target='_blank' href='https://capacitycommons.org/guidebook/scope/shape-your-project/'>identify your project</a>",
    sideBlurb:
      "We advise you to conduct additional preparation before starting a pro bono project to increase your likelihood of success.",
  },
};

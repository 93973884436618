// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/build/packages/frontend-app/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("/build/packages/frontend-app/src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-auth-tsx": () => import("/build/packages/frontend-app/src/pages/auth.tsx" /* webpackChunkName: "component---src-pages-auth-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("/build/packages/frontend-app/src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-index-tsx": () => import("/build/packages/frontend-app/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-organization-tsx": () => import("/build/packages/frontend-app/src/pages/organization.tsx" /* webpackChunkName: "component---src-pages-organization-tsx" */),
  "component---src-pages-projects-tsx": () => import("/build/packages/frontend-app/src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-share-tsx": () => import("/build/packages/frontend-app/src/pages/share.tsx" /* webpackChunkName: "component---src-pages-share-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/build/packages/frontend-app/.cache/data.json")


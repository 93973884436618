import React from "react";
import { Tooltip } from "shared-ui";
import { FieldArray, Field, FieldProps } from "formik";
import { Text, Box } from "grommet";
import {
  Header,
  CurrencyInput,
  AddFieldButton,
  TrashButton,
  ErrorMessage,
} from "../../components";
import {
  StyledLabel,
  StyledNumberInput,
  StyledTextInput,
} from "../../styles/Inputs";
import { Wrapper, RowBox } from "../../styles/Wrappers";
import {
  calculateTotalValues,
  calculateGroupAmount,
} from "@rr/utils/calculator/totals";
import fieldTypes from "../../data/fieldTypes";

interface Props {
  values?: {
    [key: string]: string;
  }[];
}

const MonetaryValueOfVolunteers: React.FunctionComponent<Props> = React.memo(
  (props) => {
    const { values } = props;
    return (
      <Wrapper>
        <Header
          heading="Monetary Value of Volunteers"
          size="medium"
          level={4}
          amount={calculateGroupAmount(values) || "$0.00"}
          tooltipContent="<em>The total value of the hourly contributions of your skilled volunteer(s).</em>"
          data-testid="mvc-value"
        />
        <Text
          margin={{ vertical: "1rem" }}
          size="small"
          dangerouslySetInnerHTML={{
            __html:
              "Input the <u>hourly rate of a comparable consultant</u> in your region and/or project area. Then, input the <u>estimated number of volunteer hours</u> for the project.",
          }}
        />
        <FieldArray name="monetaryValueOfConsultants">
          {(arrayHelpers) => (
            <React.Fragment>
              {values &&
                values.length > 0 &&
                values.map((value, index) => (
                  <div key={index}>
                    <RowBox>
                      <Box width="61%">
                        <StyledLabel
                          display={index !== 0 ? "none" : "flex"}
                          htmlFor="consultantDescription"
                        >
                          {"Volunteer Description (e.g. HR Specialist)"}
                        </StyledLabel>
                      </Box>
                      <Box width="13%">
                        <StyledLabel
                          display={index !== 0 ? "none" : "flex"}
                          htmlFor="hourlyRate"
                        >
                          <Tooltip
                            size="16px"
                            italicized="true"
                          >{`<em>The value of a skilled volunteer hour.</em>\n\n<div style="font-style:normal">Estimate this value based on the rate of a consultant in your region or project area. If you can't find specific data for your region or project area, use the current pro bono standard of $150 / hour.<div>`}</Tooltip>
                          {"Hourly Rate"}
                        </StyledLabel>
                      </Box>
                      <Box width="13%">
                        <StyledLabel
                          display={index !== 0 ? "none" : "flex"}
                          htmlFor="totalHours"
                        >
                          <Tooltip
                            size="16px"
                            italicized="true"
                          >{`<em>The estimated number of hours your skilled volunteer(s) will contribute to complete the project.\n\nWe recommend considering how many hours you'd expect the volunteer(s) to contribute per week, then multiply this by the estimated duration of the project.</em>`}</Tooltip>
                          {"Total Hours"}
                        </StyledLabel>
                      </Box>
                      <Box width="13%">
                        <StyledLabel
                          display={index !== 0 ? "none" : "flex"}
                          htmlFor="totalValue"
                        >
                          {"Total Value"}
                        </StyledLabel>
                      </Box>
                    </RowBox>
                    <RowBox>
                      <TrashButton onClick={() => arrayHelpers.remove(index)} />
                      <Box width="61%" margin={{ right: ".35rem" }}>
                        <Field
                          name={`monetaryValueOfConsultants.${index}.consultantDescription`}
                          render={({ field }: FieldProps) => (
                            <StyledTextInput
                              {...field}
                              id="conslutantDescription"
                              size="small"
                              type="text"
                            />
                          )}
                        />
                        <ErrorMessage
                          name={`monetaryValueOfConsultants.${index}.consultantDescription`}
                        />
                      </Box>
                      <Box width="13%" margin={{ right: ".35rem" }}>
                        <Field
                          name={`monetaryValueOfConsultants.${index}.hourlyRate`}
                          render={({ field }: FieldProps) => (
                            <CurrencyInput
                              {...field}
                              id="hourlyRate"
                              placeholder="0.00"
                              onStateChange={(currency) => {
                                arrayHelpers.replace(index, {
                                  ...value,
                                  hourlyRate: currency,
                                });
                              }}
                              onBlur={(event) => {
                                field.onBlur(event);
                                arrayHelpers.replace(index, {
                                  ...value,
                                  totalValue: calculateTotalValues(
                                    "monetaryValueOfConsultants",
                                    value,
                                  ),
                                });
                              }}
                            />
                          )}
                        />
                        <ErrorMessage
                          name={`monetaryValueOfConsultants.${index}.hourlyRate`}
                        />
                      </Box>
                      <Box width="13%" margin={{ right: ".35rem" }}>
                        <Field
                          name={`monetaryValueOfConsultants.${index}.totalHours`}
                          render={({ field }: FieldProps) => (
                            <StyledNumberInput
                              {...field}
                              id="totalHours"
                              placeholder="0"
                              size="small"
                              type="number"
                              onBlur={(event) => {
                                field.onBlur(event);
                                arrayHelpers.replace(index, {
                                  ...value,
                                  totalValue: calculateTotalValues(
                                    "monetaryValueOfConsultants",
                                    value,
                                  ),
                                });
                              }}
                            />
                          )}
                        />
                        <ErrorMessage
                          name={`monetaryValueOfConsultants.${index}.totalHours`}
                        />
                      </Box>
                      <Box width="13%">
                        <Field
                          name={`monetaryValueOfConsultants.${index}.totalValue`}
                          render={({ field }: FieldProps) => (
                            <StyledNumberInput
                              {...field}
                              id="totalValue"
                              placeholder="0.00"
                              size="small"
                              type="number"
                              disabled={true}
                            />
                          )}
                        />
                      </Box>
                    </RowBox>
                  </div>
                ))}
              {values && (
                <AddFieldButton
                  onClick={() =>
                    arrayHelpers.push({
                      ...fieldTypes["monetaryValueOfConsultants"],
                      consultantDescription: `Volunteer ${values.length + 1}`,
                    })
                  }
                  text="Add a volunteer"
                />
              )}
            </React.Fragment>
          )}
        </FieldArray>
      </Wrapper>
    );
  },
);

MonetaryValueOfVolunteers.displayName = "MonetaryValueOfVolunteers";

export default MonetaryValueOfVolunteers;

import * as React from "react";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import styled from "styled-components-themed";
import AssessmentApp from "assessment";
import { loadOrganization, unloadOrganization } from "organization";
import { RootState } from "frontend-app/typings";
import { Link } from "@reach/router";
import { Anchor, Breadcrumbs } from "shared-ui";
import { FaHome } from "react-icons/fa";

interface Props {
  organization: RootState["organizations"]["organization"];
  loadOrganizationAction: typeof loadOrganization;
  unloadOrganizationAction: typeof unloadOrganization;
}

const Wrapper = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;
`;

const AssessmentPage: React.FunctionComponent<Props> = (props) => {
  const {
    organization,
    loadOrganizationAction,
    unloadOrganizationAction,
  } = props;

  React.useEffect(() => {
    (async () => {
      try {
        await loadOrganizationAction();
      } catch (error) {
        console.log(`Error loading organization ${error}`);
      }
    })();

    return () => {
      unloadOrganizationAction();
    };
  }, []);

  if (!organization) {
    return null;
  }

  return (
    <Wrapper>
      <Breadcrumbs>
        <Anchor href="https://www.capacitycommons.org/">
          <FaHome size="16px" color="#238DC1" />
        </Anchor>
        <Anchor as={Link} to="/">
          My Projects
        </Anchor>
        <div>Assessment</div>
      </Breadcrumbs>

      <AssessmentApp
        parentId={organization._id!}
        type="Organization"
        basepath={`organization/assessment`}
      />
    </Wrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  organization: state.organizations.organization,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      loadOrganizationAction: loadOrganization,
      unloadOrganizationAction: unloadOrganization,
    },

    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssessmentPage);

import React from "react";
import styled from "styled-components-themed";
import { Box, Text } from "grommet";
// import { Twitter, Facebook, Linkedin, Youtube } from "grommet-icons";
import { Container } from "shared-ui";

const Wrapper = styled.footer`
  padding: 1.5rem;
  background: #244b5b;
`;

const GuidebookItem = styled(Box)`
  flex-direction: row;

  > span {
    font-weight: bold;
    padding-top: 0.125rem;
    padding-right: 0.3125rem;
  }

  > a {
    text-decoration: none;
    color: inherit;
  }
`;

const TextItem = styled(Text)`
  font-size: 0.75rem;
  color: white;
`;

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <Box direction="row" justify="between" margin={{ bottom: "5rem" }}>
          <Box direction="row-responsive" justify="between" width="28rem">
            <Box>
              <GuidebookItem>
                <TextItem>Learn:</TextItem>
                <a href="https://www.capacitycommons.org/guidebook/learn/about-skills-based-volunteerism/">
                  <TextItem>About Skills-Based Volunteering</TextItem>
                </a>
              </GuidebookItem>
              <GuidebookItem>
                <TextItem>Prepare:</TextItem>
                <a href="https://www.capacitycommons.org/guidebook/prepare/ready-your-organization/">
                  <TextItem>Ready Your Organization</TextItem>
                </a>
              </GuidebookItem>
              <GuidebookItem>
                <TextItem>Scope:</TextItem>
                <a href="https://www.capacitycommons.org/guidebook/scope/shape-your-project/">
                  <TextItem>Shape Your Volunteers</TextItem>
                </a>
              </GuidebookItem>
              <GuidebookItem>
                <TextItem>Source:</TextItem>
                <a href="https://www.capacitycommons.org/guidebook/source/find-your-volunteers/">
                  <TextItem>Find Your Volunteers</TextItem>
                </a>
              </GuidebookItem>
              <GuidebookItem>
                <TextItem>Implement:</TextItem>
                <a href="https://www.capacitycommons.org/guidebook/implement/manage-your-project/">
                  <TextItem>Manage Your Project</TextItem>
                </a>
              </GuidebookItem>
              <GuidebookItem>
                <TextItem>Evaluate:</TextItem>
                <a href="https://www.capacitycommons.org/guidebook/evaluate/measure-your-impact/">
                  <TextItem>Measure Your Impact</TextItem>
                </a>
              </GuidebookItem>
              <GuidebookItem>
                <TextItem>Repeat:</TextItem>
                <a href="https://www.capacitycommons.org/guidebook/repeat/embed-the-practice/">
                  <TextItem>Embed The Practice</TextItem>
                </a>
              </GuidebookItem>
            </Box>
            <Box>
              <a href="https://www.capacitycommons.org/about-us/">
                <TextItem>About Us</TextItem>
              </a>
              <a href="https://www.capacitycommons.org/privacy-policy/">
                <TextItem>Privacy Policy</TextItem>
              </a>
              <a href="https://www.capacitycommons.org/guidebook/learn/frequently-asked-questions/">
                <TextItem>Frequently Asked Questions</TextItem>
              </a>
              <a href="https://www.capacitycommons.org/terms-of-service/">
                <TextItem>Terms of Service</TextItem>
              </a>
            </Box>
          </Box>
          {/* <Box direction="row" justify="evenly" width="small">
            <Twitter color="#fff" />
            <Facebook color="#fff" />
            <Linkedin color="#fff" />
            <Youtube color="#fff" />
          </Box> */}
        </Box>
        <TextItem>
          Copyright © 2019 Capacity Commons. All rights reserved.
        </TextItem>
      </Container>
    </Wrapper>
  );
};

export default Footer;

export const softSkills = [
  "Project Management",
  "Training and Facilitation",
  "Coaching and Mentorship",
  "Public Speaking and Presentation",
  "Risk Management",
  "Conflict Resolution",
  "Cross-Cultural Competency",
  "Open-Mindedness",
  "Influence",
  "Adaptability",
  "Active Listening",
  "Creative Problem Solving",
  "Teamwork",
  "Attention to Detail",
  "Time Management",
  "Passion for our Mission",
];

export const hardSkills = {
  Marketing: [
    "Marketing Generalist",
    "Digital Marketing",
    "Communications",
    "Content Strategy",
    "Collateral Development",
    "Copywriting and Editing",
    "Social Media",
    "Audience Segmentation",
    "Brand Activation",
    "Search Engine Optimization",
    "Graphic Design",
    "Visual Brand Development (Logos, Color Schemes)",
    "Public Relations and Media Management",
    "Market Research",
    "Stakeholder Interviews",
    "Message or Talking Point Development",
  ],
  "Human Resources": [
    "Benefits and Compensation",
    "Change Management",
    "Crisis Management",
    "Employment Law",
    "Hiring and Recruiting",
    "HR Generalist",
    "Onboarding",
    "Performance Management",
    "Personnel Policy",
    "Resourcing and Staffing Strategy",
    "Succession Planning",
    "Talent Development",
    "Diversity, Equity and Inclusion",
  ],
  "Financial Management": [
    "Business Analysis",
    "Budget Analysis",
    "Cost-Benefit Analysis",
    "Cost Allocation Strategy",
    "Finance Generalist",
    "Financial Forecasting",
    "Financial Modeling",
    "Investment Strategy",
    "Managerial Accounting",
    "Market Analysis",
  ],
  Technology: [
    "Application Development",
    "Application Integration",
    "Business Analysis and Process Optimization",
    "Cloud Computing",
    "Data Management",
    "Database Development",
    "Database Administration",
    "Database Outcomes and Reporting",
    "Desktop Technician",
    "IT Infrastructure Assessment",
    "Networking",
    "Technical Requirements Development",
    "Search Engine Optimization",
    "Security Specialist",
    "SQL Servers",
    "Technical Writing",
    "Technology Generalist",
    "User Experience Design (Navigation, Wireframes)",
    "User Interface Design (Graphic Design, Branding)",
    "Vendor Selection",
    "Website Development (HTML, PHP, CSS)",
    "Wordpress.com Experience",
    "Salesforce.com Experience",
  ],
  "Data and Analytics": [
    "Data Analysis",
    "Metric or KPI Development",
    "Survey Design and Development",
    "Google Analytics",
    "Infographic Development",
    "Tableau Experience",
    "Data Visualization",
  ],
  Strategy: [
    "Contingency Planning",
    "Financial Planning",
    "Market Research",
    "Metric or KPI Development",
    "Product or Program Development",
    "Strategic Planning",
    "Strategic Analysis",
  ],
  Operations: [
    "Information Mapping",
    "LEAN Six Sigma",
    "Organizational Design",
    "Process Mapping",
    "Process Optimization",
    "Knowledge Management",
  ],
  "Client Relations": [
    "Business Development",
    "Client Lead Identification",
    "Client Lead Conversion",
    "Crisis Management",
    "Client Relationship Management (CRM) Infrastructure Assessment",
    "Client Relationship Management (CRM) Vendor Recommendation",
    "Client Pipeline Management",
    "Client Relationship Management",
    "Customer Communication and Retention",
    "Sales Strategy",
  ],
};

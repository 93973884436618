import * as React from "react";
// import { Router, Redirect } from "@reach/router";
import { Router } from "@reach/router";
import { ProtectedRoute } from "shared-ui";
// import OrganizationForm from "./components/OrganizationForm";
// import OrganizationList from "./components/OrganizationList";
// import OrganizationDisplay from "./components/OrganizationDisplay";
import Assessment from "./components/Assessment";

const Routes: React.FunctionComponent<{ basepath?: string }> = React.memo(
  ({ basepath = "" }) => (
    <Router basepath={basepath} primary={false}>
      {/* <Redirect default noThrow from="/" to={`${basepath}/list`} /> */}
      {/* <ProtectedRoute component={OrganizationList} path="list" /> */}
      {/* <ProtectedRoute component={OrganizationForm} path="new" />*/}
      {/* <ProtectedRoute component={(props) => props.children} path=":orgId"> */}
      {/* <ProtectedRoute component={OrganizationDisplay} path="/" />
        <ProtectedRoute component={OrganizationForm} path="edit" /> */}
      <ProtectedRoute component={Assessment} path="assessment/*" />
      {/* </ProtectedRoute> */}
    </Router>
  ),
);

Routes.displayName = "OrganizationRoutes";

export default Routes;

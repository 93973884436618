import React from "react";
import styled from "styled-components-themed";
import { Text } from "grommet";

interface Props {
  separator?: any;
}

const StyledBreadcrumbs = styled.div`
  text-decoration: none;
  display: flex;
  color: ${(props) => props.theme.colors.text};
`;

const StyledBreadcrumbItem = styled.div`
  padding: 0 0.2rem;

  &:last-child {
    color: ${(props) => props.theme.colors.darkText};
  }

  a {
    text-decoration: none;
  }
`;

const Breadcrumbs: React.FunctionComponent<Props> = ({
  separator = "›",
  children,
}) => {
  const items = React.Children.toArray(children)
    .filter((child) => React.isValidElement(child))
    .reduce((arr: any[] = [], child) => {
      arr.push(child);
      arr.push(separator);
      return arr;
    }, []);

  return (
    <StyledBreadcrumbs>
      {items.slice(0, items.length - 1).map((item, index) => {
        return (
          <StyledBreadcrumbItem key={index}>
            <Text as="div" size="small">
              {item}
            </Text>
          </StyledBreadcrumbItem>
        );
      })}
    </StyledBreadcrumbs>
  );
};

export default Breadcrumbs;

import * as React from "react";
import { Button as ButtonBase } from "grommet";
import styled from "styled-components-themed";

const StyledButton = styled(ButtonBase)`
  border-radius: 0.25rem;
`;

const Button = ({ ...restProps }) => (
  <StyledButton plain={false} {...restProps} />
);

export default Button;

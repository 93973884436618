import * as React from "react";
import styled from "styled-components-themed";
import { Box, Heading, Paragraph } from "grommet";
import { media } from "shared-ui";

const StartAssessmentParagraph = styled(Paragraph)`
  margin: none;
  max-width: none;
  white-space: pre-line;
  font-size: 1.125rem;
`;

const LeftColumn = styled.div`
  margin-top: 1.5rem;
  margin-right: 2rem;

  ${media.large`
    width: 75%;
  `}
`;

const ColoredUnorderedList = styled.ul`
  padding: 1.5rem 0 0 2rem;
  list-style: none;
  position: relative;
`;

const ColoredListItem = styled.li`
  line-height: 1.5rem;

  &::before {
    content: "• ";
    color: #00b189;
    position: absolute;
    left: 0.875rem;
    font-size: 1.75rem;
  }
`;

const HomeLeftColumn: React.FunctionComponent = () => {
  return (
    <LeftColumn>
      <Heading level="1" margin={{ bottom: "1.5rem" }} color="#244B5A">
        Organizational Readiness Assessment
      </Heading>
      <Box margin={{ bottom: "2rem" }}>
        <Heading level="3" margin={{ bottom: "1rem" }} color="#00B189">
          Welcome!
        </Heading>
        <StartAssessmentParagraph>
          Before you embark on a skills-based volunteering project, we recommend
          you take a step back to ensure you’re “ready” to do so. What does it
          mean to be ready? The most successful organizations have strong
          executive leadership, a proven theory of change, established
          relationship-building practices, and a commitment to building their
          capacity to serve. In short, these organizations have a clear picture
          of where they’re going and how skilled volunteers will help them get
          there.{"\n\n"}Take our Organizational Readiness Assessment to receive
          an objective assessment of your readiness, along with customized tips
          for preparing your organization to make the most of skilled volunteer
          support.
        </StartAssessmentParagraph>
      </Box>
      <Box margin={{ bottom: "2rem" }}>
        <Heading level="3" margin={{ bottom: ".5rem" }} color="#244B5A">
          Instructions:
        </Heading>
        <StartAssessmentParagraph>
          It takes about 10 minutes to complete the assessment and see your
          results. To do so, simply answer each of the questions using the
          following scale:
        </StartAssessmentParagraph>
        <ColoredUnorderedList>
          <ColoredListItem> Definitely True</ColoredListItem>
          <ColoredListItem> Mostly True</ColoredListItem>
          <ColoredListItem> Somewhat True</ColoredListItem>
          <ColoredListItem> Not True</ColoredListItem>
        </ColoredUnorderedList>
      </Box>
      <Box margin={{ bottom: "2rem" }}>
        <StartAssessmentParagraph>
          After the assessment, we’ll share your results along with tips to
          increase the readiness of your organization. We encourage you to
          retake the assessment if any major changes occur in your
          organization’s leadership, mission, or operating model.
        </StartAssessmentParagraph>
      </Box>
    </LeftColumn>
  );
};

export default HomeLeftColumn;

import * as React from "react";
import styled from "styled-components-themed";
import { Match } from "@reach/router";
import { Anchor, Button as ButtonBase, media } from "shared-ui";
import { Box } from "grommet";
import { FaSignInAlt } from "react-icons/fa";
import { AuthService, useAuth } from "authentication";
import NavProfile from "./NavProfile";
import NavItem from "./NavItem";

const Button = styled(ButtonBase)`
  background-color: #448cbc;
  color: #ffffff;
  padding: 0.5rem;
`;

export const DesktopMenu = styled.div`
  display: none;

  ${media.large`
    display: block;
  `}
`;

const Login: React.FunctionComponent<{}> = () => {
  const { isLoggedIn, firstName, lastName, email } = useAuth();
  const name = `${firstName} ${lastName}`.trim() || email;

  // `APP_URL` is only available on WP Site
  const loginButton = process.env.APP_URL ? (
    <Anchor href={process.env.APP_URL}>
      <Button
        icon={<FaSignInAlt size="16px" color="#ffffff" />}
        label="Log In"
        color="#448cbc"
      />
    </Anchor>
  ) : (
    <Button
      icon={<FaSignInAlt size="16px" color="#ffffff" />}
      label="Log In"
      color="#448cbc"
      onClick={AuthService.login}
    />
  );

  return (
    <Box direction="row" align="center">
      {!isLoggedIn ? (
        loginButton
      ) : (
        <React.Fragment>
          <DesktopMenu>
            <Match path={`${process.env.APP_URL}/`}>
              {({ match: matchRoot }) => (
                <Match path={`${process.env.APP_URL}/projects/*`}>
                  {({ match: matchProjects }) => (
                    <NavItem
                      title="My Projects"
                      href={`${process.env.APP_URL}/`}
                      match={!!matchRoot || !!matchProjects}
                    />
                  )}
                </Match>
              )}
            </Match>
          </DesktopMenu>
          {name && <NavProfile name={name} />}
        </React.Fragment>
      )}
    </Box>
  );
};

export default Login;

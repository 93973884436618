import { Dispatch } from "redux";
import { getUser as getUserApi } from "api/user";
import { updateOrganization as updateOrganizationApi } from "api/organizations";
import { Organization } from "../typings";

export const ORGANIZATIONS_LOAD = "ORGANIZATIONS_LOAD";
export const ORGANIZATION_LOAD = "ORGANIZATION_LOAD";
export const ORGANIZATION_UNLOAD = "ORGANIZATION_UNLOAD";
export const ORGANIZATION_LOADING = "ORGANIZATION_LOADING";

export const loadOrganization = () => async (dispatch: Dispatch) => {
  try {
    const response = await getUserApi();
    const { data } = response.data;
    dispatch({
      type: ORGANIZATION_LOAD,
      payload: data.organization,
    });
    return data;
  } catch (error) {
    console.log(`Error loading project: ${error}`);
  }
};

export const unloadOrganization = () => ({
  type: ORGANIZATION_UNLOAD,
});

export const setOrganizationLoading = (isLoading: boolean) => ({
  type: ORGANIZATION_LOADING,
  payload: isLoading,
});

export const updateOrganization = (id: string, values: Organization) => async (
  dispatch: Dispatch,
) => {
  dispatch(setOrganizationLoading(true));
  try {
    const response = await updateOrganizationApi(id, values);
    const { data } = response.data;
    dispatch({
      type: ORGANIZATION_LOAD,
      payload: data,
    });
    dispatch(setOrganizationLoading(false));
    return data;
  } catch (error) {
    console.log(`Error updating organization ${error}`);
  }
};

export type Action =
  | { type: typeof ORGANIZATIONS_LOAD; payload: Organization[] }
  | { type: typeof ORGANIZATION_LOAD; payload: Organization }
  | { type: typeof ORGANIZATION_LOADING; payload: boolean }
  | { type: typeof ORGANIZATION_UNLOAD };

export interface State {
  organizations: Organization[];
  organizationsLoaded: boolean;
  organization?: Organization;
  organizationLoaded: boolean;
  isLoading: boolean;
}

const initialState: State = {
  organizations: [],
  organizationsLoaded: false,
  organization: undefined,
  organizationLoaded: false,
  isLoading: false,
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case ORGANIZATIONS_LOAD:
      return {
        ...state,
        organizations: action.payload,
        organizationsLoaded: true,
      };
    case ORGANIZATION_LOAD:
      return {
        ...state,
        organization: action.payload,
        organizationLoaded: true,
      };
    case ORGANIZATION_UNLOAD:
      return {
        ...state,
        organization: undefined,
        organizationLoaded: false,
      };
    case ORGANIZATION_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

import * as React from "react";
import styled from "styled-components-themed";

const Wrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 70vh;
`;

const NotFound: React.FunctionComponent<{}> = (props) => {
  const { children = "Not Found" } = props;
  return (
    <Wrapper>
      <h1>{children}</h1>
    </Wrapper>
  );
};

export default NotFound;

import React from "react";
import styled from "styled-components-themed";
import { AssessmentGroup, AssessmentItem } from "shared-ui";
import { getAnswersByGroup, getGroupByStep } from "./utils";
import { AssessmentContext } from "./Store";

interface Props {
  step?: string;
}

const Wrapper = styled.div``;

const Step: React.FunctionComponent<Props> = ({ step }) => {
  const { state, dispatch } = React.useContext(AssessmentContext);
  const parsedStep = Number(step);

  React.useEffect(() => {
    dispatch({ type: "SET_STEP", step: parsedStep });
  }, [parsedStep, dispatch]);

  const answers = getAnswersByGroup(state.answers, parsedStep);
  const group = getGroupByStep(state.questionsData, parsedStep);

  return (
    <Wrapper>
      <AssessmentGroup title={group.title} type={state.type}>
        {answers.map((item, index) => (
          <AssessmentItem
            key={item.number}
            num={item.number}
            selected={item.answer}
            onChange={(_event, values: { num: number; value: string }) => {
              dispatch({
                type: "SET_ANSWER",
                payload: {
                  number: values.num,
                  answer: Number(values.value),
                },
              });

              // Update score
              dispatch({ type: "SET_SCORE" });
            }}
          >
            {group.items[index]}
          </AssessmentItem>
        ))}
      </AssessmentGroup>
    </Wrapper>
  );
};

export default Step;

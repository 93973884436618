export const projectNeed = [
  {
    question: "What top goal do you need additional resources to meet?",
    textarea: {
      leadingText: "Our organization is currently working to ",
    },
    answer: "",
  },
  {
    question: "What are you currently doing to meet this goal?",
    textarea: {
      leadingText: "Right now, we are ",
    },
    answer: "",
  },
  {
    question: "Ideally, what would you be doing to meet this goal?",
    textarea: {
      leadingText: "However, by ",
    },
    answer: "",
  },
  {
    question:
      "How will this goal help your organization better meet its vision?",
    textarea: {
      leadingText: "we will ",
    },
    answer: "",
  },
];

export const projectDescription = [
  {
    question:
      'What will your volunteer(s) do on this project and what tangible "thing" will be created?',
    textarea: {
      leadingText: "A skilled volunteer or team of skilled volunteers will ",
    },
    answer: "",
  },
  {
    question:
      "What will you be able to do at the end of this project that you aren’t able to do now?",
    textarea: {
      leadingText: "This will ",
    },
    answer: "",
  },
];

export const projectPhase = [
  {
    question: "What would you like to name this phase?",
    answer: "",
  },
  {
    question:
      "What concrete actions will the volunteer(s) take to accomplish this phase of work?",
    answer: "",
  },
  {
    question:
      "What tangible work product will you review at the end of this phase before your volunteer(s) move to the next step?",
    answer: "",
  },
];

import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components-themed";
import { Link } from "@reach/router";
import { RootState } from "frontend-app/typings";
import { Anchor, Breadcrumbs } from "shared-ui";
import { FaHome } from "react-icons/fa";
import AssessmentApp from "assessment";

interface Props {
  projectId: string;
  project: RootState["projects"]["project"];
}

const Wrapper = styled.div`
  margin-top: 2rem;
`;

const Assessment: React.FunctionComponent<Props> = (props) => {
  const { projectId, project } = props;

  if (!project) {
    return null;
  }

  return (
    <Wrapper>
      <Breadcrumbs>
        <Anchor href="https://www.capacitycommons.org/">
          <FaHome size="16px" color="#238DC1" />
        </Anchor>
        <Anchor as={Link} to="/">
          My Projects
        </Anchor>
        <div>{project.name}</div>
      </Breadcrumbs>

      <AssessmentApp
        parentId={projectId}
        type="Project"
        basepath={`projects/${projectId}/assessment`}
      />
    </Wrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  project: state.projects.project,
});

export default connect(mapStateToProps)(Assessment);

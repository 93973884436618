import styled, { css } from "styled-components-themed";

const Step = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  font-size: 0.8rem;
  border-radius: 50%;
  background: rgb(182, 204, 194);
  color: #fff;
  transition: background 0.2s 0.1s;

  ${(props: { isActive?: boolean }) =>
    props.isActive &&
    css`
      background: rgb(0, 177, 137);
    `}
`;

export default Step;

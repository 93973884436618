import * as React from "react";
import styled from "styled-components-themed";

interface Props {
  label?: string;
  props?: { [key: string]: any };
  state?: { [key: string]: any };
}

const Wrapper = styled.div`
  margin: 1rem 0;
  text-align: left;
  font-family: monospace;
`;

const Pre = styled.pre`
  background: #f6f8fa;
  font-size: 0.7rem;
  padding: 0.5rem;
`;

const Label = styled.h3`
  padding: 0.25rem 0.5rem;
  background: #000;
  color: #fff;
  font-size: 0.75rem;
  font-family: ${(props) => props.theme.fonts.base};
  text-transform: uppercase;
`;

const Debug: React.FunctionComponent<Props> = ({
  label,
  props,
  state,
  ...restProps
}) => (
  <Wrapper {...restProps}>
    {label && <Label>{label}</Label>}
    {props && (
      <Pre>
        <strong>props</strong> = {JSON.stringify(props, null, 2)}
      </Pre>
    )}
    {state && (
      <Pre>
        <strong>state</strong> = {JSON.stringify(state, null, 2)}
      </Pre>
    )}
  </Wrapper>
);

export default Debug;

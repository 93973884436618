import { FaExclamationCircle, FaCheckCircle } from "react-icons/fa";

import ReadyImage from "frontend-app/src/images/img-ora-youre-ready.svg";
import AlmostThereImage from "frontend-app/src/images/img-ora-almost-there.svg";
import WorkToDoImage from "frontend-app/src/images/img-ora-work-to-do.svg";

export const assessmentMap = {
  "You're ready": {
    icon: FaCheckCircle,
    color: "#4BAE8B",
    sideImage: ReadyImage,
    topBlurb:
      "Congratulations! Your project is a great fit for a skills-based volunteering engagement. Review your results in the key areas below to understand the benchmarks that contribute to your readiness. Your next step is to <a target='_blank' href='../volunteers'>find the volunteers</a> who will partner with you on this project.",
    sideBlurb:
      "Your project is a strong fit for pro bono. Please proceed to your desired next step.",
  },
  "Almost there": {
    icon: FaExclamationCircle,
    color: "#E6A161",
    sideImage: AlmostThereImage,
    topBlurb:
      "You’re headed in the right direction, but your project is not quite ready to kick off. Don’t be discouraged - review your results below to understand what you need to adjust to make sure the project will be a success.\n\n Consider utilizing our guided <a target='_blank' href='https://capacitycommons.org/guidebook/scope/scope-your-project/'>Project Scoping Tool</a> to refine your project or enlisting the support of an external <a target='_blank' href='https://capacitycommons.org/guidebook/scope/scope-consultant-job-description/'>Scope Consultant</a> to help you think through your needs. \n\n Once you’ve taken the necessary steps to strengthen the project, feel free to take the assessment again or proceed to <a target='_blank' href='../volunteers'>find the volunteers</a> to tackle your needs.",
    sideBlurb:
      "Your organization would benefit from some additional attention to your project before you move forward on it. Read on below for tips and resources to strengthen your pro bono project.",
  },
  "Work to do": {
    icon: FaExclamationCircle,
    color: "#C35D5C",
    sideImage: WorkToDoImage,
    topBlurb:
      "It looks like your project is not ready to kick off. Not every project need translates well to a skilled volunteer engagement, but don’t be discouraged. There are easy ways to strengthen the project or to redefine a new project that’s a better fit for pro bono. Review your results below to understand what you need to adjust to make sure your project will be a success. \n\n We recommend utilizing our guided <a target='_blank' href='https://capacitycommons.org/guidebook/scope/scope-your-project/'>Project Scoping Tool</a> to refine your project or enlisting the support of an external <a target='_blank' href='https://capacitycommons.org/guidebook/scope/scope-consultant-job-description/'>Scope Consultant</a> to help you think through your needs. \n\n Once you’ve taken the necessary steps to strengthen the project or define an alternative scope, we encourage you to take the assessment again before you proceed to <a target='_blank' href='../volunteers'>find the volunteers</a> to tackle your needs.",
    sideBlurb:
      "We advise you to further develop this project before seeking volunteer resources. Read on below for tips and resources to strengthen your pro bono project.",
  },
};

import React from "react";
import styled from "styled-components-themed";
import AssessmentItem from "./AssessmentItem";
import { Text } from "grommet";

export interface Props {
  title: string;
  type: string;
}

const Wrapper = styled.div`
  ${AssessmentItem} {
    margin-bottom: 1.5rem;
  }
  padding-top: 2rem;
`;

const Title = styled.h2`
  font-size: 2.75rem;
  margin-bottom: 1.5rem;
  padding-top: 1rem;
`;

const AssessmentGroup: React.FunctionComponent<Props> = (props) => {
  const { title, type, children, ...restProps } = props;

  return (
    <Wrapper {...restProps}>
      <Text size="small" margin={{ bottom: "1rem" }}>
        {type === "Organization" ? "Organizational" : type} Readiness Assessment
      </Text>
      <Title>{title}</Title>
      {children}
    </Wrapper>
  );
};

export default AssessmentGroup;

import React from "react";
import { FieldArray, Field, FieldProps } from "formik";
import { Text, Box } from "grommet";
import {
  Header,
  CurrencyInput,
  AddFieldButton,
  TrashButton,
} from "../../components";
import { StyledLabel, StyledTextInput } from "../../styles/Inputs";
import { Wrapper, RowBox } from "../../styles/Wrappers";
import { calculateGroupAmount } from "@rr/utils/calculator/totals";
import fieldTypes from "../../data/fieldTypes";
import { longTermBusinessValuePlaceholders } from "../../data/placeholders";

interface Props {
  values?: {
    [key: string]: string;
  }[];
}

const LongTermBusinessValue: React.FunctionComponent<Props> = React.memo(
  (props) => {
    const { values } = props;
    return (
      <Wrapper>
        <Header
          data-testid="ltbv-value"
          heading="Long-term Business Value"
          size="medium"
          level={4}
          amount={calculateGroupAmount(values) || "$0.00"}
          tooltipContent="<em>The indirect business value you will recognize by completing the project.</em>\n\nFor instance, undertaking a pro bono website renovation could translate to ongoing savings by bringing your web hosting in-house or by building an online donation platform, you may forecast that you’ll attract 5 new donors with an average gift of $1,000 each."
        />
        <Text
          margin={{ vertical: "1rem" }}
          size="small"
          dangerouslySetInnerHTML={{
            __html:
              "Below are examples of the <u>types of long-term value</u> your project might yield.  Fill in your own based on the project you are considering.  Then, input the monetary value of each business consideration below.",
          }}
        />
        <FieldArray name="longTermBusinessValue">
          {(arrayHelpers) => (
            <React.Fragment>
              {values &&
                values.length > 0 &&
                values.map((value, index) => (
                  <div key={index}>
                    <RowBox>
                      <Box width="80%">
                        <StyledLabel
                          display={index !== 0 ? "none" : "flex"}
                          htmlFor="description"
                        >
                          {"Description"}
                        </StyledLabel>
                      </Box>
                      <Box width="20%">
                        <StyledLabel
                          display={index !== 0 ? "none" : "flex"}
                          htmlFor="totalValue"
                        >
                          {"Total Value"}
                        </StyledLabel>
                      </Box>
                    </RowBox>
                    <RowBox>
                      <TrashButton onClick={() => arrayHelpers.remove(index)} />
                      <Box width="80%" margin={{ right: ".35rem" }}>
                        <Field
                          name={`longTermBusinessValue.${index}.description`}
                          render={({ field }: FieldProps) => (
                            <StyledTextInput
                              {...field}
                              placeholder={
                                longTermBusinessValuePlaceholders[index] || ""
                              }
                              id="description"
                              size="small"
                              type="text"
                            />
                          )}
                        />
                      </Box>
                      <Box width="20%">
                        <Field
                          name={`longTermBusinessValue.${index}.totalValue`}
                          render={({ field }: FieldProps) => (
                            <CurrencyInput
                              {...field}
                              id="totalValue"
                              placeholder="0.00"
                              onStateChange={(currency) => {
                                arrayHelpers.replace(index, {
                                  ...value,
                                  totalValue: currency,
                                });
                              }}
                            />
                          )}
                        />
                      </Box>
                    </RowBox>
                  </div>
                ))}
              {values && (
                <AddFieldButton
                  onClick={() =>
                    arrayHelpers.push(fieldTypes["longTermBusinessValue"])
                  }
                  text="Add long-term value"
                />
              )}
            </React.Fragment>
          )}
        </FieldArray>
      </Wrapper>
    );
  },
);

LongTermBusinessValue.displayName = "LongTermBusinessValue";

export default LongTermBusinessValue;

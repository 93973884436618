import React from "react";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import styled from "styled-components-themed";
import { connect } from "react-redux";
import { Link, navigate } from "@reach/router";
import { RootState } from "frontend-app/typings";
import { Box, Text, Heading } from "grommet";
import { FaHome } from "react-icons/fa";
import { Header } from "../../components";
import { Formik, FormikProps } from "formik";
import { Button, Breadcrumbs, Anchor } from "shared-ui";
import {
  createCalculator,
  updateCalculator,
  setCalculatorEditing,
} from "../../reducers";
import {
  organizationalValue,
  organizationalInvestment,
} from "@rr/utils/calculator/groups";
import { CalculatorSchema } from "../../validation";
import { calculateGroupTotals } from "@rr/utils/calculator/totals";
import { Calculator } from "../../../typings";
import MonetaryValueOfVolunteers from "./MonetaryValueOfVolunteers";
import MonetaryValueOfProducts from "./MonetaryValueOfProducts";
import LongTermBusinessValue from "./LongTermBusinessValue";
import LongTermBusinessValueUniqueToProBono from "./LongTermBusinessValueUniqueToProBono";
import StaffTime from "./StaffTime";
import OpportunityCostOfImplementation from "./OpportunityCostOfImplementation";
import ImplementationAndMaintenance from "./ImplementationAndMaintenance";
import UnquantifiableValue from "./UnquantifiableValue";

interface Props {
  monetaryValueOfConsultants: Calculator["monetaryValueOfConsultants"];
  monetaryValueOfProducts: Calculator["monetaryValueOfProducts"];
  longTermBusinessValue: Calculator["longTermBusinessValue"];
  longTermBusinessValueUniqueToProBono: Calculator["longTermBusinessValueUniqueToProBono"];
  staffTime: Calculator["staffTime"];
  opportunityCostOfImplementation: Calculator["opportunityCostOfImplementation"];
  implementationAndMaintenance: Calculator["implementationAndMaintenance"];
  unquantifiableValue: Calculator["unquantifiableValue"];
  createCalculatorAction: typeof createCalculator;
  updateCalculatorAction: typeof updateCalculator;
  setCalculatorEditingAction: typeof setCalculatorEditing;
  projectId?: string;
  projectName?: string;
  calculatorLoaded: boolean;
  isEditing: boolean;
  calculatorId?: string;
}

const Wrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 4rem;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  color: #333;
`;

const Container = styled.div`
  padding: 1rem 0;
  max-width: 64rem;
`;

const StyledButton = styled(Button)`
  background-color: #529fc9;
  color: #ffffff;
  padding: 0.875rem;
`;

const Home: React.FunctionComponent<Props> = React.memo((props) => {
  const {
    monetaryValueOfConsultants,
    monetaryValueOfProducts,
    longTermBusinessValue,
    longTermBusinessValueUniqueToProBono,
    staffTime,
    opportunityCostOfImplementation,
    implementationAndMaintenance,
    unquantifiableValue,
    projectId,
    projectName,
    createCalculatorAction,
    updateCalculatorAction,
    setCalculatorEditingAction,
    calculatorLoaded,
    isEditing,
    calculatorId,
  } = props;

  React.useEffect(() => {
    if (calculatorLoaded && !isEditing) {
      navigate("calculator/results", { replace: true });
    }
    return () => {
      setCalculatorEditingAction(false);
    };
  }, [calculatorLoaded, isEditing]);

  return (
    <Wrapper>
      <Breadcrumbs>
        <Anchor href="https://www.capacitycommons.org/">
          <FaHome size="16px" color="#238DC1" />
        </Anchor>
        <Anchor as={Link} to="/">
          My Projects
        </Anchor>
        <div>{projectName}</div>
      </Breadcrumbs>
      <Container>
        <Heading color="#2d4a58" margin={{ bottom: "1.5rem", top: "1rem" }}>
          Estimate Net Value
        </Heading>
        <Text as="p" size="1rem" margin={{ bottom: "medium" }}>
          It can be challenging for organizations to quantify the discrete
          impact of a pro bono intervention. That said, being able to cite the
          numerical value of a prospective project will help you validate that
          it is a good use of your time and garner buy in from your leadership
          and board.
        </Text>
        <Text as="p" size="1rem" margin={{ bottom: "medium" }}>
          Utilize this tool to calculate the unique value of your pro bono
          project. Don{"'"}t worry if you{"'"}re not able to complete every
          field - fill it out to the best of your ability!
        </Text>
        <Formik
          initialValues={{
            monetaryValueOfConsultants,
            monetaryValueOfProducts,
            longTermBusinessValue,
            longTermBusinessValueUniqueToProBono,
            staffTime,
            opportunityCostOfImplementation,
            implementationAndMaintenance,
            unquantifiableValue,
          }}
          validationSchema={CalculatorSchema}
          onSubmit={async (values, actions) => {
            try {
              if (calculatorId) {
                await updateCalculatorAction(calculatorId, {
                  ...values,
                  projectId,
                });
              } else {
                await createCalculatorAction({
                  ...values,
                  projectId,
                });
              }
              navigate("results");
            } catch (error) {
              alert("error creating caclculator");
            }
            actions.setSubmitting(false);
          }}
        >
          {({ values, handleSubmit }: FormikProps<Calculator>) => {
            return (
              <form
                onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                onSubmit={handleSubmit}
              >
                <Box margin={{ bottom: "2rem" }}>
                  <Header
                    data-testid="estimated-net-value"
                    heading="Estimated Net Value"
                    amount={
                      calculateGroupTotals(organizationalValue, values) -
                        calculateGroupTotals(
                          organizationalInvestment,
                          values,
                        ) || "$0.00"
                    }
                    size="large"
                    level={3}
                    tooltipContent={`<em>The total estimated value of your pro bono project, taking into consideration its all anticipated values and investments.</em>\n\nThis field will autopopulate as you complete the tool.`}
                  />
                </Box>
                <Box margin={{ bottom: "2rem" }}>
                  <Box margin={{ bottom: "1.5rem" }}>
                    <Header
                      data-testid="org-value"
                      heading="Organizational Value"
                      amount={calculateGroupTotals(organizationalValue, values)}
                      size="medium"
                      level={3}
                      tooltipContent={`<em>The total estimated value of your pro bono project, before you subtract any associated investments.</em>\n\nThe value will help you answer the question: "<em>Is this worth pursuing for our organization?</em>" This field will autopopulate as you complete the sub-categories.`}
                    />
                  </Box>
                  <MonetaryValueOfVolunteers
                    values={values.monetaryValueOfConsultants}
                  />
                  <MonetaryValueOfProducts
                    values={values.monetaryValueOfProducts}
                  />
                  <LongTermBusinessValue
                    values={values.longTermBusinessValue}
                  />
                  <LongTermBusinessValueUniqueToProBono
                    values={values.longTermBusinessValueUniqueToProBono}
                  />
                </Box>
                <Box margin={{ bottom: "2rem" }}>
                  <Box margin={{ bottom: "1.5rem" }}>
                    <Header
                      data-testid="org-investment"
                      heading="Organizational Investment"
                      amount={
                        calculateGroupTotals(
                          organizationalInvestment,
                          values,
                        ) || "$0.00"
                      }
                      size="medium"
                      level={3}
                      tooltipContent={`<em>The total estimated cost of your pro bono project.</em>\n\nThe investment will help you answer the question: "<em>What's it going to take to pull this off?</em>" This field will autopopulate as you complete the sub-categories.\n\nIf the implementation of a project will take longer for pro bono volunteers than it would with a contractor, consider the cost implications of that extra month on the following categories.`}
                    />
                  </Box>
                  <StaffTime values={values.staffTime} />
                  <OpportunityCostOfImplementation
                    values={values.opportunityCostOfImplementation}
                  />
                  <ImplementationAndMaintenance
                    values={values.implementationAndMaintenance}
                  />
                </Box>
                <Box margin={{ bottom: "2rem" }}>
                  <UnquantifiableValue values={values.unquantifiableValue} />
                </Box>
                <Box align="end">
                  <StyledButton type="submit" color="#529fc9">
                    Done
                  </StyledButton>
                </Box>
              </form>
            );
          }}
        </Formik>
      </Container>
    </Wrapper>
  );
});

Home.displayName = "Home";

const mapStateToProps = (state: RootState) => {
  const { calculator, calculatorLoaded, isEditing } = state.calculator;
  const projectId = state.projects.project && state.projects.project._id;
  const projectName = state.projects.project && state.projects.project.name;
  return {
    monetaryValueOfConsultants: calculator.monetaryValueOfConsultants,
    monetaryValueOfProducts: calculator.monetaryValueOfProducts,
    longTermBusinessValue: calculator.longTermBusinessValue,
    longTermBusinessValueUniqueToProBono:
      calculator.longTermBusinessValueUniqueToProBono,
    staffTime: calculator.staffTime,
    opportunityCostOfImplementation: calculator.opportunityCostOfImplementation,
    implementationAndMaintenance: calculator.implementationAndMaintenance,
    unquantifiableValue: calculator.unquantifiableValue,
    projectId,
    calculatorLoaded,
    isEditing,
    projectName,
    calculatorId: calculator._id,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      createCalculatorAction: createCalculator,
      updateCalculatorAction: updateCalculator,
      setCalculatorEditingAction: setCalculatorEditing,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);

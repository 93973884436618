import * as React from "react";
import styled from "styled-components-themed";
import { Heading as HeadingBase, HeadingProps, TextInput } from "grommet";

interface Props extends HeadingProps {
  children: string;
  onChange?: (...args: any) => void;
  onBlur?: (...args: any) => void;
}

const Heading = styled(HeadingBase)`
  max-width: none;
`;

const EditableTitle: React.FunctionComponent<Props> = (props) => {
  const { children, onChange, onBlur, ...restProps } = props;
  const [showInput, setShowInput] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(children);
  const inputEl = React.useRef(null as any);

  React.useEffect(() => {
    if (inputEl && inputEl.current) {
      inputEl.current.focus();
      // inputEl.current.select();
    }
  }, [showInput]);

  return showInput ? (
    <TextInput
      ref={inputEl}
      value={inputValue}
      onChange={(event) => {
        setInputValue(event.currentTarget.value);
        if (onChange) {
          onChange(event, event.currentTarget.value);
        }
      }}
      onBlur={(event) => {
        if (!inputValue) {
          // No input value, reset to initial value
          setInputValue(children);
        }
        if (onBlur) {
          onBlur(event, event.currentTarget.value);
        }
        setShowInput(false);
      }}
    />
  ) : (
    <Heading
      level={1}
      onClick={() => {
        setShowInput(true);
      }}
      {...restProps}
    >
      {inputValue}
    </Heading>
  );
};

export default EditableTitle;

import React from "react";
import styled from "styled-components-themed";
import { Omit } from "typings";
import { LinkProps } from "@reach/router";
import { colors } from "../../styles/theme";

interface Props
  extends LinkProps<any>,
    React.AnchorHTMLAttributes<HTMLAnchorElement> {
  color?: keyof typeof colors;
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  ref?: any;
  href?: string;
}

const StyledAnchor = styled.a<Omit<Props, "href">>`
  cursor: pointer;
  text-decoration: none;
  color: ${(props) => props.theme.colors[props.color!]};

  &:hover {
    text-decoration: ${(props) => (props.as === "a" ? "underline" : "none")};
    color: ${(props) => props.theme.colors.mediumBlue};
  }
`;

const Anchor: React.FunctionComponent<Props> = ({
  color = "blue" as keyof typeof colors,
  as = "a" as keyof JSX.IntrinsicElements,
  href,
  to,
  ...restProps
}) => {
  const link: { to?: string; href?: string } = {};
  if (as === "a") {
    link.href = href;
  } else {
    link.to = href || to;
  }

  return <StyledAnchor as={as} color={color} {...restProps} {...link} />;
};

export default Anchor;

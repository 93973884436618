import { Calculator } from "calculator/typings";

export const organizationalValue: Array<keyof Calculator> = [
  "monetaryValueOfConsultants",
  "monetaryValueOfProducts",
  "longTermBusinessValue",
  "longTermBusinessValueUniqueToProBono",
];

export const organizationalInvestment: Array<keyof Calculator> = [
  "staffTime",
  "opportunityCostOfImplementation",
  "implementationAndMaintenance",
];

export const unquantifiableValueOptions: Array<string> = [
  "Somewhat Valuable",
  "Not Valuable",
  "Valuable",
  "Very Valuable",
];

export const organizationalValueInfoBlurb: Array<string> = [
  "<em>The total value of the hourly contributions of your skilled volunteer(s).</em>",
  '<em>The up-front, fixed retail value of the product you will implement.</em>\n\nThis will only apply to some products. Some products, such as Salesforce, as free for nonprofits so the "product value" would be zero, in addition to the hourly value you\'ve calculated above.',
  "<em>The indirect business value you will recognize by completing the project.</em>\n\nFor instance, undertaking a pro bono website renovation could translate to ongoing savings by bringing your web hosting in-house or by building an online donation platform, you may forecast that you’ll attract 5 new donors with an average gift of $1,000 each.",
  "<em>The indirect business value you will recognize only by completing the project with skilled volunteer resources.</em>\n\nConsider the leadership, functional and project management skill development that your staff will experience working alongside volunteers (e.g. project provides equivalent skill development to a $5K training) and the long-term relationships you may develop with volunteers or companies (e.g. each solidified corporate relationship represents $10K in earned revenue).",
];

export const organizationalInvestmentInfoBlurb: Array<string> = [
  "<em>The monetary value of the time your staff will need to invest in scoping and managing the engagement.</em>\n\nTake the average loaded cost of an employee hour (check in with your Executive Director or HR staff!) and multiply it by the estimated number of hours your team will likely spend on the project. <strong>Hint:</strong> Thinking about it on a per week basis at first can be helpful in getting to the total number of hours. On average,we estimate a staff member will contribute 2-5 hours per week on a pro bono project.",
  "<em>The value of the initiatives that you could otherwise pursue with the resources devoted to this project.</em>\n\nFor example, if you plan to have your Development Director spearhead a website project, map out the cost of her time away from other fund-generating activities and the average conversion on time spent to donor dollars.",
  "<em>The additional budget needed to implement and maintain the deliverable.</em>\n\nCommon examples of ancillary costs include website hosting fees, collateral printing, or new purchases required. These usually have tangible market value that you can identify through quick research.",
];

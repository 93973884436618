import { Dispatch } from "redux";
import { updateUserMetadata as updateUserMetadataApi } from "api/user";
import { Omit } from "typings";

const SET_SESSION = "SET_SESSION";
const USER_METADATA_UPDATE = "USER_METADATA_UPDATE";

export interface Session {
  isLoading: boolean;
  isLoggedIn: boolean;
  userId: string;
  email: string;
  fullName: string;
  firstName: string;
  lastName: string;
  roles: string[];
}

export const setSession = (payload: Omit<Session, "fullName">) => ({
  type: SET_SESSION,
  payload,
});

export const updateUserMetadata = (values: {
  first_name: string;
  last_name: string;
}) => async (dispatch: Dispatch) => {
  try {
    const response = await updateUserMetadataApi(values);
    const { data } = response.data;
    dispatch({
      type: USER_METADATA_UPDATE,
      payload: data.user_metadata,
    });
    return data.user_metadata;
  } catch (error) {
    console.log(`Error updating user metadata: ${error}`);
  }
};

export type Action =
  | { type: typeof SET_SESSION; payload: Omit<Session, "fullName"> }
  | {
      type: typeof USER_METADATA_UPDATE;
      payload: { first_name: string; last_name: string };
    };

const initialState: Session = {
  isLoading: false,
  isLoggedIn: false,
  userId: "",
  email: "",
  fullName: "",
  firstName: "",
  lastName: "",
  roles: [],
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_SESSION: {
      const firstName = action.payload.firstName || "";
      const lastName = action.payload.lastName || "";
      return {
        ...state,
        isLoading: action.payload.isLoading,
        isLoggedIn: action.payload.isLoggedIn,
        userId: action.payload.userId,
        email: action.payload.email,
        firstName,
        lastName,
        fullName: `${firstName} ${lastName}`.trim(),
        roles: action.payload.roles,
      };
    }
    case USER_METADATA_UPDATE: {
      // Comes from Auth0
      const firstName = action.payload.first_name || "";
      const lastName = action.payload.last_name || "";
      return {
        ...state,
        firstName,
        lastName,
        fullName: `${firstName} ${lastName}`.trim(),
      };
    }
    default:
      return state;
  }
};

import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import { NavigateFn } from "@reach/router";
import styled from "styled-components-themed";
import { Steps, Spinning } from "shared-ui";
import { loadProject } from "projects";
import { Pagination } from "../../components/Pagination";
import {
  questionsData as organizationQuestionsData,
  assessments as organizationAssessmentScores,
} from "../../data/organization";
import {
  questionsData as projectQuestionsData,
  assessments as projectAssessmentScores,
} from "../../data/project";
import { createAssessment } from "api/assessments";
import { AssessmentContext } from "./Store";
import {
  composeAnswers,
  generatePagination,
  checkAllQuestionsAnswered,
} from "./utils";

const Container = styled.div`
  margin: 0 auto 4rem;
`;

const AssessmentSteps = styled(Steps)`
  justify-content: flex-end;
`;

const Assessment: React.FunctionComponent<{
  navigate: NavigateFn;
  loadProjectAction: typeof loadProject;
}> = (props) => {
  const { children, navigate, loadProjectAction } = props;
  const { state, dispatch } = React.useContext(AssessmentContext);
  const totalSteps = state.questionsData.length;
  const pagination = React.useMemo(
    () => generatePagination(state.questionsData),
    [state.questionsData],
  );

  const questionsData =
    state.type === "Project" ? projectQuestionsData : organizationQuestionsData;

  React.useEffect(() => {
    // Use cache data & exit early
    if (state.answers.length) {
      return undefined;
    }

    const assessmentScore =
      state.type === "Project"
        ? projectAssessmentScores
        : organizationAssessmentScores;

    dispatch({
      type: "SET_DATA",
      questionsData,
      answers: composeAnswers(questionsData),
      maxScore: assessmentScore[0].between[1],
    });
  }, [dispatch, state.answers.length]);

  if (state.isLoading) {
    return <Spinning />;
  }

  const isNextEnabled = checkAllQuestionsAnswered(state.answers, state.step);

  return (
    <Container>
      <AssessmentSteps total={totalSteps} current={state.step} />
      {children}
      <Pagination
        pagination={pagination}
        currentStep={state.step}
        totalSteps={totalSteps}
        isNextEnabled={isNextEnabled}
        onSubmit={async () => {
          if (!state.type || !state.parentId) {
            return console.error(
              "Oops! Looks like you're not suppose to be here..",
            );
          }
          try {
            const response = await createAssessment({
              type: state.type,
              parentId: state.parentId,
              totalScore: state.totalScore,
              maxScore: state.maxScore,
              scores: state.scores,
              questionsData: state.questionsData,
              answers: state.answers,
            });
            console.log("response", response);
            if (state.type === "Project") {
              await loadProjectAction(state.parentId);
            }
            navigate("../results");
          } catch (error) {
            console.error("Submit error", error);
          }
        }}
      />
    </Container>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      loadProjectAction: loadProject,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Assessment);

import { Dispatch } from "redux";
import {
  createCalculator as createCalculatorApi,
  getCalculator as getCalculatorApi,
  updateCalculator as updateCalculatorApi,
} from "api/calculator";
import { Calculator } from "../../typings";
import { navigate } from "@reach/router";

export const CALCULATOR_LOAD = "CALCULATOR_LOAD";
export const CALCULATOR_UNLOAD = "CALCULATOR_UNLOAD";
export const CALCULATOR_LOADING = "CALCULATOR_LOADING";
export const CALCULATOR_EDITING = "CALCULATOR_EDITING";

export interface CreatePayload extends Calculator {
  projectId?: string;
}

export interface State {
  calculator: Calculator;
  calculatorLoaded: boolean;
  isLoading: boolean;
  isEditing: boolean;
}

export type Action =
  | { type: typeof CALCULATOR_LOAD; payload: Calculator }
  | { type: typeof CALCULATOR_UNLOAD }
  | { type: typeof CALCULATOR_EDITING; payload: boolean }
  | { type: typeof CALCULATOR_LOADING; payload: boolean };

export const loadCalculator = (payload: Calculator) => ({
  type: CALCULATOR_LOAD,
  payload,
});

export const unloadCalculator = () => ({
  type: CALCULATOR_UNLOAD,
});

export const setCalculatorLoading = (isLoading: boolean) => ({
  type: CALCULATOR_LOADING,
  payload: isLoading,
});

export const createCalculator = (payload: CreatePayload) => async (
  dispatch: Dispatch,
) => {
  dispatch(setCalculatorLoading(true));
  try {
    const response = await createCalculatorApi(payload);
    const { data } = response.data;
    dispatch({
      type: CALCULATOR_LOAD,
      payload: data,
    });
    dispatch(setCalculatorLoading(false));
  } catch (error) {
    console.log(`Error creating calculator ${error}`);
  }
};

export const updateCalculator = (
  id: string,
  payload: Calculator,
): any => async (dispatch: Dispatch) => {
  try {
    const response = await updateCalculatorApi(id, payload);
    const { data } = response.data;
    dispatch({
      type: CALCULATOR_LOAD,
      payload: data,
    });
    return data;
  } catch (error) {
    console.log(`Error updating project: ${error}`);
  }
};

export const setCalculatorEditing = (isEditing: boolean) => ({
  type: CALCULATOR_EDITING,
  payload: isEditing,
});

export const editCalculator = () => async (dispatch: Dispatch) => {
  dispatch(setCalculatorEditing(true));
  navigate("../calculator");
};

export const getCalculator = (calculatorId: string) => async (
  dispatch: Dispatch,
) => {
  dispatch(setCalculatorLoading(true));
  try {
    const response = await getCalculatorApi(calculatorId);
    const { data } = response.data;
    dispatch({
      type: CALCULATOR_LOAD,
      payload: data,
    });
    dispatch(setCalculatorLoading(false));
  } catch (error) {
    console.log(`Error getting calculator ${error}`);
  }
};

const initialState: State = {
  calculator: {
    monetaryValueOfConsultants: [
      {
        consultantDescription: "Volunteer 1",
        hourlyRate: "150.00",
        totalHours: "0",
        totalValue: "0.00",
      },
    ],
    monetaryValueOfProducts: [
      {
        productDescription: "Product 1",
        totalValue: "0.00",
      },
    ],
    longTermBusinessValue: [
      {
        description: "",
        totalValue: "",
      },
      {
        description: "",
        totalValue: "",
      },
      {
        description: "",
        totalValue: "",
      },
    ],
    longTermBusinessValueUniqueToProBono: [
      {
        description: "",
        totalValue: "",
      },
      {
        description: "",
        totalValue: "",
      },
      {
        description: "",
        totalValue: "",
      },
    ],
    staffTime: [
      {
        staffMember: "Staff Member 1",
        hoursPerWeek: "0",
        numberOfWeeks: "0",
        hourlyCost: "0.00",
        totalValue: "0",
      },
    ],
    opportunityCostOfImplementation: [
      {
        descriptionOfOpportunity: "",
        totalValue: "",
      },
      {
        descriptionOfOpportunity: "",
        totalValue: "",
      },
    ],
    implementationAndMaintenance: [
      {
        description: "",
        totalValue: "",
      },
      {
        description: "",
        totalValue: "",
      },
      {
        description: "",
        totalValue: "",
      },
      {
        description: "",
        totalValue: "",
      },
    ],
    unquantifiableValue: [
      {
        description: "",
        valueWeight: "",
      },
      {
        description: "",
        valueWeight: "",
      },
    ],
  },
  calculatorLoaded: false,
  isLoading: false,
  isEditing: false,
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case CALCULATOR_LOAD:
      return {
        ...state,
        calculator: action.payload,
        calculatorLoaded: true,
        isEditing: false,
      };
    case CALCULATOR_UNLOAD:
      return {
        ...state,
        calculator: initialState.calculator,
        calculatorLoaded: initialState.calculatorLoaded,
        isLoading: initialState.isLoading,
        isEditing: initialState.isEditing,
      };
    case CALCULATOR_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case CALCULATOR_EDITING:
      return {
        ...state,
        isEditing: action.payload,
      };
    default:
      return state;
  }
};

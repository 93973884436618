import { VolunteerInformation, Partner } from "../../../typings";
import boardOfDirectors from "frontend-app/src/images/img-your-board-of-directors.svg";
import currentVolunteers from "frontend-app/src/images/img-current-volunteers.svg";
import currentDonors from "frontend-app/src/images/img-current-donors.svg";

import encore from "frontend-app/src/images/img-encore.png";
import aarp from "frontend-app/src/images/img-aarp.png";
import score from "frontend-app/src/images/img-score.png";

import allForGood from "frontend-app/src/images/img-all-for-good.png";
import taproot from "frontend-app/src/images/img-taproot-plus.png";
import volunteerMatch from "frontend-app/src/images/img-volunteermatch.png";
import catchFire from "frontend-app/src/images/img-catchafire.png";
import microMentor from "frontend-app/src/images/img-micromentor.png";
import movingWorlds from "frontend-app/src/images/moving_worlds.png";

import linkedInNonProfit from "frontend-app/src/images/img-linkedin-for-nonprofit.png";
import idealist from "frontend-app/src/images/img-idealist.png";

import Facebook from "frontend-app/src/images/img-facebook.png";
import Twitter from "frontend-app/src/images/img-twitter.png";
import linkedIn from "frontend-app/src/images/img-linkedin.png";
import Blogs from "frontend-app/src/images/img-blogs.png";

import CommonImpact from "frontend-app/src/images/img-common-impact-1.png";
import TaprootFoundation from "frontend-app/src/images/img-taproot-foundation-1.png";
import Pyxera from "frontend-app/src/images/img-pyxera.png";
import UnitedWay from "frontend-app/src/images/img-united-way-1.png";
import HandsOn from "frontend-app/src/images/img-hands-on.png";

import ChamberOfCommerce from "frontend-app/src/images/img-chamber-of-commerce.png";
import CharlesSchwab from "frontend-app/src/images/img-charles-schwab-1.png";
import Salesforce from "frontend-app/src/images/img-salesforce.png";

import TrustLaw from "frontend-app/src/images/img-trustlaw.png";
import McKinsey from "frontend-app/src/images/img-mckinsey.png";
import BCG from "frontend-app/src/images/img-bcg.png";
import PWC from "frontend-app/src/images/img-pwc.png";
import Accenture from "frontend-app/src/images/img-accenture.png";
import Deloitte from "frontend-app/src/images/img-deloitte.png";

import Inspiring from "frontend-app/src/images/img-inspiring-capital.png";
import JobsCareers from "frontend-app/src/images/img-jobs-careers.png";

import AccountingProfessionalsAssociations from "frontend-app/src/images/img-apa.png";
import HumanResourcesAssociations from "frontend-app/src/images/img-hra.png";
import MarketingAssociation from "frontend-app/src/images/img-da.png";
import BarAssociation from "frontend-app/src/images/img-ba.png";

export const partnerInformation: Partner[] = [
  // {
  //   name: "All For Good",
  //   description: {
  //     __html:
  //       "<b>All for Good</b> is a digital hub for volunteerism and community engagement, and a service of Points of Light.",
  //   },
  //   image: {
  //     src: allForGood,
  //     height: "6.25rem",
  //     width: "auto",
  //     alt: "All For Good",
  //     url: "https://www.allforgood.org/",
  //   },
  // },
  {
    name: "Taproot Plus",
    description: {
      __html:
        "<b>Taproot Plus</b> is an online platform built by the Taproot Foundation that helps nonprofit professionals describe their needs and connect to a curated group of motivated, ready skilled volunteers with the right skills to help.",
    },
    image: {
      src: taproot,
      height: "2.875rem",
      width: "auto",
      alt: "Taproot Plus",
      url: "https://www.taprootplus.org/",
    },
    viewLink: `${process.env.TAPROOT_ORIGIN}/nonprofits/dashboard/projects`,
    onClick: (options: { auth_id: string; project_id: string }) => {
      const { auth_id, project_id } = options;
      const config =
        window.TaprootPlusConfig || (window.TaprootPlusConfig = []);
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = `${process.env.TAPROOT_ORIGIN}${process.env.TAPROOT_SCRIPT!}`;
      const x = document.getElementsByTagName("script")[0];
      x.parentNode && x.parentNode.insertBefore(s, x);

      config.push({
        client_id: process.env.TAPROOT_CLIENT_ID,
        client_secret: process.env.TAPROOT_CLIENT_SECRET,
        partner: "capacity",
        auth_id,
        project_id,
        redirect_url: window.location.href,
      });

      // Wait for script to load then fire connect()
      s.addEventListener("load", () => {
        window.taproot.connect();
      });
    },
  },
  // {
  //   name: "Volunteer Match",
  //   description: {
  //     __html:
  //       "<b>VolunteerMatch</b> matches inspired people with inspiring causes.  It's how volunteers and nonprofits connect to achieve remarkable outcomes.",
  //   },
  //   image: {
  //     src: volunteerMatch,
  //     height: "7.25rem",
  //     width: "auto",
  //     alt: "Volunteer Match",
  //     url: "https://www.volunteermatch.org/",
  //   },
  // },
];

export const volunteerInformation: VolunteerInformation[] = [
  {
    mainTitle: "Individuals",
    data: [
      {
        title: "Your Network",
        description:
          "Begin your volunteer search with the individuals in your network who are already supports of your organization. Think broadly about the skill sets that are in your existing circles and consider structuring your conversations with prospective directors, volunteers, and donors differently so that you can open the door for a multi-pronged relationship that includes pro bono.",
        images: [boardOfDirectors, currentVolunteers, currentDonors],
        imageTitle: [
          "Board of Directors",
          "Current Volunteers",
          "Current Donors",
        ],
        imageUrl: "",
        tooltipInfo:
          "Professionals already in your network who have a set of functional skills that could be used to support your organization via pro bono",
      },
      {
        title: "Freelancers, Retirees, and Unemployed Professionals",
        description:
          "Freelancers, retirees, and unemployed professionals represent a group of highly skilled individuals with significant flexibility. Freelancers and unemployed professionals are often seeking opportunities to build their portfolio or resume and many retirees seek purpose-driven work after they have ended their first career. Consider the following sources to tap into these groups.",
        images: [encore, aarp, score],
        imageTitle: "",
        imageUrl: [
          "https://encore.org/",
          "https://www.aarp.org/",
          "https://score.org",
        ],
        tooltipInfo:
          "Professionals or former professionals with valuable skill sets and the flexibility to plug into purpose-driven or resume-building work",
      },
    ],
  },
  {
    mainTitle: "Online",
    data: [
      {
        title: "Online Matching Portals",
        description:
          "There are a number of platforms that exist with the sole mission of connection you with volunteers. Post your project on one or multiple of the following platforms to find volunteers with the skills you seek.",
        images: [
          allForGood,
          taproot,
          volunteerMatch,
          catchFire,
          microMentor,
          movingWorlds,
        ],
        imageTitle: "",
        imageUrl: [
          "https://www.allforgood.org/",
          "https://www.taprootplus.org/",
          "https://www.volunteermatch.org/",
          "https://www.catchafire.org/",
          "https://www.micromentor.org/",
          "https://movingworlds.org/join-org?utm_source=Capacity_Commons",
        ],
        tooltipInfo:
          "Online portals that match nonprofits to qualified volunteers based on interest and skills",
      },
      {
        title: "Online Job Boards",
        description:
          "Social sector job boards often also feature volunteering opportunities. Posting your desired volunteer profile on a job board is a great way to get significant visibility and find interested talent, due to the high foot traffic on these sites and the resume and skill-building benefits that pro bono volunteering offers for job seekers.",
        images: [linkedInNonProfit, idealist],
        imageTitle: "",
        imageUrl: [
          "https://nonprofit.linkedin.com/",
          "https://www.idealist.org/en/?type=JOB",
        ],
        tooltipInfo: "Job boards that also feature volunteer opportunities",
      },
      {
        title: "Social Media",
        description:
          "Social media functions as a marketplace and vehicle for practical connections. Utilize your organization's or your personal social media channels to share your organization's skills-based volunteering opportunities with a broad network.",
        images: [Facebook, Twitter, linkedIn, Blogs],
        imageTitle: ["", "", "", "Blogs"],
        imageUrl: [
          "https://www.facebook.com/",
          "https://twitter.com/?lang=en",
          "https://linkedin.com/",
          "",
        ],
        tooltipInfo:
          "Forums that enable you to share pro bono needs to a broader, online network",
      },
    ],
  },
  {
    mainTitle: "Institutions",
    data: [
      {
        title: "Intermediaries",
        description:
          "Select organizations specialize in facilitating successful skills-based volunteering partnerships. If you desire a more guided pro bono journey, consider getting in touch with an intermediary who can facilitate and oversee your pro bono project. These organizations include pro bono specialists (Common Impact, Taproot Foundation, PYXERA Global), as well as groups that offer pro bono services as part of broader volunteerism programming (such as some United Way Chapters and the Points of Light Global Network).",
        images: [CommonImpact, TaprootFoundation, Pyxera, UnitedWay, HandsOn],
        imageTitle: "",
        imageUrl: [
          "https://commonimpact.org/",
          "https://taprootfoundation.org/",
          "https://www.pyxeraglobal.org/",
          "https://www.unitedway.org/",
          "https://www.pointsoflight.org/",
        ],
        tooltipInfo:
          "Organizations that recruit and manage pro bono resources for a nonprofit or social sector organization",
      },
      {
        title: "Companies",
        description:
          "Over 50% of corporations offer a formal skills-based volunteering program. Companies are a great source of talent and often seek philanthropic and employee engagement opportunities. Start conversations with the companies already in your network to uncover to full suite of skills they have in-house. If you need to forge a new relationship, consider local businesses (check your Chamber of Commerce), large companies with a presence in your area (e.g. Charles Schwab), and/or companies that offer pro bono support along with a desired product or service (e.g Salesforce).",
        images: [ChamberOfCommerce, CharlesSchwab, Salesforce],
        imageTitle: "",
        imageUrl: [
          "https://www.chamberofcommerce.com/chambers",
          "https://www.aboutschwab.com/citizenship",
          "https://www.salesforce.org/volunteers/probono/",
        ],
        tooltipInfo:
          "Corporations that have an existing employee engagement or skills-based program or are in the practice of supporting employees to lend their skills pro bono",
      },
      {
        title: "Professional Services Firms",
        description:
          "Most professional services firms include pro bono services in their portfolio. Many firms will invite you to submit a Request for Proposal (RFP) for their services or will offer another formal avenue through which you can solicit pro bono support.",
        images: [TrustLaw, McKinsey, BCG, PWC, Accenture, Deloitte],
        imageTitle: "",
        imageUrl: [
          "http://www.trust.org/trustlaw/",
          "https://www.mckinsey.com/about-us/social-responsibility",
          "https://www.bcg.com/capabilities/social-impact/overview.aspx",
          "http://responsible.pwc.com/performance/pro-bono.html",
          "https://www.accenture.com/cz-en/careers/probono",
          "https://www2.deloitte.com/us/en/pages/about-deloitte/articles/corporate-citizenship-pro-bono-skills-based-volunteering.html",
        ],
        tooltipInfo:
          "Companies that provide consultative services as their business. Examples include design firms, law firms, management consulting firms, etc.",
      },
      {
        title: "Professional Schools",
        description:
          "Many professional schools offer student paid internships at social organizations or integrate pro bono consulting into their coursework. Get in touch with a school's career center to understand what pre-existing programs your project might fit into and/or reach out to an intermediary organization, like Inspiring Capital, who connects MBA fellows to social sector organizations.",
        images: [Inspiring, JobsCareers],
        imageTitle: ["", "Job / Career Pages on University Websites"],
        imageUrl: ["https://www.inspiringcapital.ly/", ""],
        tooltipInfo: "Business (MBA), IT, and design schools",
      },
      {
        title: "Professional Associations",
        description:
          "There are a number of national or local professional associations that serve as hubs for individuals with specific areas of expertise. An online search for associations in your region and area of need (e.g HR, Legal) will reveal which of these associations have local chapters that you can reach out to. Examples of common associations include:",
        images: [
          AccountingProfessionalsAssociations,
          HumanResourcesAssociations,
          MarketingAssociation,
          BarAssociation,
        ],
        imageTitle: [
          "Accounting Professionals Associations",
          "Human Resources Associations",
          "Marketing Associations",
          "Bar Association",
        ],
        imageUrl: [
          "https://jobstars.com/accounting-professional-associations-organizations/",
          "https://jobstars.com/human-resource-professional-associations-organizations/",
          "https://www.ama.org/",
          "http://www.statebarassociations.org/",
        ],
        tooltipInfo:
          "National organizations with local and regional members, e.g. associations of human resources professionals, design associations, bar associations, etc.",
      },
    ],
  },
];

export const imgsToBeResized = [
  "Blogs",
  "Job / Career Pages on University Websites",
  "Accounting Professionals Associations",
  "Human Resources Associations",
  "Marketing Associations",
  "Bar Association",
];

import * as React from "react";
import { Link } from "@reach/router";
import styled from "styled-components-themed";
import { Heading, Text, Button } from "grommet";
import { MdArrowForward } from "react-icons/md";
import {
  assessments as organizationAssessments,
  assessmentMap as organizationAssessmentMap,
} from "../../data/organization";
import {
  assessments as projectAssessments,
  assessmentMap as projectAssessmentMap,
} from "../../data/project";
import { AssessmentContext } from "../Assessment/Store";
import { getAssessmentScore } from "../Assessment/utils";
import {
  ResultsParagraph,
  LeftColumn,
  RightColumn,
  TopSection,
  ScoreLabel,
  ScoreContainer,
  BottomSection,
  BottomTitle,
  ButtonsContainer,
  Image,
} from "./styles";
import ResultList from "./ResultList";
import { media } from "shared-ui";

const Wrapper = styled.div`
  padding-top: 2rem;

  ${media.large`
    display: flex;
  `}
`;

const OrganizationResults: React.FunctionComponent<{}> = () => {
  const { state } = React.useContext(AssessmentContext);
  const assessments =
    state.type === "Project" ? projectAssessments : organizationAssessments;
  const assessmentMap =
    state.type === "Project" ? projectAssessmentMap : organizationAssessmentMap;
  const assessment = getAssessmentScore(assessments, state.totalScore);
  const maxPoints = assessments[0].between[1];
  const {
    icon: AssessmentIcon,
    color: assessmentColor,
    sideImage,
    topBlurb,
    sideBlurb,
  } = assessmentMap[assessment.label as keyof typeof assessmentMap];

  return (
    <Wrapper>
      <LeftColumn>
        <Heading
          level="1"
          margin={{ bottom: "1.5rem", top: "1rem" }}
          color="#244B5A"
        >
          Organizational Readiness Assessment
        </Heading>
        <Heading level="3">Your Results</Heading>
        <ResultsParagraph
          margin="none"
          dangerouslySetInnerHTML={{ __html: topBlurb }}
        />
        <ResultList />
      </LeftColumn>
      <RightColumn>
        <TopSection>
          <ScoreLabel>YOUR SCORE</ScoreLabel>
          <ScoreContainer>
            <Heading size="large" color={assessmentColor}>
              {state.totalScore}
            </Heading>
            <Text size="small">out of {maxPoints}</Text>
          </ScoreContainer>
        </TopSection>
        <BottomSection>
          <BottomTitle>
            <AssessmentIcon color={assessmentColor} size="32px" />
            <Text data-testid="assessment-result" margin={{ left: "0.625rem" }}>
              {assessment.label}
            </Text>
          </BottomTitle>
          <ResultsParagraph size="small">{sideBlurb}</ResultsParagraph>
          <ButtonsContainer>
            <Link to={`../step`}>
              <Button
                label="Retake the Assessment"
                primary={true}
                color="#228DC1"
                icon={<MdArrowForward />}
                fill="horizontal"
                reverse={true}
                margin="xsmall"
              />
            </Link>
            <Link to="/projects/new">
              <Button
                label="Start a project"
                plain={true}
                icon={<MdArrowForward />}
                reverse={true}
                margin="small"
              />
            </Link>
          </ButtonsContainer>
          <Image alt={assessment.label} src={sideImage} />
        </BottomSection>
      </RightColumn>
    </Wrapper>
  );
};

export default OrganizationResults;

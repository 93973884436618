import { Organization } from "organization/typings";
import ApiClient from "./";

const organizationsPath = "/api/organizations";

export function getOrganizations() {
  return ApiClient.get(organizationsPath);
}

export function getOrganization(id: string) {
  return ApiClient.get(`${organizationsPath}/${id}`);
}

export function createOrganization(payload: Organization) {
  return ApiClient.post(organizationsPath, payload);
}

export function updateOrganization(id: string, payload: Organization) {
  return ApiClient.put(`${organizationsPath}/${id}`, payload);
}

export function deleteOrganization(id: string) {
  return ApiClient.delete(`${organizationsPath}/${id}`);
}

import * as React from "react";
import styled from "styled-components-themed";
import { TextArea as TextAreaBase, TextAreaProps } from "grommet";

interface Props extends TextAreaProps {
  leadingText?: string;
  ellipsis?: string;
  initialValue?: string;
  onChange?: (event: React.FormEvent<HTMLTextAreaElement>, value?: any) => void;
  onBlur?: (event: React.FormEvent<HTMLTextAreaElement>, value?: any) => void;
}

const StyledTextArea = styled(TextAreaBase)`
  font-weight: 400;
`;

const removeLeadingText = (leadingText: string, value: string) =>
  value.substring(leadingText.length);

const TextArea: React.FunctionComponent<Props> = (props) => {
  const {
    leadingText = "",
    ellipsis = "",
    initialValue,
    onChange,
    onBlur,
    ...restProps
  } = props;
  const [touched, setTouched] = React.useState(false);
  const [value, setValue] = React.useState(
    `${leadingText}${initialValue || ellipsis}`,
  );

  const handleChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const eventValue = event.currentTarget.value;
    const hasLeadingText = eventValue.startsWith(leadingText);
    let newValue = "";

    if (hasLeadingText) {
      // If leading text is there, we're good, let's append the value.
      newValue = eventValue;
    } else {
      if (eventValue.length < leadingText.length) {
        // User is trying to delete leading text. Reset value to leading text.
        newValue = leadingText;
      } else {
        // User is trying to insert text in the middle of leading text.. Append value.
        newValue = `${leadingText}${eventValue.substring(leadingText.length)}`;
      }
    }

    setValue(newValue);

    if (onChange) {
      onChange(event, {
        actualValue: removeLeadingText(leadingText, newValue),
      });
    }
  };

  const handleKeyPress = (event: React.FormEvent<HTMLTextAreaElement>) => {
    if (event.currentTarget.selectionStart <= leadingText.length) {
      event.currentTarget.selectionStart = value.length;
    }
    if (event.currentTarget.selectionEnd <= leadingText.length) {
      event.currentTarget.selectionEnd = value.length;
    }
  };

  const handleFocus = () => {
    if (!touched) {
      setTouched(true);
      // If value has ellipsis, let's remove it from leadingText.
      if (`${leadingText}${ellipsis}` === value) {
        setValue(`${leadingText}`);
      } else {
        setValue(`${leadingText}${initialValue}`);
      }
    }
  };

  const handleBlur = (event: React.FormEvent<HTMLTextAreaElement>) => {
    if (leadingText === value) {
      // Value hasn't changed, add ellipsis back on
      setTouched(false);
      setValue(`${leadingText}${ellipsis}`);
    }

    if (onBlur) {
      onBlur(event, { actualValue: removeLeadingText(leadingText, value) });
    }
  };

  return (
    <StyledTextArea
      {...restProps}
      resize={false}
      value={value}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyPress={handleKeyPress}
      onChange={handleChange}
    />
  );
};

export default TextArea;

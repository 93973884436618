import React from "react";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import styled from "styled-components-themed";
import { connect } from "react-redux";
import { RootState } from "frontend-app/typings";
import { Box, Heading } from "grommet";
import { Avatar, media } from "shared-ui";
import { loadOrganization } from "organization/src/reducer";
import AccountForm from "./AccountForm";

interface Props {
  fullName: string;
  email: string;
  loadOrganizationAction: typeof loadOrganization;
}

const Wrapper = styled.div`
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
  padding: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const AvatarBox = styled(Box)`
  margin-bottom: 2rem;

  ${media.large`
    padding-top: 5.5rem;
  `}
`;

const Home: React.FunctionComponent<Props> = (props) => {
  const { fullName, email, loadOrganizationAction } = props;

  React.useEffect(() => {
    (async () => {
      await loadOrganizationAction();
    })();
  }, []);

  return (
    <Wrapper>
      <Box direction="row-responsive">
        <AvatarBox margin={{ right: "2.5rem" }}>
          <Avatar name={fullName || email} height="6.25rem" width="6.25rem" />
        </AvatarBox>
        <Box>
          <Heading level={1} color="#2d4a58" margin={{ vertical: "small" }}>
            My Account
          </Heading>
          <AccountForm />
        </Box>
      </Box>
    </Wrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  fullName: state.session.fullName,
  email: state.session.email,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      loadOrganizationAction: loadOrganization,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);

import * as auth0 from "auth0-js";
import { navigate } from "@reach/router";
import { setAuthToken } from "api";
import { createUser } from "api/user";

const isBrowser = typeof window !== "undefined";

interface UserSession {
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  roles: string[];
}

class Auth {
  private accessToken?: string;
  private idToken?: string;
  private email?: string;
  private userId?: string;

  public stateCallback = (_state: { isLoggedIn: boolean }) => {};

  private auth0 = process.env.AUTH0_DOMAIN
    ? new auth0.WebAuth({
        domain: process.env.AUTH0_DOMAIN!,
        clientID: process.env.AUTH0_CLIENT_ID!,
        redirectUri: process.env.AUTH0_CALLBACK_URL!,
        audience: process.env.AUTH0_AUDIENCE!,
        responseType: "token id_token",
        scope: "openid email profile",
      })
    : undefined;

  public login = () => {
    if (!isBrowser) return;
    localStorage.setItem("postLoginUrl", window.location.pathname);
    this.auth0 && this.auth0.authorize();
  };

  public handleAuthentication = () =>
    new Promise((resolve, reject) => {
      this.auth0 &&
        this.auth0.parseHash((err, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            this.setSession(authResult);

            createUser(authResult.idTokenPayload)
              .then(() => {
                const postLoginUrl = localStorage.getItem("postLoginUrl");
                localStorage.removeItem("postLoginUrl");
                if (postLoginUrl) {
                  navigate(postLoginUrl);
                }
                return resolve(authResult);
              })
              .catch((error) => {
                console.error("Create user error:", error);
                return reject(error);
              });
          } else if (err) {
            return reject(err);
          } else {
            return resolve();
          }
        });
    });

  public getAccessToken = () => this.accessToken;

  public getIdToken = () => this.idToken;

  public getEmail = () => this.email;

  public getUserId = () => this.userId;

  public getProfile = () =>
    new Promise((resolve, reject) => {
      if (!this.accessToken) return resolve();
      this.auth0 &&
        this.auth0.client.userInfo(this.accessToken, (err, profile) => {
          if (err) {
            return reject(err);
          }
          return resolve(profile);
        });
    });

  private setSession(authResult: auth0.Auth0DecodedHash) {
    if (!isBrowser) return;
    // Set isLoggedIn flag in localStorage
    localStorage.setItem("isLoggedIn", "true");
    this.stateCallback({ isLoggedIn: true });
    // Update axios Authorization header
    setAuthToken(authResult.accessToken);

    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.email = authResult.idTokenPayload.email;
    this.userId = authResult.idTokenPayload.sub;
  }

  public checkSession = () =>
    new Promise((resolve) => {
      this.auth0 &&
        this.auth0.checkSession({}, (err, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            this.setSession(authResult);
            return resolve(authResult);
          }
          if (err && err.error === "login_required") {
            // User has been logged out from Auth0 server.
            // Remove local session.
            this.localLogout();
          }
          return resolve();
        });
    });

  public localLogout = () => {
    if (!isBrowser) return;
    // Remove tokens and expiry time
    this.accessToken = undefined;
    this.idToken = undefined;
    this.email = undefined;
    this.userId = undefined;

    setAuthToken(undefined);

    // Remove isLoggedIn flag from localStorage
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("session");
    this.stateCallback({ isLoggedIn: false });
  };

  public logout = () => {
    if (!isBrowser) return;
    this.localLogout();
    this.auth0 &&
      this.auth0.logout({
        returnTo: window.location.origin,
      });
  };

  public isAuthenticated = () => {
    if (!isBrowser) return false;
    return localStorage.getItem("isLoggedIn") === "true";
  };

  public getUserSession = (): UserSession => {
    const session = isBrowser && localStorage.getItem("session");
    return session
      ? JSON.parse(session)
      : { userId: "", email: "", firstName: "", lastName: "", roles: [] };
  };

  public setUserSession = (user: UserSession) => {
    localStorage.setItem("session", JSON.stringify(user));
  };
}

const auth = new Auth();

// expose Auth in Cypress
if (isBrowser && window.Cypress) {
  window.auth = auth;
}

export default auth;

import * as React from "react";
import auth from "../services/Auth";

const useAuth = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoggedIn, setIsLoggedIn] = React.useState(auth.isAuthenticated());
  const [profile, setProfile] = React.useState(auth.getUserSession());

  React.useEffect(() => {
    auth.stateCallback = (state) => setIsLoggedIn(state.isLoggedIn);
    (async () => {
      await auth.checkSession();
      try {
        const user: any = await auth.getProfile();
        if (user) {
          // Need to set up Auth0 rules to get user profile metadata. Read more:
          // https://auth0.com/docs/api-auth/tutorials/adoption/scope-custom-claims#custom-claims
          const userMetadata =
            user["https://app.capacitycommons.org/user_metadata"];
          const appMetadata =
            user["https://app.capacitycommons.org/app_metadata"];
          setIsLoggedIn(true);
          const userSession = {
            userId: user.sub,
            email: user.email,
            firstName: userMetadata ? userMetadata.first_name : "",
            lastName: userMetadata ? userMetadata.last_name : "",
            roles: appMetadata ? appMetadata.roles : [],
          };
          setProfile(userSession);
          auth.setUserSession(userSession);
        }
      } catch (error) {
        console.log(`Error: ${error}`);
      }

      setIsLoading(false);
    })();

    return () => {
      // Clean up stateCallback
      auth.stateCallback = () => {};
    };
  }, []);

  return {
    isLoading,
    isLoggedIn,
    email: profile.email,
    firstName: profile.firstName,
    lastName: profile.lastName,
    roles: profile.roles,
    userId: profile.userId,
  };
};

export default useAuth;

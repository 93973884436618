export interface FieldTypes {
  [key: string]: {
    [key: string]: string;
  };
}

const fieldTypes: FieldTypes = {
  monetaryValueOfConsultants: {
    consultantDescription: "Volunteer",
    hourlyRate: "150.00",
    totalHours: "0",
    totalValue: "0.00",
  },
  monetaryValueOfProducts: {
    productDescription: "Product",
    totalValue: "0.00",
  },
  longTermBusinessValue: {
    description: "",
    totalValue: "",
  },
  longTermBusinessValueUniqueToProBono: {
    description: "",
    totalValue: "",
  },
  staffTime: {
    staffMember: "Staff Member",
    hoursPerWeek: "0",
    numberOfWeeks: "0",
    hourlyCost: "0.00",
    totalValue: "0",
  },
  opportunityCostOfImplementation: {
    descriptionOfOpportunity: "",
    totalValue: "",
  },
  implementationAndMaintenance: {
    description: "",
    totalValue: "",
  },
  unquantifiableValue: {
    description: "",
    valueWeight: "",
    totalValue: "",
  },
};

export default fieldTypes;

export default {
  "Potential to Create Deep Social Impact": {
    blurb:
      "Organizations that have the potential to create social impact demonstrate a clear strategic vision and theory of change. Having institutional strategic goals that drive your operations and program measurement efforts will ensure that a pro bono project translates into tangible social impact for your constituents and the broader community.",
    resources: [
      {
        title: "On Strategic Planning",
        items: [
          "<a target='_blank' href='https://www.bridgespan.org/insights/library/strategy-development/strategic-planning-resources'>The Bridgespan Group’s Strategic Planning resources</a> - a starting place for strengthening your organization’s strategic vision; check out their guide on <a target='_blank' href='https://www.bridgespan.org/insights/library/strategy-development/living-into-strategic-plan-implemention-guide'>Living Into Your Strategic Plan</a>",
          "<a target='_blank' href='https://managementhelp.org/strategicplanning/index.htm'>Strategic Planning Resources from the Free Management Library</a> - a library of strategic planning resources for nonprofit and for-profit organizations",
        ],
      },
      {
        title: "On Impact Measurement and Evaluation",
        items: [
          "<a target='_blank' href='https://www.nten.org/NTEN_images/reports/Data_Workbook_FINAL.pdf'>Data workbook from NTEN</a> - a set of worksheets to guide thinking and planning around more effective data utilization strategies",
          "<a target='_blank' href='https://www.twosigma.com/about/data-clinic/'>Two Sigma Data Clinic</a> - supports nonprofits, government agencies, and academic institutions to quantify impact and enact data-driven strategies",
        ],
      },
    ],
  },
  "Organizational Stability": {
    blurb:
      "Organizational stability refers to staff retention, funding maintenance, and consistent program delivery. A state of relative stability is required for you to anticipate the resources you can dedicate to executing a skills-based volunteering project and to guaranteeing its long-term social impact results. Risks to stability exist for all organizations; it is not their presence that is a deterrent for engaging in skilled volunteering, but the absence of a well thought-out mitigation plan.",
    resources: [
      "<a target='_blank' href='https://www.nonprofitrisk.org/resources/articles/competition-risk-aware-definition/'>Nonprofit Risk Management Center’s collection of free articles</a> - tools and recommendations around a number of organizational risk topics",
      "<a target='_blank' href='https://nff.org/learn/fundamentals-nonprofits'>Nonprofit Finance Fund</a> - a collection of free resources on developing robust financial health and risk mitigation strategies",
      "<a target='_blank' href='https://ssir.org/articles/entry/the_nonprofit_leadership_development_deficit'>The Nonprofit Leadership Development Deficit</a> - a Stanford Social Innovation Review article from The Bridgespan Group about nonprofit staff retention challenges and costs",
    ],
  },
  "Strong Executive Leadership": {
    blurb:
      "Strong executive leadership conveys a compelling social vision, listens to stakeholders, and innovates to meet changing client needs. Such leadership inspires volunteers to connect with your organization’s mission, which drives their motivation and commitment to serve your organization’s needs effectively. For skills-based volunteering to be successful, you’ll need an executive team thatis invested in building capacity through pro bono and able to make key, strategic decisions that inform project outcomes.",
    resources: [
      "<a target='_blank' href='https://www.capacitycommons.org/guidebook/prepare/make-the-internal-business-case/'>Skills-Based Volunteering Internal Business Case</a> - a tool to increase executive buy-in for skills-based volunteering and capacity-building endeavors",
      "<a target='_blank' href='https://boardsource.org/fundamental-topics-of-nonprofit-board-service/'>BoardSource</a> - an extensive array of resources to support nonprofit governance and leadership. Begin with fundamental topics like <a target='_blank' href='https://boardsource.org/fundamental-topics-of-nonprofit-board-service/board-staff-partnership/'>The Board-Staff Partnership</a>, <a target='_blank' href='https://boardsource.org/fundamental-topics-of-nonprofit-board-service/roles-responsibilities/'>Roles & Responsibilities</a>, and <a target='_blank' href='https://boardsource.org/fundamental-topics-of-nonprofit-board-service/culture-dynamics/'>Culture & Dynamics</a>",
      "<a target='_blank' href='https://www.compasspoint.org/blog/tag/leadership'>Compass Points of View</a> - newsletters, research, blogs, and articles that report on leadership trends in the field and share actionable tools and resources",
      "<a target='_blank' href='https://grantspace.org/search/keyword/leadership+management?redirect_source=/skills/leadership-management'>Grantspace</a> - a collection of courses, webinars, and articles on a variety of topics within governance and management",
    ],
  },
  "Commitment to Capacity Building": {
    blurb:
      "Skills-based volunteering projects often focus on strengthening organizational infrastructure to increase your organization’s ability to serve your mission effectively and efficiently. Commitment to capacity-building can be hard for mission-driven organizations who prioritize the delivery of their important programs, but indicate an investment in long-term organizational growth and health. Before taking on a pro bono initiative, you’ll need to ensure that your leadership and staff are committed to carving out the time and resources for this work. ",
    resources: [
      "<a target='_blank' href='https://www.capacitycommons.org/guidebook/prepare/make-the-internal-business-case/'>Skills-Based Volunteering Internal Business Case</a> - a tool to increase executive buy-in for skills-based volunteering and capacity-building endeavors",
      "<a target='_blank' href='https://www.capacitycommons.org/guidebook/scope/value-your-project/'>The Pro Bono Calculator</a> - an interactive tool to understand how devoting resources to skills-based volunteering saves you additional time and resources in the future",
      "<a target='_blank' href='https://www.councilofnonprofits.org/tools-resources/what-capacity-building'>The National Council of Nonprofits</a> - a library of free online resources to help your organization understand its capacity-building needs",
      "<a target='_blank' href='https://commonimpact.org/pdf/Common_Impact_Report.pdf'>Redefining the Solution: Doing More with Less</a> - a report by Common Impact and Capital One on the nonprofit capacity gap and key solutions",
    ],
  },
  "Effective Relationship Building": {
    blurb:
      "An organization with effective relationship-building strategies will unlock the inherent potential of skills-based volunteering,. Organizations with a track record of seeking out and developing strong, mutually beneficial relationships across sectors, perspectives, backgrounds, and cultures and of evolving as a result of those collaborations will be well-positioned to effectively engage with and benefit from your skilled volunteers - over the course of your project and beyond. ",
    resources: [
      "<a target='_blank' href='https://www.capacitycommons.org/guidebook/implement/create-an-equitable-and-productive-dynamic/'>Navigating Power Dynamics</a> - a set of best practices for building equitable and productive relationships across sectors",
      "<a target='_blank' href='https://hbr.org/2007/11/eight-ways-to-build-collaborative-teams'>8 Ways to Build Collaborative Teams</a> - an article from Harvard Business Review",
      "<a target='_blank' href='https://www.classy.org/blog/5-relationship-building-hacks-for-nonprofit-professionals/'>5 Relationship-Building Hacks for Nonprofit Professionals</a> - an article from Classy that offers ways nonprofit organizations can strategically think about building relationships",
      "<a target='_blank' href='https://www.capacitycommons.org/guidebook/evaluate/define-key-stakeholder-metrics/'>Measure Impact for Different Stakeholders</a> - a tool that breaks down stakeholder objectives so your organization can gather feedback from partners and identify opportunities for growth and improvement",
    ],
  },
  "Strategic Engagement of Volunteers": {
    blurb:
      "Managing volunteers is likely familiar to you already and may even be an integral part of the way you deliver services. Strategic engagement of volunteers goes beyond effective volunteer management to develop long-term relationships with volunteers that yield organizational growth - on the front lines of your programs as well as in your capacity-building efforts. ",
    resources: [
      "<a target='_blank' href='https://www.pointsoflight.org/blog/building-capacity-strategic-volunteer-engagement/'>Building Capacity: Strategic Volunteer Engagement</a> - an article from Points of Light on why strategic volunteer engagement is important and why organizations feel the tension to implement it correctly ",
      "<a target='_blank' href='https://www.capacitycommons.org/guidebook/scope/value-your-project/'>The Pro Bono Calculator</a> - an interactive tool to help plan for the hours and additional resources a skills-based volunteering project requires so you can prepare to allocate them",
    ],
  },
};

import React from "react";
import { Router, Redirect } from "@reach/router";
import { Route } from "shared-ui";
import { AssessmentType } from "../typings";
import Home from "./pages/Home";
// import List from "./pages/List";
import Results from "./pages/Results";
import Assessment from "./pages/Assessment";
import AssessmentStep from "./pages/Assessment/Step";
import AssessmentStore from "./pages/Assessment/Store";

interface Props {
  basepath?: string;
  parentId: string;
  type: AssessmentType;
}

const Routes: React.FunctionComponent<Props> = (props) => {
  const { basepath = "", parentId, type } = props;

  return (
    <AssessmentStore parentId={parentId} type={type}>
      <Router basepath={basepath} primary={false}>
        <Redirect default noThrow from="/" to={`${basepath}/start`} />
        <Route component={Home} path="start" />
        {/* <Route component={List} path="list" /> */}
        <Route component={Assessment} path="step">
          <Redirect default noThrow from="/" to={`${basepath}/step/1`} />
          <Route component={AssessmentStep} path=":step" />
        </Route>
        <Route component={Results} path="results" />
      </Router>
    </AssessmentStore>
  );
};

export default Routes;

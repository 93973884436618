import React from "react";
import styled from "styled-components-themed";
import { VolunteerRightColumn } from "../../components/Volunteers/";

const Wrapper = styled.div`
  padding-top: 2rem;
`;

interface Props {
  projectId: string;
}

const FindVolunteers: React.FunctionComponent<Props> = ({ projectId }) => {
  return (
    <Wrapper>
      <VolunteerRightColumn projectId={projectId} />
    </Wrapper>
  );
};

export default FindVolunteers;

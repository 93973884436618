import React from "react";
import styled from "styled-components-themed";
import { connect } from "react-redux";
import { Calculator } from "calculator/typings";
import {
  organizationalValue,
  organizationalInvestment,
} from "@rr/utils/calculator/groups";
import { calculateGroupTotals } from "@rr/utils/calculator/totals";
import { displayUSD } from "@rr/utils";
import { Box, Heading, Text } from "grommet";
import { MdClose } from "react-icons/md";
import { FaShareSquare, FaCheckCircle } from "react-icons/fa";
import { RootState } from "frontend-app/typings";

interface Props {
  values: Calculator;
  calculatorLoaded: boolean;
}
const LeftColumn = styled.div`
  width: calc(30% - 2rem);
  border-radius: 1.5625rem;
  height: 100%;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
`;

const LeftTopColumnContainer = styled.div`
  background: #f7f9f8;
`;

const LeftBottomColumnContainer = styled.div`
  background: #f7f9f8;
  margin-top: 2rem;
  padding: 0 2rem;
`;

const LeftColumnBox = styled(Box)`
  padding: 0 2rem;
`;

const VolunteerLeftColumn: React.FunctionComponent<Props> = ({
  values,
  calculatorLoaded,
}) => {
  return (
    <LeftColumn>
      <LeftTopColumnContainer>
        <LeftColumnBox
          margin={{ top: "1rem", bottom: "1rem" }}
          direction="row"
          justify="between"
          border={{ side: "bottom", color: "grey" }}
        >
          <Heading level="4" color="#244B5A" margin={{ bottom: "1rem" }}>
            To Do
          </Heading>
          <MdClose />
        </LeftColumnBox>
        <LeftColumnBox
          margin={{ bottom: "2rem" }}
          border={{ side: "bottom", color: "grey" }}
        >
          <Box direction="row" margin={{ bottom: "1rem" }}>
            <FaCheckCircle />
            <Heading level="6">Project Scope</Heading>
          </Box>
          <Text margin={{ bottom: "1rem" }}>Status Bar Component</Text>
        </LeftColumnBox>
        <LeftColumnBox
          margin={{ bottom: "2rem" }}
          border={{ side: "bottom", color: "grey" }}
        >
          <Box direction="row" margin={{ bottom: "1rem" }}>
            <FaCheckCircle />
            <Heading level="6">Estimated Net Value</Heading>
          </Box>
          <Box direction="row" justify="end">
            <Heading level="4">
              {calculatorLoaded
                ? displayUSD(
                    calculateGroupTotals(organizationalValue, values) -
                      calculateGroupTotals(organizationalInvestment, values),
                  )
                : "$ 0.00"}
            </Heading>
          </Box>
          <Text size="xsmall" margin={{ bottom: "1rem" }}>
            This is the net cost of using skills-based volunteers for this
            project, including assessing organizational value and investment.
          </Text>
        </LeftColumnBox>
        <LeftColumnBox>
          <Box direction="row" margin={{ bottom: "1rem" }}>
            <FaCheckCircle />
            <Heading level="6">Project Readiness</Heading>
          </Box>
          <Box direction="column" alignSelf="end">
            <Heading level="4">You{"'"}re Ready</Heading>
            <Heading level="6">133 our of 155</Heading>
          </Box>
          <Text size="xsmall" margin={{ bottom: "2rem" }}>
            Congratulations, your project is ready to engage with skilled
            volunteers.
          </Text>
        </LeftColumnBox>
        <LeftColumnBox background="#244B5A">
          <Heading level="6">Discover Skills-Based Volunteers</Heading>
          <Text size="xsmall">
            Explore the various resourced available for finding skilled
            volunteers for your project.
          </Text>
        </LeftColumnBox>
      </LeftTopColumnContainer>
      <LeftBottomColumnContainer>
        <Heading level="4" margin={{ top: "1rem", bottom: "1rem" }}>
          For Inspiration
        </Heading>
        <Heading level="6" margin={{ bottom: ".5rem" }}>
          <FaShareSquare size="16px" />
          <a href="#">Preview a Sample Project</a>
        </Heading>
        <Heading level="6" margin={{ bottom: "1rem" }}>
          <FaShareSquare size="16px" />
          <a href="#">Explore Different Project Types</a>
        </Heading>
      </LeftBottomColumnContainer>
    </LeftColumn>
  );
};

const mapStateToProps = (state: RootState) => {
  const { calculator, calculatorLoaded } = state.calculator;
  return {
    values: {
      monetaryValueOfConsultants: calculator.monetaryValueOfConsultants,
      monetaryValueOfProducts: calculator.monetaryValueOfProducts,
      longTermBusinessValue: calculator.longTermBusinessValue,
      longTermBusinessValueUniqueToProBono:
        calculator.longTermBusinessValueUniqueToProBono,
      staffTime: calculator.staffTime,
      opportunityCostOfImplementation:
        calculator.opportunityCostOfImplementation,
      implementationAndMaintenance: calculator.implementationAndMaintenance,
      unquantifiableValue: calculator.unquantifiableValue,
    },
    calculatorLoaded,
  };
};

export default connect(mapStateToProps)(VolunteerLeftColumn);

import { AssessmentScore, QuestionData } from "../../../typings";

export const assessments: AssessmentScore[] = [
  {
    label: "You're ready",
    between: [121, 165],
  },
  {
    label: "Almost there",
    between: [89, 120],
  },
  {
    label: "Work to do",
    between: [0, 89],
  },
];

export const questionsData: QuestionData[] = [
  {
    title: "Potential to Create Deep Social Impact",
    items: [
      "Our organization has a clear, strategic vision or defined strategic plan for the next three years.",
      "Our organization has clearly identified focus areas that will allow it to experience sustainable growth and/or deeper impact.",
      "All of our programs are effective in helping our organization meet its mission.",
      "Our organization works to hone our programs over time to make them more effective and meet changing needs.",
      "Our organization has a clear understanding of how a skills-based volunteer project would help it achieve its aforementioned goals for sustainable growth and/or deeper impact.",
      "Our organization has a clearly defined outcomes measurement system, i.e. metrics for success on organizational and program goals.",
      "Though our organization may be designed to serve a specific population, we make every effort to ensure our services are accessible to people of all backgrounds and do not discriminate against individuals based on any  aspect of their identity.",
    ],
    assessments: [
      {
        label: "You're ready",
        between: [21, 35],
      },
      {
        label: "Almost there",
        between: [15, 20],
      },
      {
        label: "Work to do",
        between: [0, 14],
      },
    ],
  },
  {
    title: "Organizational Stability",
    items: [
      "Our organization has not experienced significant swings in operating budget size [unless related to stable growth] over the past three years.",
      "Our organization has maintained 70-80% of our funding relationships and/or revenue streams over the past three years.",
      "Our organization maintains robust internal financial management statements and systems (i.e. clearly defined budget, income and cash flow statement and expense management).",
      "Our organization has not had a significant reduction in force over the past two years.",
      "Our organization has had a relatively stable senior management team [more than 75% of senior management has remained in leadership position] for the past two years.",
      "Our organization has experienced limited impact from turnover of more junior staff.",
      "Our organization has identified areas of major risk.",
      "Our organization has created contingency plans for our identified areas of organizational risk.",
    ],
    assessments: [
      {
        label: "You're ready",
        between: [30, 40],
      },
      {
        label: "Almost there",
        between: [22, 29],
      },
      {
        label: "Work to do",
        between: [0, 21],
      },
    ],
  },
  {
    title: "Strong Executive Leadership",
    items: [
      "Our senior management team conveys a strong social vision and inspires internal and external stakeholders to help realize that vision.",
      "Our senior management team helps our organization innovate to meet changing client needs.",
      "Our senior management team has deep, collective institutional knowledge of our organization.",
      "Our Board of Directors works closely with our senior management team to expand the impact of our organization.",
      "Our Executive Director / CEO has a strong relationship [including frequent communication, clearly defined roles and decision-making] with our Board of Directors.",
    ],
    assessments: [
      {
        label: "You're ready",
        between: [20, 25],
      },
      {
        label: "Almost there",
        between: [15, 19],
      },
      {
        label: "Work to do",
        between: [0, 14],
      },
    ],
  },
  {
    title: "Commitment to Capacity Building",
    items: [
      "Our Board of Directors has shown willingness to allocate financial resources towards capacity-building functions.",
      "Our senior management emphasizes and supports staff in allocating time to capacity-building work as part of their day-to-day responsibilities.",
      "Our organization has a clearly defined structure, including clear reporting relationships.",
      "Our organization has a clear decision-making process for different types of decisions (i.e. defined stakeholders and perspectives that we need to provide input for different types of decisions).",
      'Our organization has one individual who can make the "final call" on decisions (could be different individuals in different decision-making areas).',
    ],
    assessments: [
      {
        label: "You're ready",
        between: [20, 25],
      },
      {
        label: "Almost there",
        between: [15, 19],
      },
      {
        label: "Work to do",
        between: [0, 14],
      },
    ],
  },
  {
    title: "Effective Relationship Building",
    items: [
      "Relationship development is a key strategy for our organization.",
      "Our organization can name 2-3 relationships developed over the past three years that have increased the stability, presence, or resources of our organization.",
      "Our organization has asked for feedback from the organizations and individuals who partner with us and has incorporated that feedback into our approach to relationship building.",
      "Our organization has a proven ability to develop relationships with individuals and organizations from different cultures, sectors and industries.",
    ],
    assessments: [
      {
        label: "You're ready",
        between: [15, 20],
      },
      {
        label: "Almost there",
        between: [11, 14],
      },
      {
        label: "Work to do",
        between: [0, 10],
      },
    ],
  },
  {
    title: "Strategic Engagement of Volunteers",
    items: [
      "Our organization has an articulated strategy of using volunteer engagement for building capacity.",
      "Our organization prioritizes longer term relationship development with individuals that volunteer for us.",
      "Our organization has an understanding of staff roles and hours it takes to manage the volunteers we engage.",
      "Our organization has asked for feedback from our volunteers and incorporated it into our approach to volunteer engagement.",
    ],
    assessments: [
      {
        label: "You're ready",
        between: [15, 20],
      },
      {
        label: "Almost there",
        between: [11, 14],
      },
      {
        label: "Work to do",
        between: [0, 10],
      },
    ],
  },
];

import React from "react";
import styled from "styled-components-themed";
import { Link } from "@reach/router";
import { Anchor, media } from "shared-ui";
import { Box, Button, Paragraph } from "grommet";
import { MdArrowForward } from "react-icons/md";
import defaultImage from "frontend-app/src/images/img-prepare-check-your-readiness.svg";

const RightColumn = styled.div`
  background: #f7f9f8;
  border-radius: 0.375rem;
  height: 100%;
  padding: 1.5rem;

  ${media.large`
    width: calc(25% - 2rem);
  `}
`;

const StartAssessmentParagraph = styled(Paragraph)`
  max-width: none;
  text-align: center;
`;

const ButtonsContainer = styled.div`
  padding: 1rem 0;
  text-align: center;
  font-size: 1.125rem;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const HomeRightColumn: React.FunctionComponent = () => {
  return (
    <RightColumn>
      <ButtonsContainer>
        <Anchor as={Link} to="../step">
          <Button
            label="Start the Assessment"
            primary={true}
            color="#228DC1"
            icon={<MdArrowForward />}
            reverse={true}
            margin="xsmall"
          />
        </Anchor>
      </ButtonsContainer>
      <Box margin={{ bottom: "2rem" }}>
        <StartAssessmentParagraph size="small">
          Takes ~10 minutes to complete
        </StartAssessmentParagraph>
      </Box>
      <Image alt="Start the assessment image" src={defaultImage} />
    </RightColumn>
  );
};

export default HomeRightColumn;

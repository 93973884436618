import React from "react";
import { FieldArray, Field, FieldProps } from "formik";
import { Text, Box } from "grommet";
import {
  Header,
  CurrencyInput,
  AddFieldButton,
  TrashButton,
} from "../../components";
import { StyledLabel, StyledTextInput } from "../../styles/Inputs";
import { Wrapper, RowBox } from "../../styles/Wrappers";
import { calculateGroupAmount } from "@rr/utils/calculator/totals";
import fieldTypes from "../../data/fieldTypes";
import { implementationAndMaintenancePlaceholders } from "../../data/placeholders";

interface Props {
  values?: {
    [key: string]: string;
  }[];
}

const ImplementationAndMaintenance: React.FunctionComponent<Props> = React.memo(
  (props) => {
    const { values } = props;
    return (
      <Wrapper>
        <Header
          data-testid="im-value"
          heading="Implementation and Maintenance"
          size="medium"
          level={4}
          amount={calculateGroupAmount(values) || "$0.00"}
          tooltipContent="<em>The additional budget needed to implement and maintain the deliverable.</em>\n\nCommon examples of ancillary costs include website hosting fees, collateral printing, or new purchases required. These usually have tangible market value that you can identify through quick research."
        />
        <Text
          margin={{ vertical: "1rem" }}
          size="small"
          dangerouslySetInnerHTML={{
            __html:
              "Below are examples of implementation and maintenance costs.  Input your own implementation and maintenance costs based on the project you are exploring.  Input monetary value of additional budget needed to implement and maintain desired solution.",
          }}
        />
        <FieldArray name="implementationAndMaintenance">
          {(arrayHelpers) => (
            <React.Fragment>
              {values &&
                values.length > 0 &&
                values.map((value, index) => (
                  <div key={index}>
                    <RowBox>
                      <Box width="80%">
                        <StyledLabel
                          display={index !== 0 ? "none" : "flex"}
                          htmlFor="description"
                        >
                          {"Description"}
                        </StyledLabel>
                      </Box>
                      <Box width="20%">
                        <StyledLabel
                          display={index !== 0 ? "none" : "flex"}
                          htmlFor="totalValue"
                        >
                          {"Total Value"}
                        </StyledLabel>
                      </Box>
                    </RowBox>
                    <RowBox>
                      <TrashButton onClick={() => arrayHelpers.remove(index)} />
                      <Box width="80%" margin={{ right: ".35rem" }}>
                        <Field
                          name={`implementationAndMaintenance.${index}.description`}
                          render={({ field }: FieldProps) => (
                            <StyledTextInput
                              {...field}
                              placeholder={
                                implementationAndMaintenancePlaceholders[index]
                              }
                              id="description"
                              size="small"
                              type="text"
                            />
                          )}
                        />
                      </Box>
                      <Box width="20%">
                        <Field
                          name={`implementationAndMaintenance.${index}.totalValue`}
                          render={({ field }: FieldProps) => (
                            <CurrencyInput
                              {...field}
                              id="totalValue"
                              placeholder="0.00"
                              onStateChange={(currency) => {
                                arrayHelpers.replace(index, {
                                  ...value,
                                  totalValue: currency,
                                });
                              }}
                            />
                          )}
                        />
                      </Box>
                    </RowBox>
                  </div>
                ))}
              {values && (
                <AddFieldButton
                  onClick={() =>
                    arrayHelpers.push(
                      fieldTypes["implementationAndMaintenance"],
                    )
                  }
                  text="Add an implementation / maintenance cost"
                />
              )}
            </React.Fragment>
          )}
        </FieldArray>
      </Wrapper>
    );
  },
);

ImplementationAndMaintenance.displayName = "ImplementationAndMaintenance";

export default ImplementationAndMaintenance;

import React from "react";
import styled from "styled-components-themed";
import * as copy from "copy-to-clipboard";
import { Box, DropButton, ButtonProps } from "grommet";
import { IoMdLink } from "react-icons/io";

interface Props extends ButtonProps {
  prefix: string;
  getShareId: () => Promise<string | undefined>;
}

const StyledDropButton = styled(DropButton)`
  font-size: 0.875rem;
  font-weight: bold;
  color: #238dc1;
`;

const P = styled.p`
  font-size: 0.75rem;
  line-height: 1.5;
  color: #244b5a;
`;

const DropContent: React.FunctionComponent<{}> = ({ children }) => (
  <Box pad="small">
    <P>
      <strong>Copied to clipboard</strong>
    </P>
    <P>{children}</P>
  </Box>
);

const ShareButton: React.FunctionComponent<Props> = (props) => {
  const { prefix, getShareId, ...restProps } = props;
  const [shareCopied, setShareCopied] = React.useState<string | undefined>(
    undefined,
  );

  return (
    <StyledDropButton
      open={!!shareCopied}
      onClose={() => setShareCopied(undefined)}
      dropContent={<DropContent>{shareCopied}</DropContent>}
      dropAlign={{ bottom: "top", right: "right" }}
      plain={true}
      onClick={async () => {
        const shareId = await getShareId();
        const shareLink = `https://app.capacitycommons.org/share/${prefix}/${shareId}`;
        const didCopy = copy(shareLink);
        setShareCopied(didCopy ? shareLink : undefined);
      }}
      gap="xsmall"
      icon={<IoMdLink size="16px" color="#238DC1" />}
      label="LINK"
      {...restProps}
    />
  );
};

export default ShareButton;

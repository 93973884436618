import ApiClient from "./";
import { Calculator } from "calculator/typings";

const calculatorPath = "/api/calculator";

export function getCalculator(id: string) {
  return ApiClient.get(`${calculatorPath}/${id}`);
}

export function createCalculator(payload: Calculator) {
  return ApiClient.post(calculatorPath, payload);
}

export function updateCalculator(id: string, payload: Calculator) {
  return ApiClient.put(`${calculatorPath}/${id}`, payload);
}

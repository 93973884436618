import * as React from "react";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import styled, { css } from "styled-components-themed";
import startOfDay from "date-fns/start_of_day";
import format from "date-fns/format";
import { Box, Button, Calendar, DropButton, Text } from "grommet";
import { FaRegCalendar, FaPencilAlt } from "react-icons/fa";
import { MdArrowForward } from "react-icons/md";
import { RootState } from "frontend-app/typings";
import { RadioGroup, Radio } from "shared-ui";
import { updateProject } from "../../../reducer";
import { Header, Heading, StyledEditButton, Label } from "../styles";

const StyledOption = styled.div`
  border: 1px solid #b6ccc2;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  padding: 1rem;
  font-size: 1rem;
`;

const DateRangeWrapper = styled.div<{ enabled: boolean }>`
  ${(props) => css`
    display: flex;
    margin-top: 1rem;
    align-items: center;
    opacity: ${props.enabled ? 1 : 0.5};
    pointer-events: ${props.enabled ? "auto" : "none"};
  `}
`;

const PickerWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #b6ccc2;
  border-radius: 0.25rem;

  > button {
    width: 100%;
  }
`;

interface Props {
  initialEnable?: boolean;
  initialStartDate?: string;
  initialEndDate?: string;
  project: RootState["projects"]["project"];
  updateProjectAction: typeof updateProject;
}

const ProjectDuration: React.FunctionComponent<Props> = (props) => {
  const {
    initialEnable,
    initialStartDate,
    initialEndDate,
    project,
    updateProjectAction,
  } = props;
  const [showEditMode, setShowEditMode] = React.useState(
    initialEnable === undefined,
  );
  const [enableProjectDuration, setEnableProjectDuration] = React.useState(
    initialEnable,
  );
  const [startDate, setStartDate] = React.useState(initialStartDate as
    | string
    | undefined);
  const [endDate, setEndDate] = React.useState(initialEndDate as
    | string
    | undefined);
  const [openStartDate, setOpenStartDate] = React.useState(false);
  const [openEndDate, setOpenEndDate] = React.useState(false);

  return (
    <Box margin={{ vertical: "small" }}>
      <Header>
        <Heading level={3}>Project Duration</Heading>
        {!showEditMode && (
          <StyledEditButton
            color=" #238DC1"
            plain={true}
            icon={<FaPencilAlt size="14px" color=" #238DC1" />}
            gap="xxsmall"
            label="Edit"
            onClick={() => {
              setShowEditMode(true);
            }}
          />
        )}
      </Header>

      {showEditMode ? (
        <React.Fragment>
          <Box margin={{ vertical: "small" }}>
            <Label>
              Do you want to include a duration for this project? Don{"'"}t
              worry about getting the dates perfect, you can always change them
              later.
            </Label>
          </Box>

          <RadioGroup
            name="duration"
            value={enableProjectDuration ? "yes" : "no"}
            onChange={(_event, value) => {
              setEnableProjectDuration(value === "yes" ? true : false);
            }}
          >
            <StyledOption>
              <Radio
                value="no"
                label="No, I do not want to include an estimated project duration."
                style={{ fontSize: "1rem" }}
              />
            </StyledOption>
            <StyledOption>
              <Radio
                value="yes"
                label="Yes, include an estimated project duration."
                style={{ fontSize: "1rem" }}
              />

              <DateRangeWrapper enabled={!!enableProjectDuration}>
                <PickerWrapper>
                  <DropButton
                    open={openStartDate}
                    onClose={() => setOpenStartDate(false)}
                    onOpen={() => setOpenStartDate(true)}
                    dropContent={
                      <Calendar
                        date={startDate as any}
                        onSelect={(date: any) => {
                          setStartDate(startOfDay(date).toISOString());
                          setOpenStartDate(false);
                        }}
                      />
                    }
                  >
                    <Box direction="row" align="center" justify="between">
                      <Text>
                        {startDate
                          ? new Date(startDate).toLocaleDateString()
                          : "Start Date"}
                      </Text>
                      <FaRegCalendar size="20px" />
                    </Box>
                  </DropButton>
                </PickerWrapper>

                <MdArrowForward style={{ margin: "0 1.5rem" }} />

                <PickerWrapper>
                  <DropButton
                    open={openEndDate}
                    onClose={() => setOpenEndDate(false)}
                    onOpen={() => setOpenEndDate(true)}
                    dropContent={
                      <Calendar
                        date={endDate as any}
                        onSelect={(date: any) => {
                          const start = startDate
                            ? new Date(startDate)
                            : new Date();
                          const end = new Date(date);
                          if (start <= end) {
                            setEndDate(startOfDay(date).toISOString());
                            setOpenEndDate(false);
                          }
                        }}
                      />
                    }
                  >
                    <Box
                      direction="row"
                      align="center"
                      justify="between"
                      width="full"
                    >
                      <Text>
                        {endDate
                          ? new Date(endDate).toLocaleDateString()
                          : "End Date"}
                      </Text>
                      <FaRegCalendar size="20px" />
                    </Box>
                  </DropButton>
                </PickerWrapper>
              </DateRangeWrapper>
            </StyledOption>
          </RadioGroup>

          <Box align="end" margin={{ vertical: "medium" }}>
            <Button
              type="submit"
              primary={true}
              color="#228DC1"
              label="Done"
              onClick={async () => {
                await updateProjectAction(project!._id!, {
                  projectDuration: {
                    enabled: !!enableProjectDuration,
                    startDate,
                    endDate,
                  },
                });
                setShowEditMode(false);
              }}
            />
          </Box>
        </React.Fragment>
      ) : (
        <Text margin={{ vertical: "medium" }}>
          {enableProjectDuration && startDate && endDate
            ? `${format(startDate, "MMMM DD, YYYY")} to ${format(
                endDate,
                "MMMM DD, YYYY",
              )}`
            : "No estimated project duration."}
        </Text>
      )}
    </Box>
  );
};

const mapStateToProps = (state: RootState) => {
  const project = state.projects.project;
  const hasProjectDuration = project && project.projectDuration;
  return {
    project,
    initialEnable: hasProjectDuration
      ? project!.projectDuration!.enabled
      : undefined,
    initialStartDate: hasProjectDuration
      ? project!.projectDuration!.startDate
      : undefined,
    initialEndDate: hasProjectDuration
      ? project!.projectDuration!.endDate
      : undefined,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ updateProjectAction: updateProject }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectDuration);

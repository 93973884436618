import React from "react";
import { AuthService } from "authentication";
const { EventSourcePolyfill } = require("event-source-polyfill");
const API_URL = process.env.API_URL || "http://localhost:3000";

export const useEventSource = ({
  endpoint,
  eventName,
}: {
  endpoint: string;
  eventName: string;
}) => {
  const [data, setData] = React.useState();

  React.useEffect(() => {
    const eventSource = new EventSourcePolyfill(`${API_URL}${endpoint}`, {
      headers: {
        Authorization: `Bearer ${AuthService.getAccessToken()}`,
      },
    });

    eventSource.addEventListener("open", () => {
      console.log("Connection was opened!");
    });

    eventSource.addEventListener("error", (error: any) => {
      console.log("An error has occurred while receiving stream", error);
    });

    const listener = (event: any) => {
      setData(JSON.parse(event.data));
    };

    eventSource.addEventListener(eventName, listener);

    return () => {
      eventSource.close();
      eventSource.removeEventListener(eventName, listener);
    };
  }, [endpoint, eventName]);

  return data;
};

import React from "react";
import styled from "styled-components-themed";
import { Box, Heading } from "grommet";
import { Tooltip } from "shared-ui";
import { displayUSD } from "@rr/utils";

interface Props {
  heading: string;
  level: 1 | 2 | 3 | 4 | 5 | 6;
  size: string;
  tooltipContent?: string;
  amount?: number | string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px solid #bacbc3;
`;

const Header: React.FunctionComponent<Props> = (props) => {
  const { heading, level, size, tooltipContent, amount, ...restProps } = props;
  return (
    <Wrapper {...restProps}>
      <Box direction="row">
        <Heading
          level={level}
          size={size}
          color="#2d4a58"
          margin={{ right: "xsmall" }}
        >
          {heading}
        </Heading>
        {tooltipContent && (
          <Tooltip style={{ margin: "4.5rem" }} italicized="true" size="20px">
            {tooltipContent}
          </Tooltip>
        )}
      </Box>
      <Heading level={level} size={size}>
        {amount && displayUSD(amount)}
      </Heading>
    </Wrapper>
  );
};

export default Header;

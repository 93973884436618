import { Assessment } from "assessment/typings";
import ApiClient from "./";

const assessmentsPath = "/api/assessments";

export function getAssessments(parentId?: string) {
  const url = parentId
    ? `${assessmentsPath}?parentId=${parentId}`
    : assessmentsPath;

  return ApiClient.get(url);
}

export function getAssessment(id: string) {
  return ApiClient.get(`${assessmentsPath}/${id}`);
}

export function createAssessment(payload: Assessment) {
  return ApiClient.post(assessmentsPath, payload);
}

export function deleteAssessment(id: string) {
  return ApiClient.delete(`${assessmentsPath}/${id}`);
}

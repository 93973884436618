export const longTermBusinessValuePlaceholders: Array<string> = [
  "e.g. Increased fundraising returns",
  "e.g. Savings from discontinued support with outside vendor",
  "e.g. Monetary value of staff time saved due to increased operational efficiency",
];

export const longTermBusinessValueUniqueToProBonoPlaceholders: Array<string> = [
  "e.g. New or strengthened relationships with pro bono volunteers / companies",
  "e.g. Increased functional / project management expertise for staff",
  "e.g. Leadership development opportunity for staff or Executive Director",
];

export const opportunityCostOfImplementationPlaceholders: Array<string> = [
  "e.g. Less staff time spent on another functional area for the duration of the project",
  "e.g. Allocating fewer resources for other projects",
];

export const implementationAndMaintenancePlaceholders: Array<string> = [
  "e.g. Annual cost for new vendor relationship",
  "e.g. Increased operational costs",
  "e.g. Annual cost of additional personnel to drive the project forward",
  "e.g. Cost of hours spent training staff on new solution/product",
];

export const unquantifiableValuePlaceholders: Array<string> = [
  "e.g. Improved organizational culture and morale after a project is successfully completed and the results are celebrated",
  "e.g. Understanding of how to work across sectors to create sustainable community change",
];

import { Omit } from "typings";
import { AssessmentType, QuestionData, Answer, Scores } from "../../../typings";

export interface State {
  step: number;
  type: AssessmentType | "";
  parentId: string;
  totalScore: number;
  maxScore: number;
  scores: Scores;
  questionsData: QuestionData[];
  answers: Answer[];
  assessmentLoaded: boolean;
  isLoading: boolean;
}

export const initialState: State = {
  step: 0,
  type: "",
  parentId: "",
  totalScore: 0,
  maxScore: 0,
  scores: {},
  questionsData: [],
  answers: [],
  assessmentLoaded: false,
  isLoading: true,
};

export type Action =
  | { type: "SET_STEP"; step: number }
  | { type: "SET_SCORE" }
  | {
      type: "SET_DATA";
      questionsData: QuestionData[];
      answers: Answer[];
      maxScore: number;
    }
  | {
      type: "SET_ANSWER";
      payload: Omit<Answer, "group">;
    }
  | {
      type: "ASSESSMENT_LOADING";
      payload: boolean;
    }
  | {
      type: "LOAD_ASSESSMENT";
      payload: Omit<State, "step">;
    };

function assessmentReducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_STEP":
      return { ...state, step: action.step };
    case "SET_SCORE":
      const scores: Scores = state.answers.reduce((acc: Scores, q) => {
        const score = acc[q.group] || 0;
        const answer = q.answer || 0;
        acc[q.group] = score + answer;
        return acc;
      }, {});
      const totalScore = Object.values(scores).reduce(
        (acc, score) => acc + score,
        0,
      );

      return {
        ...state,
        scores,
        totalScore,
      };
    case "SET_DATA":
      return {
        ...state,
        maxScore: action.maxScore,
        questionsData: action.questionsData,
        answers: action.answers,
      };
    case "SET_ANSWER":
      const answers = state.answers.map((answer) => {
        if (answer.number === action.payload.number) {
          return { ...answer, ...action.payload };
        }
        return answer;
      });
      return {
        ...state,
        answers,
      };
    case "ASSESSMENT_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "LOAD_ASSESSMENT":
      return {
        ...state,
        ...action.payload,
        assessmentLoaded: true,
      };
    default:
      throw new Error();
  }
}

export default assessmentReducer;

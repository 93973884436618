import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import { Match } from "@reach/router";
import styled from "styled-components-themed";
import { Box, Heading, Text } from "grommet";
import { FaShareSquare } from "react-icons/fa";
import Step from "./Step";

import { RootState } from "frontend-app/typings";
import { Calculator } from "calculator/typings";
import { unloadCalculator } from "calculator/src/reducers";
import { displayUSD } from "@rr/utils";
import { calculateGroupTotals } from "@rr/utils/calculator/totals";
import {
  organizationalValue,
  organizationalInvestment,
} from "@rr/utils/calculator/groups";
import { loadOrganization } from "organization/src/reducer";
import { Anchor, NotFound, ProgressBar, media } from "shared-ui";
import { loadProject, unloadProject } from "../../reducer";
import { Assessment } from "assessment/typings";
import { getAssessmentScore } from "assessment/src/pages/Assessment/utils";
import { assessments as projectAssessments } from "assessment/src/data/project";

import { selectProjectsProgressValue } from "@rr/utils/projects/getProgressValue";

const Wrapper = styled.div`
  /* display: grid;
  grid-gap: 0;
  grid-template-columns: minmax(300px, 350px) 1fr minmax(300px, 350px);
  grid-template-areas: "sidebar content content";
  grid-gap: 2rem; */
  margin-top: 2rem;

  ${media.large`
    display: flex;
  `}
`;

const Sidebar = styled.aside<{ hideSidebar: boolean }>`
  min-width: 21.875rem;
  flex-basis: 21.875rem;
  /* grid-area: sidebar;*/
  display: ${(props) => (props.hideSidebar ? "none" : "block")};

  ${media.large`
    margin-right: 4rem;
  `}

  > div {
    background: #f7f9f8;
    margin-bottom: 2rem;
  }
`;

const Main = styled.main`
  width: 100%;
  /* grid-area: content; */
`;

interface Props {
  projectId?: string;
  loadProjectAction: typeof loadProject;
  unloadProjectAction: typeof unloadProject;
  unloadCalculatorAction: typeof unloadCalculator;
  projects: RootState["projects"];
  values: Calculator;
  calculatorLoaded: boolean;
  assessment?: Assessment;
  projectsProgressValue: number;
  organization: RootState["organizations"]["organization"];
  loadOrganizationAction: typeof loadOrganization;
}

const Layout: React.FunctionComponent<Props> = (props) => {
  const {
    children,
    projectId,
    loadProjectAction,
    unloadProjectAction,
    unloadCalculatorAction,
    projects,
    values,
    calculatorLoaded,
    assessment,
    projectsProgressValue,
    organization,
    loadOrganizationAction,
  } = props;

  React.useEffect(() => {
    (async () => {
      try {
        if (!organization) {
          await loadOrganizationAction();
        }
        if (projectId) {
          await loadProjectAction(projectId);
        }
      } catch (error) {
        console.log("Error: ", error);
      }
    })();

    return () => {
      unloadProjectAction();
      unloadCalculatorAction();
    };
  }, [projectId]);

  if (!projects.isLoading && !projects.project) {
    return <NotFound>Project Not Found</NotFound>;
  }

  return (
    <Match path="assessment/step/*">
      {(props) => (
        <Wrapper>
          <Sidebar hideSidebar={!!props.match}>
            <div>
              <Box
                pad={{ horizontal: "medium", vertical: "small" }}
                border={{ side: "bottom", color: "#B7CCC2" }}
                direction="row"
                justify="between"
                align="center"
              >
                <Heading level={3}>To Do</Heading>
              </Box>
              <Step
                path="scope"
                title="Scope Your Project"
                order={1}
                render={() => (
                  <ProgressBar
                    label="Completed"
                    barColor="#15A576"
                    value={projectsProgressValue}
                    total={100}
                  />
                )}
              />
              <Step
                path="calculator"
                title="Estimate Its Net Value"
                subtitle="This is the net cost of using skills-based volunteers for this project, including assessing organizational value and investment."
                order={2}
                render={() => (
                  <Box direction="row" justify="end">
                    <Heading level="3">
                      {calculatorLoaded
                        ? displayUSD(
                            calculateGroupTotals(organizationalValue, values) -
                              calculateGroupTotals(
                                organizationalInvestment,
                                values,
                              ),
                          )
                        : "$ 0.00"}
                    </Heading>
                  </Box>
                )}
              />
              <Step
                path="assessment"
                title={
                  assessment ? "Project Readiness" : "Evaluate Its Readiness"
                }
                subtitle="The Project Readiness Assessment ensures your project meets all of the requirements necessary for a successful, impactful skills-based volunteer project!"
                order={3}
                render={() => (
                  <Box direction="column" alignSelf="end">
                    {assessment ? (
                      <React.Fragment>
                        <Heading level="4" textAlign="end">
                          {
                            getAssessmentScore(
                              projectAssessments,
                              assessment.totalScore,
                            ).label
                          }
                        </Heading>
                        <Heading
                          level="6"
                          textAlign="end"
                          margin={{ bottom: "small" }}
                        >
                          {assessment.totalScore} out of {assessment.maxScore}
                        </Heading>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Heading level="4" textAlign="end">
                          ?
                        </Heading>
                        <Heading
                          level="6"
                          textAlign="end"
                          margin={{ bottom: "small" }}
                        >
                          Score
                        </Heading>
                      </React.Fragment>
                    )}
                  </Box>
                )}
              />
              <Step
                path="volunteers"
                title="Find Skills-Based Volunteers"
                subtitle="Explore the various resourced available for finding skilled volunteers for your project."
                order={4}
              />
            </div>
            <Box pad={{ horizontal: "medium", vertical: "small" }}>
              <Heading level="4" margin={{ top: "1rem", bottom: "1rem" }}>
                For Inspiration
              </Heading>
              <Text as="p" size="1rem" margin={{ bottom: "0.5rem" }}>
                <FaShareSquare
                  style={{ marginRight: "0.5rem" }}
                  color="#3a7399"
                />
                <strong>
                  <Anchor
                    target="_blank"
                    href="https://capacitycommons.org/guidebook/scope/digital-communications-plan/"
                  >
                    Preview a Sample Project
                  </Anchor>
                </strong>
              </Text>
              <Text as="p" size="1rem" margin={{ bottom: "1rem" }}>
                <FaShareSquare
                  style={{ marginRight: "0.5rem" }}
                  color="#3a7399"
                />
                <strong>
                  <Anchor
                    target="_blank"
                    href="https://capacitycommons.org/guidebook/scope/view-sample-projects/"
                  >
                    Explore Different Project Types
                  </Anchor>
                </strong>
              </Text>
            </Box>
          </Sidebar>
          <Main>{children}</Main>
        </Wrapper>
      )}
    </Match>
  );
};

const mapStateToProps = (state: RootState) => {
  const { calculator, calculatorLoaded } = state.calculator;
  const { projects } = state;
  return {
    values: {
      monetaryValueOfConsultants: calculator.monetaryValueOfConsultants,
      monetaryValueOfProducts: calculator.monetaryValueOfProducts,
      longTermBusinessValue: calculator.longTermBusinessValue,
      longTermBusinessValueUniqueToProBono:
        calculator.longTermBusinessValueUniqueToProBono,
      staffTime: calculator.staffTime,
      opportunityCostOfImplementation:
        calculator.opportunityCostOfImplementation,
      implementationAndMaintenance: calculator.implementationAndMaintenance,
      unquantifiableValue: calculator.unquantifiableValue,
    },
    calculatorLoaded,
    projects,
    assessment: projects.project && projects.project.assessment,
    projectsProgressValue: selectProjectsProgressValue(state),
    organization: state.organizations.organization,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      loadProjectAction: loadProject,
      unloadProjectAction: unloadProject,
      unloadCalculatorAction: unloadCalculator,
      loadOrganizationAction: loadOrganization,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Layout);

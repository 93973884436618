import React from "react";
import styled from "styled-components-themed";
import { Field, getIn, FieldProps } from "formik";

const Message = styled.p`
  color: ${(props) => props.theme.colors.red};
  font-size: 0.875rem;
  margin-top: 0.5rem;
`;

const ErrorMessage = ({ name }: { name: string }) => (
  <Field
    name={name}
    render={({ form }: FieldProps) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return touch && error ? <Message>{error}</Message> : null;
    }}
  />
);

export default ErrorMessage;

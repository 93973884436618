import { toCents, toDecimalString } from "../index";
import { Calculator } from "calculator/typings";

export const calculateTotalValues = (groupType: string, value: any) => {
  let totalValue;
  switch (groupType) {
    case "monetaryValueOfConsultants":
      totalValue = toCents(value.hourlyRate) * parseFloat(value.totalHours);
      break;
    case "staffTime":
      const { hoursPerWeek, numberOfWeeks, hourlyCost } = value;
      totalValue =
        parseFloat(hoursPerWeek) *
        parseInt(numberOfWeeks) *
        toCents(hourlyCost);
      break;
    default:
      totalValue = toCents(value.totalValue);
  }
  return toDecimalString(totalValue);
};

const totalValuesReducer = (acc: number, curr: { totalValue: string }) =>
  acc + toCents(curr.totalValue);

const totalsReducer = (acc: number, curr: number) => acc + curr;

export const calculateGroupTotals = (
  groups: Array<keyof Calculator> = [],
  values: Calculator,
) =>
  groups
    .map((group) => (values[group] as any[]).reduce(totalValuesReducer, 0))
    .reduce(totalsReducer, 0);

export const calculateGroupAmount = (items?: any[]): number => {
  return items && items.reduce(totalValuesReducer, 0);
};

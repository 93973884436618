import React from "react";
import styled from "styled-components-themed";
import { Button as ButtonBase } from "grommet";
import { FaRegTrashAlt } from "react-icons/fa";

interface Props {
  onClick: () => void;
}
export const Button = styled(ButtonBase)`
  position: absolute;
  left: -2rem;
  top: 50%;
  transform: translateY(-50%);
`;

const TrashButton: React.FunctionComponent<Props> = (props) => {
  const { onClick } = props;
  return (
    <Button
      icon={<FaRegTrashAlt color="#448cbc" size="14px" />}
      onClick={onClick}
    />
  );
};

export default TrashButton;

import { between } from "@rr/utils";
import { AssessmentScore, Answer, QuestionData } from "../../../typings";

export const getAnswersByGroup = (data: Answer[], group: number) =>
  data.filter((item) => item.group === group);

export const getGroupByStep = (data: QuestionData[], step: number) =>
  data[step - 1] || {};

export const checkAllQuestionsAnswered = (data: Answer[], group: number) => {
  let questions: Answer[] = [];
  for (let index = 1; index <= group; index++) {
    questions = [...questions, ...getAnswersByGroup(data, index)];
  }
  return questions.every((question) => question.answer !== undefined);
};

export interface Pagination {
  first: number;
  last: number;
  step: number;
}

export const generatePagination = (data: QuestionData[]) =>
  data.reduce(
    (acc, group, index) => {
      const prev = acc[acc.length - 1];
      const prevLastNum = prev ? prev.last : 0;
      const item = {
        first: prevLastNum + 1,
        last: prevLastNum + group.items.length,
        step: index + 1,
      };
      return [...acc, item];
    },
    [] as Pagination[],
  );

// Loop over groups
// Return Flatten results of group.items.
// Add `number` which is the question count.
// Add `group` which is the index of the group it belongs to.
export const composeAnswers = (data: QuestionData[]) =>
  data.reduce(
    (acc, group, index) => {
      const lastItem = acc[acc.length - 1];
      const lastNum = lastItem ? lastItem.number : 0;
      const items = group.items.map((_item, itemIndex) => ({
        number: lastNum + itemIndex + 1,
        group: index + 1,
      }));
      return [...acc, ...items];
    },
    [] as Answer[],
  );

export const getAssessmentScore = (
  assessments: AssessmentScore[],
  score: number,
) =>
  assessments.find((assessment) => {
    const [min, max] = assessment.between;
    return between(score, min, max);
  }) || { label: "Opps! Something went wrong.", between: undefined };

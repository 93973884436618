import * as React from "react";
import styled from "styled-components-themed";
import { Box } from "grommet";
import { TextArea } from "shared-ui";
import { QA } from "../../../../typings";

export interface QuestionItem extends QA {
  onChange?: (event: React.FormEvent<HTMLTextAreaElement>, value?: any) => void;
  onBlur?: (event: React.FormEvent<HTMLTextAreaElement>, value?: any) => void;
  form?: any;
  name?: string;
}

export interface Props extends QuestionItem {
  index: number;
  prefix?: string;
}

const StyledLabel = styled.label`
  font-style: italic;
  white-space: pre-line;
`;

const Question: React.FunctionComponent<Props> = (props) => {
  const {
    question,
    textarea,
    answer,
    prefix = "question",
    index,
    onChange,
    onBlur,
    name,
    form,
    ...restProps
  } = props;
  const hasLeadingText = textarea && textarea.leadingText;

  return (
    <Box key={question} margin={{ vertical: "small" }}>
      <Box margin={{ vertical: "small" }}>
        <StyledLabel
          htmlFor={`${prefix}-${index}`}
          dangerouslySetInnerHTML={{ __html: question }}
        />
      </Box>
      <TextArea
        {...restProps}
        name={name}
        id={`${prefix}-${index}`}
        resize={false}
        initialValue={answer}
        leadingText={hasLeadingText ? textarea!.leadingText : undefined}
        ellipsis={hasLeadingText ? "..." : undefined}
        onChange={(_event, { actualValue }) => {
          if (form) {
            form.setFieldValue(name, actualValue);
          }
        }}
        onBlur={(event) => {
          if (onBlur) {
            onBlur(event);
          }
        }}
      />
    </Box>
  );
};

export default Question;

import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const middleware = [thunk];

const initStore = (initialState = {}) => {
  const windowGlobal: any = typeof window !== "undefined" && window;

  const devtools =
    process.env.NODE_ENV !== "production" && windowGlobal.devToolsExtension
      ? windowGlobal.__REDUX_DEVTOOLS_EXTENSION__ &&
        windowGlobal.__REDUX_DEVTOOLS_EXTENSION__()
      : (f: any) => f;

  const store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(...middleware),
      devtools,
    ),
  );

  return store;
};

export default initStore;

import { RootState } from "frontend-app/typings";

const selectPropertyFrom: Function = (property: string, state: {}) =>
  state && state[property as keyof typeof state];

const selectProject: Function = (state: RootState, id?: string) => {
  if (id) {
    return state.projects.projects.find((proj) => id === proj._id);
  }
  return state.projects && state.projects.project;
};

const selectProjectNeed = (state: RootState, id?: string) => {
  const data = selectPropertyFrom("projectNeed", selectProject(state, id));
  if (data && data.length && data.length > 0) {
    return 20;
  }
  return 0;
};

const selectProjectDescription = (state: RootState, id?: string) => {
  const data = selectPropertyFrom(
    "projectDescription",
    selectProject(state, id),
  );
  if (data && data.length && data.length > 0) {
    return 20;
  }
  return 0;
};

const selectProjectPhases = (state: RootState, id?: string) => {
  const data = selectPropertyFrom("projectPhases", selectProject(state, id));
  if (data && data.length && data.length > 0) {
    return 20;
  }
  return 0;
};

const selectSkillsNeeded = (state: RootState, id?: string) => {
  const data = selectPropertyFrom("skillsNeeded", selectProject(state, id));
  if (data && Object.keys(data).length && Object.keys(data).length > 0) {
    return Object.keys(data).every((datum) => {
      if (typeof data[datum] === "string") {
        return true;
      }
      if (typeof data[datum] === "object") {
        if (Object.keys(data[datum]).length) {
          return Object.keys(data[datum]).every(
            (item) => data[datum][item].length > 0,
          );
        }
        if (datum === "selectedSoftSkills") {
          return true;
        }
        return data[datum].length > 0;
      }
      return data[datum].length > 0;
    })
      ? 20
      : 0;
  }
  return 0;
};

const selectProjectDuration = (state: RootState, id?: string) => {
  const data = selectPropertyFrom("projectDuration", selectProject(state, id));
  if (data && typeof data.enabled === "boolean") {
    return 20;
  }
  return 0;
};

export const selectProjectsProgressValue = (state: RootState, id?: string) => {
  const projectNeed = selectProjectNeed(state, id);
  const projectDescription = selectProjectDescription(state, id);
  const projectPhases = selectProjectPhases(state, id);
  const skillsNeeded = selectSkillsNeeded(state, id);
  const projectDuration = selectProjectDuration(state, id);
  return (
    projectNeed +
    projectDescription +
    projectPhases +
    skillsNeeded +
    projectDuration
  );
};

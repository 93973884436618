import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "@reach/router";
import { AuthService } from "authentication";
import { RootState } from "frontend-app/typings";
import { Spinning } from "../Spinning";

interface Props extends RouteComponentProps {
  component: React.FunctionComponent<any> | React.ComponentClass<any>;
  session: RootState["session"];
}

const ProtectedRoute: React.FunctionComponent<Props> = ({
  component: Component,
  session,
  ...rest
}) => {
  React.useEffect(() => {
    if (!session.isLoggedIn) {
      AuthService.login();
    }
  }, []);

  if (session.isLoggedIn) {
    return <Component {...rest} />;
  }
  return <Spinning />;
};

const mapStateToProps = (state: RootState) => ({
  session: state.session,
});

export default connect(mapStateToProps)(ProtectedRoute);

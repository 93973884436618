import * as React from "react";
import styled from "styled-components-themed";
import { Box, Text } from "grommet";
import { media } from "../../styles/utils/breakpoint";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  subtitle?: string;
  icon?: Function;
  action?: Function;
  render?: Function;
}

const Wrapper = styled(Box)`
  width: 100%;
  padding-left: 0;
  margin-top: 2rem;

  ${media.large`
    margin-top: 0;
    padding-left: 1.5rem;
    flex-basis: 450px;
  `}
`;

const StepCard: React.FunctionComponent<Props> = ({
  title,
  subtitle,
  icon,
  action,
  render,
  ...restProps
}) => (
  <Wrapper pad="medium" {...restProps}>
    <Box direction="row" margin={{ bottom: "1rm" }} justify="between">
      <Box direction="row" align="center">
        {icon && icon()}
        <Box direction="column">
          <Text weight="bold" data-testid="StepCard-title">
            {title}
          </Text>
          <Text size="xsmall">{subtitle}</Text>
        </Box>
      </Box>
      {action && action()}
    </Box>
    <Box pad={{ top: "small" }}>{render && render()}</Box>
  </Wrapper>
);

export default StepCard;

import { AssessmentScore, QuestionData } from "../../../typings";

export const assessments: AssessmentScore[] = [
  {
    label: "You're ready",
    between: [75, 95],
  },
  {
    label: "Almost there",
    between: [55, 74],
  },
  {
    label: "Work to do",
    between: [0, 54],
  },
];

export const questionsData: QuestionData[] = [
  {
    title: "Defined Project Goals",
    items: [
      "Our organization has a clear understanding of how this project will feed into our long-term strategic goals.",
      "Our organization can clearly articulate why it is important to solve the problem that this project addresses.",
      "Our organization can provide a high-level overview of what success looks like at the end of the project.",
      "Our goals for this project are SMART (specific, measurable, attainable, relevant and time bound).",
    ],
    assessments: [
      {
        label: "You're ready",
        between: [15, 20],
      },
      {
        label: "Almost there",
        between: [11, 14],
      },
      {
        label: "Work to do",
        between: [0, 10],
      },
    ],
  },
  {
    title: "Defined Project Scope",
    items: [
      "Our organization has a clear understanding of what type of deliverable will be the most useful and actionable (i.e. application, white paper, set of recommendations).",
      "Our organization has a clear sense of the skills and experience volunteer(s) will need to have to successfully complete the project.",
      "Our organization has clear and realistic expectations of the time commitment and capacity volunteer(s) will need to contribute to the project.",
      "Our project is cohesive and addresses a core issue area within our organization.",
      "Our organization can distinguish between the need-to-have and nice-to-have elements of the project.",
    ],
    assessments: [
      {
        label: "You're ready",
        between: [20, 25],
      },
      {
        label: "Almost there",
        between: [15, 19],
      },
      {
        label: "Work to do",
        between: [0, 14],
      },
    ],
  },
  {
    title: "Project Prioritization",
    items: [
      "This project has been identified as a top priority for our organization and we can dedicate the necessary time and effort to ensure its success.",
      "This project will make a significant impact on the population we are serving along with the broader community.",
      "This project is integral to our day-to-day work and will increase staff capacity to execute.",
      "Our organization demonstrates that there is a sufficient level of buy-in on this project from our executive management and board of directors.",
    ],
    assessments: [
      {
        label: "You're ready",
        between: [15, 20],
      },
      {
        label: "Almost there",
        between: [11, 14],
      },
      {
        label: "Work to do",
        between: [0, 10],
      },
    ],
  },
  {
    title: "Project Leadership",
    items: [
      "Our organization has identified project lead(s) to steward the project along internally and to act as point person for the volunteer team.",
      "Our project lead(s) has the determined capacity needed to manage the project.",
      "Our project lead(s) has deep knowledge of the challenges faced in the area the skills-based volunteer team is addressing.",
      "Our project lead(s) has the ability to make most project decisions on their own and is able to quickly facilitate internal decision making on key strategic issues.",
    ],
    assessments: [
      {
        label: "You're ready",
        between: [15, 20],
      },
      {
        label: "Almost there",
        between: [11, 14],
      },
      {
        label: "Work to do",
        between: [0, 10],
      },
    ],
  },
  {
    title: "Post-Project Support Plan",
    items: [
      "Our organization has clearly articulated the staff resources that will be dedicated to supporting the integration of the project work into day-to-day operations.",
      "Our organization is committed to attaining the necessary resources to sustain project work over the long term.",
    ],
    assessments: [
      {
        label: "You're ready",
        between: [8, 10],
      },
      {
        label: "Almost there",
        between: [5, 7],
      },
      {
        label: "Work to do",
        between: [0, 4],
      },
    ],
  },
];

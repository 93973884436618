import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import pick from "lodash/pick";
import styled from "styled-components-themed";
import { RootState } from "frontend-app/typings";
import { updateOrganization } from "organization/src/reducer";
import { TextInput, Heading, Box, Select, Button } from "grommet";
import { Formik, Form, Field, FieldProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import { setNotification } from "frontend-app/src/reducers/app";
import { updateUserMetadata } from "frontend-app/src/reducers/session";
import missionAreas from "account/src/data/missionAreas";
import organizationBudgets from "account/src/data/organizationBudgets";
import organizationTypes from "account/src/data/organizationTypes";
import states from "account/src/data/states";

interface Props {
  email?: string;
  firstName?: string;
  lastName?: string;
  organization: RootState["organizations"]["organization"];
  updateOrganizationAction: typeof updateOrganization;
  setNotificationAction: typeof setNotification;
  updateUserMetadataAction: typeof updateUserMetadata;
}

const FormWrapper = styled.div`
  max-width: 28.125rem;
`;

const Label = styled.label`
  display: block;
  margin: 0.5rem 0 0.25rem;
`;

const FieldsGroup = styled.div`
  margin-bottom: 2.5rem;
`;

const ErrorP = styled.p`
  margin-bottom: 0.25rem;
  color: ${(props) => props.theme.colors.red};
`;

const initialOrganizationValues = {
  name: "",
  type: "",
  mission: "",
  budgetSize: "",
  city: "",
  state: "",
};

// const initialContactValues = {
//   name: "",
//   email: "",
//   title: "",
//   phoneNumber: "",
// };

const AccountForm: React.FunctionComponent<Props> = ({
  email = "",
  firstName = "",
  lastName = "",
  organization,
  updateOrganizationAction,
  updateUserMetadataAction,
  setNotificationAction,
}): JSX.Element => {
  const organizationValues = pick(
    organization,
    Object.keys(initialOrganizationValues),
  );

  // const contactValues = pick(
  //   organization ? organization.contact : {},
  //   Object.keys(initialContactValues),
  // );

  return (
    <FormWrapper>
      <Formik
        enableReinitialize={true}
        initialValues={{
          email,
          firstName,
          lastName,
          organization: {
            ...initialOrganizationValues,
            ...organizationValues,
          },
          // contact: {
          //   ...initialContactValues,
          //   ...contactValues,
          // },
        }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object({
          firstName: Yup.string()
            .trim()
            .required("First name is required."),
          lastName: Yup.string()
            .trim()
            .required("Last name is required."),
          email: Yup.string().required("Email is required."),
          // contact: Yup.object({
          //   name: Yup.string().required("Name is required."),
          //   email: Yup.string().required("Email is required."),
          //   title: Yup.string(),
          //   phoneNumber: Yup.string(),
          // }),
          organization: Yup.object({
            name: Yup.string()
              .trim()
              .required("Organization name is required."),
            type: Yup.string().required("Organization type is required."),
            mission: Yup.string().required("Organization mission is required."),
            budgetSize: Yup.string().required(
              "Organization budget size is required.",
            ),
            city: Yup.string()
              .trim()
              .required("City is required."),
            state: Yup.string().required("State is required."),
          }),
        })}
        onSubmit={async (values, actions) => {
          if (organization && organization._id) {
            try {
              if (
                firstName !== values.firstName ||
                lastName !== values.lastName
              ) {
                await updateUserMetadataAction({
                  first_name: values.firstName,
                  last_name: values.lastName,
                });
              }
              await updateOrganizationAction(organization._id, {
                ...values.organization,
                // contact: {
                //   ...values.contact,
                // },
              });
              setNotificationAction("Successfully Updated!");
            } catch (error) {
              console.log("error updating organization! " + error);
            }
          }
          actions.setSubmitting(false);
        }}
      >
        {(formikProps) => {
          return (
            <Form>
              <FieldsGroup>
                <Field
                  name="firstName"
                  render={({ field }: FieldProps) => (
                    <Box margin={{ vertical: "small" }}>
                      <Label htmlFor="firstName">First name</Label>
                      <ErrorMessage name="firstName" component={ErrorP} />
                      <TextInput id="firstName" {...field} />
                    </Box>
                  )}
                />
                <Field
                  name="lastName"
                  render={({ field }: FieldProps) => (
                    <Box margin={{ vertical: "small" }}>
                      <Label htmlFor="lastName">Last name</Label>
                      <ErrorMessage name="lastName" component={ErrorP} />
                      <TextInput id="lastName" {...field} />
                    </Box>
                  )}
                />
                <Field
                  name="email"
                  render={({ field }: FieldProps) => (
                    <Box margin={{ vertical: "small" }}>
                      <Label htmlFor="email">Your email</Label>
                      <TextInput disabled id="email" {...field} />
                    </Box>
                  )}
                />
              </FieldsGroup>
              <FieldsGroup>
                <Heading level={2} color="#2d4a58" margin={{ bottom: "1rem" }}>
                  Your Organization
                </Heading>
                <Field
                  name="organization.name"
                  render={({ field }: FieldProps) => (
                    <Box margin={{ vertical: "small" }}>
                      <Label htmlFor="organization.name">
                        Full name of organization
                      </Label>
                      <ErrorMessage
                        name="organization.name"
                        component={ErrorP}
                      />
                      <TextInput id="organization.name" {...field} />
                    </Box>
                  )}
                />
                <Field
                  name="organization.type"
                  render={({ field }: FieldProps) => (
                    <Box margin={{ vertical: "small" }}>
                      <Label htmlFor="organization.type">
                        Organization type
                      </Label>
                      <ErrorMessage
                        name="organization.type"
                        component={ErrorP}
                      />
                      <Select
                        {...field}
                        placeholder="Organization type"
                        options={organizationTypes}
                        onChange={({ value }) => {
                          formikProps.setFieldValue("organization.type", value);
                        }}
                      />
                    </Box>
                  )}
                />
                <Field
                  name="organization.mission"
                  render={({ field }: FieldProps) => (
                    <Box margin={{ vertical: "small" }}>
                      <Label htmlFor="organization.mission">
                        Mission area (NTEE Category)
                      </Label>
                      <ErrorMessage
                        name="organization.mission"
                        component={ErrorP}
                      />
                      <Select
                        {...field}
                        placeholder="Organization mission"
                        options={missionAreas}
                        onChange={({ value }) => {
                          formikProps.setFieldValue(
                            "organization.mission",
                            value,
                          );
                        }}
                      />
                    </Box>
                  )}
                />
                <Field
                  name="organization.budgetSize"
                  render={({ field }: FieldProps) => (
                    <Box margin={{ vertical: "small" }}>
                      <Label htmlFor="organization.budgetSize">
                        Organization budget
                      </Label>
                      <ErrorMessage
                        name="organization.budgetSize"
                        component={ErrorP}
                      />
                      <Select
                        {...field}
                        placeholder="Organization budget size"
                        options={organizationBudgets}
                        onChange={({ value }) => {
                          formikProps.setFieldValue(
                            "organization.budgetSize",
                            value,
                          );
                        }}
                      />
                    </Box>
                  )}
                />
                <Field
                  name="organization.city"
                  render={({ field }: FieldProps) => (
                    <Box margin={{ vertical: "small" }}>
                      <Label htmlFor="organization.city">City</Label>
                      <ErrorMessage
                        name="organization.city"
                        component={ErrorP}
                      />
                      <TextInput id="organization.city" {...field} />
                    </Box>
                  )}
                />
                <Field
                  name="organization.state"
                  render={({ field }: FieldProps) => (
                    <Box margin={{ vertical: "small" }}>
                      <Label htmlFor="organization.state">
                        State / Territory
                      </Label>
                      <ErrorMessage
                        name="organization.state"
                        component={ErrorP}
                      />
                      <Select
                        {...field}
                        placeholder="Organization state"
                        options={states}
                        onChange={({ value }) => {
                          formikProps.setFieldValue(
                            "organization.state",
                            value,
                          );
                        }}
                      />
                    </Box>
                  )}
                />
              </FieldsGroup>
              {/* <FieldsGroup>
                <Heading level={2} color="#2d4a58" margin={{ bottom: "1rem" }}>
                  Primary Contact
                </Heading>
                <Field
                  name="contact.name"
                  render={({ field }: FieldProps) => (
                    <Box margin={{ vertical: "small" }}>
                      <Label htmlFor="contact.name">
                        Name of primary contact
                      </Label>
                      <ErrorMessage name="contact.name" component={ErrorP} />
                      <TextInput id="contact.name" {...field} />
                    </Box>
                  )}
                />
                <Field
                  name="contact.email"
                  render={({ field }: FieldProps) => (
                    <Box margin={{ vertical: "small" }}>
                      <Label htmlFor="contact.email">
                        Email of primary contact
                      </Label>
                      <ErrorMessage name="contact.email" component={ErrorP} />
                      <TextInput id="contact.email" {...field} />
                    </Box>
                  )}
                />
                <Field
                  name="contact.title"
                  render={({ field }: FieldProps) => (
                    <Box margin={{ vertical: "small" }}>
                      <Label htmlFor="contact.title">
                        Title of primary contact (optional)
                      </Label>
                      <ErrorMessage name="contact.title" component={ErrorP} />
                      <TextInput id="contact.title" {...field} />
                    </Box>
                  )}
                />
                <Field
                  name="contact.phoneNumber"
                  render={({ field }: FieldProps) => (
                    <Box margin={{ vertical: "small" }}>
                      <Label htmlFor="contact.phoneNumber">
                        Phone number of primary contact (optional)
                      </Label>
                      <ErrorMessage
                        name="contact.phoneNumber"
                        component={ErrorP}
                      />
                      <TextInput id="contact.phoneNumber" {...field} />
                    </Box>
                  )}
                />
              </FieldsGroup> */}
              <Box align="end">
                <Button
                  disabled={formikProps.isSubmitting}
                  type="submit"
                  primary={true}
                  label="Update"
                />
              </Box>
            </Form>
          );
        }}
      </Formik>
    </FormWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  email: state.session.email,
  firstName: state.session.firstName,
  lastName: state.session.lastName,
  organization: state.organizations.organization,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      updateOrganizationAction: updateOrganization,
      updateUserMetadataAction: updateUserMetadata,
      setNotificationAction: setNotification,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountForm);

import { Project } from "projects/typings";
import ApiClient from "./";

const projectsPath = "/api/projects";

export function getProjects() {
  return ApiClient.get(projectsPath);
}

export function getProject(id: string) {
  return ApiClient.get(`${projectsPath}/${id}`);
}

// export function createProject(payload: Project) {
export function createProject() {
  return ApiClient.post(projectsPath, {});
}

export function updateProject(id: string, payload: Project) {
  return ApiClient.put(`${projectsPath}/${id}`, payload);
}

export function deleteProject(id: string) {
  return ApiClient.delete(`${projectsPath}/${id}`);
}

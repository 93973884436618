const missionAreas = [
  "Arts, Culture & Humanities (A)",
  "Education (B)",
  "Environment (C)",
  "Animal-Related (D)",
  "Healthcare (E)",
  "Mental Health & Crisis Intevention (F)",
  "Diseases, Disorders & Medical Disciplines (G)",
  "Medical Research (H)",
  "Crime & Legal-Related (I)",
  "Employment (J)",
  "Food, Agriculture & Nutrition (K)",
  "Housing & Shelter (L)",
  "Public Safety, Disaster Preparedness & Relief (M)",
  "Recreation & Sports (N)",
  "Youth Development (O)",
  "Human Services (P)",
  "International, Foreign Affairs & National Security (Q)",
  "Civil Rights, Social Action & Advocacy (R)",
  "Community Improvement & Capacity Building (S)",
  "Philanthropy, Voluntarism & Grantmaking Foundations (T)",
  "Science & Technology (U)",
  "Social Science (V)",
  "Public & Societal Benefit (W)",
  "Religion-Related (X)",
  "Mutual & Membership Benefit (Y)",
  "Unknown/Other (Z)",
  "N/A",
];

export default missionAreas;

import React from "react";
import styled from "styled-components-themed";
import { RadioGroup, Radio } from "../Radio";
import { options } from "./utils";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  num: number;
  selected?: number;
  onChange?: (event: React.FormEvent<HTMLInputElement>, value?: any) => void;
}

const Wrapper = styled.div`
  /* display: grid;
  grid-template-columns: minmax(4rem, auto) 1fr 12.5rem; */
  display: flex;
  border: 0.125rem solid ${(props) => props.theme.colors.assessmentBorder};
  border-radius: 0.375rem;
  overflow: hidden;
`;

const Num = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 5rem;
  padding: 0.5rem;
  color: ${(props) => props.theme.colors.assessmentBorder};
  border-right: 0.125rem solid ${(props) => props.theme.colors.assessmentBorder};
  font-size: 2rem;
`;

const Content = styled.p`
  font-size: 1.125rem;
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  flex: 1;
`;

const StyledRadio = styled(Radio)`
  font-size: 1rem;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 13.75rem;
  padding: 1rem;
`;

const AssessmentItem: React.FunctionComponent<Props> = (props) => {
  const { num, children, onChange, selected, className, ...restProps } = props;
  const assessmentNum = `assessment-${num}`;

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event, { value: event.currentTarget.value, num });
    }
  };

  return (
    <Wrapper
      data-testid={assessmentNum}
      className={`assessment-item ${className}`}
      {...restProps}
    >
      <Num>{num}</Num>
      <Content>{children}</Content>
      <StyledRadioGroup
        name={assessmentNum}
        onChange={handleChange}
        value={selected !== undefined ? selected.toString() : undefined}
      >
        {options.map((option) => (
          <StyledRadio
            key={option.label}
            id={`${assessmentNum}-${option.value}`}
            value={`${option.value}`}
            label={option.label}
          />
        ))}
      </StyledRadioGroup>
    </Wrapper>
  );
};

// export default AssessmentItem;
export default styled(AssessmentItem)``;

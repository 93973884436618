import * as React from "react";
import styled from "styled-components-themed";
import { Link } from "@reach/router";
import { Button, Heading } from "grommet";
import { MdArrowForward } from "react-icons/md";
import { Anchor } from "shared-ui";

const SubHeading = styled(Heading)`
  max-width: none;
  font-size: 1.5rem;
  font-family: ${(props) => props.theme.fonts.base};
  color: #244b5a;
`;

const Paragraph = styled.p`
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
`;

const UnorderedList = styled.ul`
  padding-left: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
`;

const ProjectHome: React.FunctionComponent = () => {
  return (
    <div>
      <Heading level="1" color="#244B5A">
        Evaluate Its Readiness
      </Heading>
      <SubHeading level="3" margin={{ bottom: "1.5rem" }}>
        Is My Project Ready to Be Matched with Volunteers?
      </SubHeading>
      <Paragraph>
        Skills-based volunteering projects yield transformational results - if
        they’re properly planned and prioritized. Take our{" "}
        <strong>Project Readiness Assessment</strong> to receive an objective
        assessment of the readiness of your proposed project, along with
        customized tips for positioning the project for maximum organizational
        impact.
      </Paragraph>
      <SubHeading level="3" margin={{ bottom: "1.5rem" }}>
        Instructions
      </SubHeading>
      <Paragraph>
        It takes about 10 minutes to complete the assessment. Simply answer each
        of the questions as honestly and accurately as you can, using the
        following scale:
      </Paragraph>
      <UnorderedList>
        <li>Definitely true</li>
        <li>Mostly true</li>
        <li>Somewhat true</li>
        <li>Not true</li>
      </UnorderedList>
      <Paragraph>
        After the assessment, we’ll share your results along with tips to
        increase the readiness of your project.
      </Paragraph>

      <Anchor as={Link} to="../step">
        <Button
          primary={true}
          icon={<MdArrowForward />}
          reverse={true}
          label="Start the Assessment"
          margin={{ vertical: "medium" }}
        />
      </Anchor>
    </div>
  );
};

export default ProjectHome;

import React from "react";
import styled from "styled-components-themed";
import { Meter, Box, Text } from "grommet";

interface Props {
  label?: string;
  valueColor?: string;
  barColor?: string;
  value: number;
  total: number;
  renderValue?: Function;
}

const StyledMeter = styled(Meter)`
  width: 100%;
  border: 1px solid rgba(178, 178, 178, 0.4);
`;

const ProgressBar: React.FunctionComponent<Props> = ({
  value,
  valueColor,
  barColor,
  total,
  label = "",
  renderValue,
}) => {
  const percentage = Math.round((value / total) * 100);
  return (
    <React.Fragment>
      <Box
        direction="row"
        justify="between"
        align="baseline"
        margin={{ bottom: "xsmall" }}
      >
        <Text size="small">{label}</Text>
        {renderValue ? (
          renderValue({ value, percentage })
        ) : (
          <Text size="large" color={valueColor}>
            {percentage}%
          </Text>
        )}
      </Box>
      <StyledMeter
        type="bar"
        thickness="small"
        round={true}
        values={[{ label, value: percentage, color: barColor }]}
      />
    </React.Fragment>
  );
};

export default ProgressBar;

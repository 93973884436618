import * as React from "react";
import styled from "styled-components-themed";
import { Link } from "@reach/router";
import { Heading, Button } from "grommet";
import { MdArrowForward } from "react-icons/md";
import {
  assessments as organizationAssessments,
  assessmentMap as organizationAssessmentMap,
} from "../../data/organization";
import {
  assessments as projectAssessments,
  assessmentMap as projectAssessmentMap,
} from "../../data/project";
import { AssessmentContext } from "../Assessment/Store";
import { getAssessmentScore } from "../Assessment/utils";
import { ResultsParagraph } from "./styles";
import ResultList from "./ResultList";

const Wrapper = styled.div`
  padding-top: 2rem;
`;

const Header = styled.div`
  display: flex;
`;

const ProjectResults: React.FunctionComponent<{}> = () => {
  const { state } = React.useContext(AssessmentContext);
  const assessments =
    state.type === "Project" ? projectAssessments : organizationAssessments;
  const assessmentMap =
    state.type === "Project" ? projectAssessmentMap : organizationAssessmentMap;
  const assessment = getAssessmentScore(assessments, state.totalScore);
  const { topBlurb } = assessmentMap[
    assessment.label as keyof typeof assessmentMap
  ];

  return (
    <Wrapper>
      <Header>
        <Heading
          level="1"
          margin={{ bottom: "1.5rem", top: "1rem" }}
          color="#244B5A"
        >
          Project Readiness Assessment
        </Heading>

        <Link to={`../step`}>
          <Button
            label="Retake"
            primary={true}
            color="#228DC1"
            icon={<MdArrowForward />}
            fill="horizontal"
            reverse={true}
            margin="xsmall"
          />
        </Link>
      </Header>
      <Heading level="3">{assessment.label}</Heading>
      <ResultsParagraph
        margin="none"
        dangerouslySetInnerHTML={{ __html: topBlurb }}
      />
      <ResultList />
    </Wrapper>
  );
};

export default ProjectResults;

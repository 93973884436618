export const NOTIFICATION_SET = "NOTIFICATION_SET";

export const setNotification = (message: string) => ({
  type: NOTIFICATION_SET,
  payload: message,
});

export type Action = { type: typeof NOTIFICATION_SET; payload: string };

export interface State {
  notification: string;
}

const initialState: State = {
  notification: "",
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case NOTIFICATION_SET:
      return {
        ...state,
        notification: action.payload,
      };
    default:
      return state;
  }
};

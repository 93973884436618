import React from "react";
// import { getAssessments } from "api/assessments";
import { AssessmentContext } from "../Assessment/Store";
import ProjectResults from "./ProjectResults";
import OrganizationResults from "./OrganizationResults";

const Results: React.FunctionComponent<{}> = () => {
  const { state } = React.useContext(AssessmentContext);

  return state.type === "Project" ? (
    <ProjectResults />
  ) : (
    <OrganizationResults />
  );
};

export default Results;

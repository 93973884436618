import React from "react";
import styled from "styled-components-themed";

interface Props {
  heading?: string;
}

const Wrapper = styled.header`
  padding: 1.5rem;
  background: #fff;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

const Header: React.FunctionComponent<Props> = (props) => {
  const { heading, children, ...restProps } = props;

  return (
    <Wrapper {...restProps}>
      {heading && <h1>{heading}</h1>}
      {children}
    </Wrapper>
  );
};

export default Header;

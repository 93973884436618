import React from "react";
import styled from "styled-components-themed";
import Tag from "./Tag";

interface Props {
  items: string[];
  initialChecked?: string[];
  onStateChange?: ({
    tag,
    checkedTags,
  }: {
    tag: any;
    checkedTags: any;
  }) => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Tags: React.FunctionComponent<Props> = (props) => {
  const { items, initialChecked = [], onStateChange } = props;
  const [checkedTags, setCheckedTags] = React.useState(
    initialChecked as string[],
  );

  return (
    <Wrapper>
      {items.map((item, index) => {
        return (
          <Tag
            key={index}
            checked={checkedTags.includes(item) ? true : false}
            onClick={() => {
              let newArray = checkedTags.includes(item)
                ? checkedTags.filter((tag) => tag !== item)
                : [...checkedTags, item];
              setCheckedTags(newArray);
              onStateChange &&
                onStateChange({ tag: item, checkedTags: newArray });
            }}
          >
            {item}
          </Tag>
        );
      })}
    </Wrapper>
  );
};

export default Tags;

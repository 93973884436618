import React from "react";
import styled from "styled-components-themed";
import { Box, Heading, Text } from "grommet";
import { unquantifiableValueOptions } from "@rr/utils/calculator/groups";
import { Calculator } from "../../../typings";

interface Props {
  items: Calculator["unquantifiableValue"];
}
const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 0.25rem;
  margin-bottom: 0.5rem;
  border-bottom: 0.125rem solid #ebebeb;
`;

const StyledHeader = styled(Heading)`
  font-size: 0.75rem;
  font-style: italic;
`;

const UnquantifiableValue: React.FunctionComponent<Props> = (props) => {
  const { items } = props;
  return (
    <Box margin={{ bottom: "2.5rem" }}>
      <Box direction="row" align="center" margin={{ bottom: "1.5rem" }}>
        <Heading
          level={4}
          size="medium"
          color="#2d4a58"
          margin={{ right: "xsmall" }}
        >
          Unquantifiable Value
        </Heading>
      </Box>
      {unquantifiableValueOptions.map((option, optionIndex) => {
        const filteredItems =
          items &&
          items.length > 0 &&
          items.filter((item) => item.valueWeight === option);
        return (
          filteredItems &&
          filteredItems.length > 0 && (
            <Box margin={{ bottom: "1.5rem" }} key={optionIndex}>
              <RowWrapper>
                <StyledHeader as="p" size="small">
                  {option}
                </StyledHeader>
              </RowWrapper>
              {filteredItems.length > 0 ? (
                filteredItems.map((item, itemIndex) => (
                  <RowWrapper key={itemIndex}>
                    <Text as="p" size="medium">
                      {item.description}
                    </Text>
                  </RowWrapper>
                ))
              ) : (
                <Text as="p" size="medium">
                  No Values
                </Text>
              )}
            </Box>
          )
        );
      })}
    </Box>
  );
};

export default UnquantifiableValue;

import * as React from "react";
// import { Router, Link } from "@reach/router";
import { Router } from "@reach/router";
import { ProtectedRoute, NotFound } from "shared-ui";
import Layout from "./components/Layout";
import New from "./pages/new";
import Scope from "./pages/scope";
import Assessment from "./pages/assessment";
//import Calculator from "./pages/calculator";
import { Home as Calculator, Results } from "calculator";
import Volunteers from "./pages/volunteers";

const Routes: React.FunctionComponent<{ basepath?: string }> = React.memo(
  ({ basepath = "" }) => (
    <Router basepath={basepath} primary={false}>
      <ProtectedRoute component={New} path="new" />
      <ProtectedRoute component={Layout} path=":projectId">
        <ProtectedRoute component={Scope} path="scope" />
        <ProtectedRoute component={Assessment} path="assessment/*" />
        <ProtectedRoute component={Calculator} path="calculator" />
        <ProtectedRoute component={Results} path="calculator/results" />
        <ProtectedRoute component={Volunteers} path="volunteers" />
      </ProtectedRoute>
      <ProtectedRoute
        default
        component={() => <NotFound>Not Found</NotFound>}
      />
    </Router>
  ),
);

Routes.displayName = "ProjectsRoutes";

export default Routes;

const uuidv4 = require("uuid/v4");
import React from "react";
import styled from "styled-components-themed";
import Group from "./Group";
import UnquantifiableValue from "./UnquantifiableValue";
import { connect } from "react-redux";
import { RootState } from "frontend-app/typings";
import { Calculator } from "../../../typings";
import { displayUSD } from "@rr/utils";
import { calculateGroupTotals } from "@rr/utils/calculator/totals";
import { editCalculator, updateCalculator } from "../../reducers";
import { Link } from "@reach/router";
import { Anchor, Breadcrumbs, ShareButton } from "shared-ui";
import { Box, Heading, Button as ButtonBase } from "grommet";
import { FaPencilAlt, FaHome } from "react-icons/fa";
import {
  organizationalValue,
  organizationalInvestment,
} from "@rr/utils/calculator/groups";

interface Props {
  monetaryValueOfConsultants: Calculator["monetaryValueOfConsultants"];
  monetaryValueOfProducts: Calculator["monetaryValueOfProducts"];
  longTermBusinessValue: Calculator["longTermBusinessValue"];
  longTermBusinessValueUniqueToProBono: Calculator["longTermBusinessValueUniqueToProBono"];
  staffTime: Calculator["staffTime"];
  opportunityCostOfImplementation: Calculator["opportunityCostOfImplementation"];
  implementationAndMaintenance: Calculator["implementationAndMaintenance"];
  unquantifiableValue: Calculator["unquantifiableValue"];
  editCalculatorAction: typeof editCalculator;
  updateCalculatorAction: typeof updateCalculator;
  projectName?: string;
  calculator: Calculator;
}

const Wrapper = styled.div`
  margin-top: 2rem;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  color: #333;
`;

const Container = styled.div`
  padding: 1rem 0;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 0;
  margin-bottom: 2.5rem;
  border-bottom: 0.125rem solid #ebebeb;
`;

const Button = styled(ButtonBase)`
  color: ${(props) => props.theme.colors.blue};
  font-size: 0.875rem;
`;

const Results: React.FunctionComponent<Props> = (props) => {
  const {
    monetaryValueOfConsultants,
    monetaryValueOfProducts,
    longTermBusinessValue,
    longTermBusinessValueUniqueToProBono,
    staffTime,
    opportunityCostOfImplementation,
    implementationAndMaintenance,
    unquantifiableValue,
    editCalculatorAction,
    updateCalculatorAction,
    projectName,
    calculator,
  } = props;
  return (
    <Wrapper>
      <Box direction="row" align="center" justify="between">
        <Breadcrumbs>
          <Anchor href="https://www.capacitycommons.org/">
            <FaHome size="16px" color="#238DC1" />
          </Anchor>
          <Anchor as={Link} to="/">
            My Projects
          </Anchor>
          <div>{projectName}</div>
        </Breadcrumbs>
        <Box direction="row">
          <ShareButton
            margin={{ horizontal: "medium" }}
            prefix="net-value"
            getShareId={async () => {
              let shareId = calculator.shareId;
              if (!shareId) {
                // calculator has no shareId, generate one!
                const response = await updateCalculatorAction(calculator._id!, {
                  shareId: uuidv4(),
                });
                shareId = response.shareId;
              }
              return shareId;
            }}
          />
          <Button
            onClick={() => editCalculatorAction()}
            plain
            alignSelf="center"
            gap="xsmall"
            icon={<FaPencilAlt size="14px" color="#238DC1" />}
            label="EDIT"
          />
        </Box>
      </Box>
      <Container>
        <Heading color="#2d4a58" margin={{ bottom: "1.5rem", top: "1rem" }}>
          Estimate Net Value
        </Heading>
        <HeaderWrapper>
          <Heading level={3} size="large" color="#2d4a58">
            Estimated Net Value
          </Heading>
          <Heading level={3} size="large">
            {displayUSD(
              calculateGroupTotals(organizationalValue, props) -
                calculateGroupTotals(organizationalInvestment, props),
            )}
          </Heading>
        </HeaderWrapper>
        <UnquantifiableValue items={unquantifiableValue} />
        <Box>
          <HeaderWrapper>
            <Heading level={3} size="medium" color="#2d4a58">
              Organizational Value
            </Heading>
            <Heading level={3} size="medium">
              {displayUSD(calculateGroupTotals(organizationalValue, props))}
            </Heading>
          </HeaderWrapper>
          <Group
            header="Monetary Value of Volunteers"
            items={monetaryValueOfConsultants!}
          />
          <Group
            header="Monetary Value of Products"
            items={monetaryValueOfProducts!}
          />
          <Group
            header="Long Term Business Value"
            items={longTermBusinessValue!}
          />
          <Group
            header="Long Term Business Value Unique to Pro Bono"
            items={longTermBusinessValueUniqueToProBono!}
          />
        </Box>
        <Box>
          <HeaderWrapper>
            <Heading level={3} size="medium" color="#2d4a58">
              Organizational Investment
            </Heading>
            <Heading level={3} size="medium">
              -{" "}
              {displayUSD(
                calculateGroupTotals(organizationalInvestment, props),
              )}
            </Heading>
          </HeaderWrapper>
          <Group header="Staff Time" sign="-" items={staffTime!} />
          <Group
            header="Opportunity Cost of Implementation"
            sign="-"
            items={opportunityCostOfImplementation!}
          />
          <Group
            header="Implementation and Maintenance"
            sign="-"
            items={implementationAndMaintenance!}
          />
        </Box>
      </Container>
    </Wrapper>
  );
};

const mapStateToProps = (state: RootState) => {
  const { calculator } = state.calculator;
  return {
    monetaryValueOfConsultants: calculator.monetaryValueOfConsultants,
    monetaryValueOfProducts: calculator.monetaryValueOfProducts,
    longTermBusinessValue: calculator.longTermBusinessValue,
    longTermBusinessValueUniqueToProBono:
      calculator.longTermBusinessValueUniqueToProBono,
    staffTime: calculator.staffTime,
    opportunityCostOfImplementation: calculator.opportunityCostOfImplementation,
    implementationAndMaintenance: calculator.implementationAndMaintenance,
    unquantifiableValue: calculator.unquantifiableValue,
    projectName: state.projects.project && state.projects.project.name,
    // FIXME:
    calculator,
  };
};

const mapDispatchToProps = {
  updateCalculatorAction: updateCalculator,
  editCalculatorAction: editCalculator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps as any,
)(Results);

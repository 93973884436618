import styled from "styled-components-themed";
import { TextInput, Select } from "grommet";

export const StyledLabel = styled.label<{ display: string }>`
  display: ${(props) => props.display};
  font-size: 0.75rem;
  font-style: italic;
  width: 100%;

  > div > div > svg {
    margin-left: 0;
    margin-right: 0.3rem;
    width: 1rem;
    height: 1rem;
  }
`;

export const StyledSelect = styled(Select)`
  background-color: #fcf9e9;
  max-height: 2.1875rem;
  margin-top: 0.5rem;
`;

export const StyledTextInput = styled(TextInput)`
  text-align: left;
  background-color: #fcf9e9;
  height: 2.1875rem;
  margin-top: 0.5rem;
`;

export const StyledNumberInput = styled(TextInput)`
  text-align: right;
  background-color: #fcf9e9;
  height: 2.1875rem;
  margin-top: 0.5rem;
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  appearance: textfield;
`;

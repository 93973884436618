import React from "react";
// import styled from "styled-components-themed";
import RadioGroupContext from "./RadioGroupContext";

export interface Props {
  name: string;
  value?: string;
  onChange?: (event: React.FormEvent<HTMLInputElement>, value?: any) => void;
}

const RadioGroup: React.FunctionComponent<Props> = (props) => {
  const { name, value, onChange, children, ...restProps } = props;
  const [valueState, setValueState] = React.useState(value || "");

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setValueState(event.currentTarget.value);

    if (onChange) {
      onChange(event, event.currentTarget.value);
    }
  };

  const context = { name, onChange: handleChange, value: valueState };

  return (
    <div {...restProps}>
      <RadioGroupContext.Provider value={context}>
        {children}
      </RadioGroupContext.Provider>
    </div>
  );
};

export default RadioGroup;

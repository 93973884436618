import React from "react";
import { Box, Heading, Text } from "grommet";
import { AssessmentType } from "../../../typings";

import {
  ResultsParagraph,
  GroupContainer,
  GroupTitleContainer,
  GroupTitleContainerRightColumn,
} from "./styles";

import { organizationMessages } from "../../data/organization";
import { projectMessages } from "../../data/project";

interface Props {
  type: AssessmentType | "";
  group: {
    title: string;
  };
  score: number;
  maxGroupPoints: number;
  sectionAssessment: {
    label: string;
  };
  assessmentGroupIcon: any;
  assessmentGroupColor: string;
}

interface ResourceObject {
  title: string;
  items: Array<string>;
}

function isResourceObject(obj: string | ResourceObject): obj is ResourceObject {
  return typeof obj !== "string";
}

const ResultItem: React.FunctionComponent<Props> = ({
  type,
  group,
  score,
  sectionAssessment,
  maxGroupPoints,
  assessmentGroupIcon: AssessmentGroupIcon,
  assessmentGroupColor,
}) => {
  const messages = type === "Project" ? projectMessages : organizationMessages;
  const section = messages[group.title as keyof typeof messages] as any;

  if (!section) {
    return null;
  }

  return (
    <GroupContainer key={group.title}>
      <GroupTitleContainer direction="row" justify="between" align="center">
        <Heading level="4">{group.title}</Heading>
        <GroupTitleContainerRightColumn>
          <div>
            <Heading level="6">{sectionAssessment.label}</Heading>
            <Text size="xsmall">
              {score} out of {maxGroupPoints}
            </Text>
          </div>
          <AssessmentGroupIcon color={assessmentGroupColor} size="32px" />
        </GroupTitleContainerRightColumn>
      </GroupTitleContainer>
      <ResultsParagraph>{section.blurb}</ResultsParagraph>
      <Heading level="4" margin={{ top: "1rem" }}>
        Helpful Tools &amp; Resources:
      </Heading>
      <Box direction="column" margin={{ top: "xsmall" }}>
        {(section.resources as Array<string | ResourceObject>).map(
          (resource, idx) => {
            if (isResourceObject(resource)) {
              return (
                <Box key={idx}>
                  <Text>{resource.title}</Text>
                  <Box margin={{ top: "xsmall", left: "medium" }}>
                    {resource.items.map((item, index) => (
                      <li
                        key={index}
                        dangerouslySetInnerHTML={{ __html: item }}
                        style={{ marginBottom: "1rem" }}
                      />
                    ))}
                  </Box>
                </Box>
              );
            } else {
              return (
                <li
                  key={idx}
                  dangerouslySetInnerHTML={{ __html: resource }}
                  style={{ marginBottom: "1rem" }}
                />
              );
            }
          },
        )}
      </Box>
    </GroupContainer>
  );
};

export default ResultItem;

import React from "react";
import { FieldArray, Field, FieldProps } from "formik";
import { Text, Box } from "grommet";
import {
  Header,
  CurrencyInput,
  AddFieldButton,
  TrashButton,
} from "../../components";
import { StyledLabel, StyledTextInput } from "../../styles/Inputs";
import { Wrapper, RowBox } from "../../styles/Wrappers";
import { calculateGroupAmount } from "@rr/utils/calculator/totals";
import fieldTypes from "../../data/fieldTypes";
import { opportunityCostOfImplementationPlaceholders } from "../../data/placeholders";

interface Props {
  values?: {
    [key: string]: string;
  }[];
}

const OpportunityCostOfImplementation: React.FunctionComponent<
  Props
> = React.memo((props) => {
  const { values } = props;
  return (
    <Wrapper>
      <Header
        data-testid="oci-value"
        heading="Opportunity Cost Of Implementation"
        size="medium"
        level={4}
        amount={calculateGroupAmount(values) || "$0.00"}
        tooltipContent="<em>The value of the initiatives that you could otherwise pursue with the resources devoted to this project.</em>\n\nFor example, if you plan to have your Development Director spearhead a website project, map out the cost of her time away from other fund-generating activities and the average conversion on time spent to donor dollars."
      />
      <Text
        margin={{ vertical: "1rem" }}
        size="small"
        dangerouslySetInnerHTML={{
          __html:
            "Below are examples of opportunity costs that your project might incur.  Fill in your own opportunity costs based on the type of project you are exploring.  Input monetary value of each opportunity cost in column E to determine total cost of project implementation.",
        }}
      />
      <FieldArray name="opportunityCostOfImplementation">
        {(arrayHelpers) => (
          <React.Fragment>
            {values &&
              values.length > 0 &&
              values.map((value, index) => (
                <div key={index}>
                  <RowBox>
                    <Box width="80%">
                      <StyledLabel
                        display={index !== 0 ? "none" : "flex"}
                        htmlFor="descriptionOfOpportunity"
                      >
                        {"Description of opportunity"}
                      </StyledLabel>
                    </Box>
                    <Box width="20%">
                      <StyledLabel
                        display={index !== 0 ? "none" : "flex"}
                        htmlFor="totalValue"
                      >
                        {"Total Value"}
                      </StyledLabel>
                    </Box>
                  </RowBox>
                  <RowBox>
                    <TrashButton onClick={() => arrayHelpers.remove(index)} />
                    <Box width="80%" margin={{ right: ".35rem" }}>
                      <Field
                        name={`opportunityCostOfImplementation.${index}.descriptionOfOpportunity`}
                        render={({ field }: FieldProps) => (
                          <StyledTextInput
                            {...field}
                            placeholder={
                              opportunityCostOfImplementationPlaceholders[
                                index
                              ] || ""
                            }
                            id="descriptionOfOpportunity"
                            size="small"
                            type="text"
                          />
                        )}
                      />
                    </Box>
                    <Box width="20%">
                      <Field
                        name={`opportunityCostOfImplementation.${index}.totalValue`}
                        render={({ field }: FieldProps) => (
                          <CurrencyInput
                            {...field}
                            id="totalValue"
                            placeholder="0.00"
                            onStateChange={(currency) => {
                              arrayHelpers.replace(index, {
                                ...value,
                                totalValue: currency,
                              });
                            }}
                          />
                        )}
                      />
                    </Box>
                  </RowBox>
                </div>
              ))}
            {values && (
              <AddFieldButton
                onClick={() =>
                  arrayHelpers.push(
                    fieldTypes["opportunityCostOfImplementation"],
                  )
                }
                text="Add an opportunity cost"
              />
            )}
          </React.Fragment>
        )}
      </FieldArray>
    </Wrapper>
  );
});

OpportunityCostOfImplementation.displayName = "OpportunityCostOfImplementation";

export default OpportunityCostOfImplementation;

const uuidv4 = require("uuid/v4");
import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components-themed";
import { Link } from "@reach/router";
import { Box } from "grommet";
import { FaHome } from "react-icons/fa";
import { RootState } from "frontend-app/typings";
import { Anchor, Breadcrumbs, ShareButton } from "shared-ui";
import { selectProjectsProgressValue } from "@rr/utils/projects/getProgressValue";
import { updateProject } from "../../reducer";
import EditableTitle from "../../components/EditableTitle";
import QuestionGroup from "./components/QuestionGroup";
import QuestionGroupArray from "./components/QuestionGroupArray";
import SkillsNeeded from "./components/SkillsNeeded";
import ProjectDuration from "./components/ProjectDuration";
import { projectNeed, projectDescription, projectPhase } from "./data";

interface Props {
  projectId: string;
  project: RootState["projects"]["project"];
  updateProjectAction: typeof updateProject;
  projectsProgressValue: number;
}

const Wrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 4rem;
`;

const ProjectTitle = styled(EditableTitle)`
  border-bottom: 1px dashed #b6ccc2;
`;

const Scope: React.FunctionComponent<Props> = (props) => {
  const {
    projectId,
    project,
    updateProjectAction,
    projectsProgressValue,
  } = props;

  if (!project) {
    return null;
  }

  return (
    <Wrapper>
      <Box direction="row" align="center" justify="between">
        <Breadcrumbs>
          <Anchor href="https://www.capacitycommons.org/">
            <FaHome size="16px" color="#238DC1" />
          </Anchor>
          <Anchor as={Link} to="/">
            My Projects
          </Anchor>
          <div>{project.name}</div>
        </Breadcrumbs>

        {projectsProgressValue === 100 && (
          <ShareButton
            prefix="scope"
            getShareId={async () => {
              let shareId = project.shareId;
              if (!shareId) {
                // project has not shareId, generate one!
                const response = await updateProjectAction(projectId, {
                  shareId: uuidv4(),
                });
                shareId = response.shareId;
              }
              return shareId;
            }}
          />
        )}
      </Box>

      <Box margin={{ vertical: "large" }}>
        <ProjectTitle
          onBlur={(_event, value) => {
            if (value && value !== project.name) {
              updateProjectAction(projectId, { name: value });
            }
          }}
        >
          {project.name!}
        </ProjectTitle>
      </Box>

      <QuestionGroup
        title="Project Need"
        questions={
          project.projectNeed && project.projectNeed.length > 0
            ? project.projectNeed
            : projectNeed
        }
        prefix="projectNeed"
      />

      <QuestionGroup
        title="Project Description"
        questions={
          project.projectDescription && project.projectDescription.length > 0
            ? project.projectDescription
            : projectDescription
        }
        prefix="projectDescription"
      />

      <QuestionGroupArray
        title="Project Phases"
        baseQuestions={projectPhase}
        questions={
          project.projectPhases && project.projectPhases.length > 0
            ? project.projectPhases
            : undefined
        }
        prefix="projectPhases"
      />

      <SkillsNeeded />

      <ProjectDuration />
    </Wrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  project: state.projects.project,
  projectsProgressValue: selectProjectsProgressValue(state),
});

const mapDispatchToProps = {
  updateProjectAction: updateProject,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps as any,
)(Scope);

import { Dispatch } from "redux";
import {
  getProject as getProjectApi,
  getProjects as getProjectsApi,
  updateProject as updateProjectApi,
} from "api/projects";
import { CALCULATOR_LOAD } from "calculator/src/reducers";
import { Project } from "../typings";

export const PROJECTS_LOAD = "PROJECTS_LOAD";
export const PROJECTS_UNLOAD = "PROJECTS_UNLOAD";
export const PROJECT_LOAD = "PROJECT_LOAD";
export const PROJECT_UNLOAD = "PROJECT_UNLOAD";
export const PROJECT_LOADING = "PROJECT_LOADING";
export const MATCH_PARTNERS = "MATCH_PARTNERS";

export const updateProject = (id: string, payload: Project): Project => async (
  dispatch: Dispatch,
) => {
  try {
    const response = await updateProjectApi(id, payload);
    const { data } = response.data;
    dispatch({
      type: PROJECT_LOAD,
      payload: data,
    });
    return data;
  } catch (error) {
    console.log(`Error updating project: ${error}`);
  }
};

export const setProjectLoading = (isLoading: boolean) => ({
  type: PROJECT_LOADING,
  payload: isLoading,
});

export const loadProject = (id: string) => async (dispatch: Dispatch) => {
  dispatch(setProjectLoading(true));
  try {
    const response = await getProjectApi(id);
    const { data } = response.data;
    if (data.calculator) {
      dispatch({
        type: CALCULATOR_LOAD,
        payload: data.calculator,
      });
    }
    dispatch({
      type: PROJECT_LOAD,
      payload: data,
    });
    dispatch(setProjectLoading(false));
  } catch (error) {
    dispatch(setProjectLoading(false));
    console.log(`Error loading project: ${error}`);
  }
};

export const unloadProject = () => ({
  type: PROJECT_UNLOAD,
});

export const loadProjects = () => async (dispatch: Dispatch) => {
  try {
    const response = await getProjectsApi();
    const { data } = response.data;
    dispatch({
      type: PROJECTS_LOAD,
      payload: data,
    });
    return data;
  } catch (error) {
    console.log(`Error loading projects: ${error}`);
  }
};

export const unloadProjects = () => ({
  type: PROJECTS_UNLOAD,
});

export type Action =
  | { type: typeof PROJECTS_LOAD; payload: Project[] }
  | { type: typeof PROJECTS_UNLOAD }
  | { type: typeof PROJECT_LOAD; payload: Project }
  | { type: typeof PROJECT_UNLOAD }
  | { type: typeof PROJECT_LOADING; payload: boolean };

export interface State {
  projects: Project[];
  projectsLoaded: boolean;
  project?: Project;
  isLoading: boolean;
}

const initialState: State = {
  projects: [],
  projectsLoaded: false,
  project: undefined,
  isLoading: true,
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case PROJECTS_LOAD:
      return {
        ...state,
        projects: action.payload,
        projectsLoaded: true,
      };
    case PROJECTS_UNLOAD:
      return {
        ...state,
        projects: [],
        projectsLoaded: false,
      };
    case PROJECT_LOAD:
      return {
        ...state,
        project: { ...state.project, ...action.payload },
      };
    case PROJECT_UNLOAD:
      return {
        ...state,
        project: undefined,
      };
    case PROJECT_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

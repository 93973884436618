import { useAsync } from "./useAsync";

export const useFetch = (
  asyncFn: (...args: any) => Promise<any>,
  ...args: any[]
) => {
  const { isLoading, result, error } = useAsync(asyncFn, ...args);

  let data;
  if (result && result.data && result.data.data) {
    data = result.data.data;
  }

  return {
    isLoading,
    data,
    error,
  };
};

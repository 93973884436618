import React from "react";
import { FieldArray, Field, FieldProps } from "formik";
import { Text, Box } from "grommet";
import {
  Header,
  CurrencyInput,
  AddFieldButton,
  TrashButton,
  ErrorMessage,
} from "../../components";
import {
  StyledLabel,
  StyledNumberInput,
  StyledTextInput,
} from "../../styles/Inputs";
import { Wrapper, RowBox } from "../../styles/Wrappers";
import {
  calculateTotalValues,
  calculateGroupAmount,
} from "@rr/utils/calculator/totals";
import fieldTypes from "../../data/fieldTypes";

interface Props {
  values?: {
    [key: string]: string;
  }[];
}

const StaffTime: React.FunctionComponent<Props> = React.memo((props) => {
  const { values } = props;
  return (
    <Wrapper>
      <Header
        data-testid="st-value"
        heading="Staff Time"
        size="medium"
        level={4}
        amount={calculateGroupAmount(values) || "$0.00"}
        tooltipContent="<em>The monetary value of the time your staff will need to invest in scoping and managing the engagement.</em>\n\nTake the average loaded cost of an employee hour (check in with your Executive Director or HR staff!) and multiply it by the estimated number of hours your team will likely spend on the project. <strong>Hint:</strong> Thinking about it on a per week basis at first can be helpful in getting to the total number of hours. On average,we estimate a staff member will contribute 2-5 hours per week on a pro bono project."
      />
      <Text
        margin={{ vertical: "1rem" }}
        size="small"
        dangerouslySetInnerHTML={{
          __html:
            "Estimate the <u>number of hours per week</u> of anticipated time commitment from your staff, <u>number of weeks</u> the pro bono engagement will last and the estimated <u>loaded cost of that staff peron's hour</u> (check in with your Executive Director or HR staff).",
        }}
      />
      <FieldArray name="staffTime">
        {(arrayHelpers) => (
          <React.Fragment>
            {values &&
              values.length > 0 &&
              values.map((value, index) => (
                <div key={index}>
                  <RowBox>
                    <Box width="40%">
                      <StyledLabel
                        display={index !== 0 ? "none" : "flex"}
                        htmlFor="staffMember"
                      >
                        {"Staff Member"}
                      </StyledLabel>
                    </Box>
                    <Box width="15%">
                      <StyledLabel
                        display={index !== 0 ? "none" : "flex"}
                        htmlFor="hoursPerWeek"
                      >
                        {"Hours Per Week"}
                      </StyledLabel>
                    </Box>
                    <Box width="15%">
                      <StyledLabel
                        display={index !== 0 ? "none" : "flex"}
                        htmlFor="numberOfWeeks"
                      >
                        {"Number Of Weeks"}
                      </StyledLabel>
                    </Box>
                    <Box width="15%">
                      <StyledLabel
                        display={index !== 0 ? "none" : "flex"}
                        htmlFor="hourlyCost"
                      >
                        {"Hourly Cost"}
                      </StyledLabel>
                    </Box>
                    <Box width="15%">
                      <StyledLabel
                        display={index !== 0 ? "none" : "flex"}
                        htmlFor="totalValue"
                      >
                        {"Total Value"}
                      </StyledLabel>
                    </Box>
                  </RowBox>
                  <RowBox>
                    <TrashButton onClick={() => arrayHelpers.remove(index)} />
                    <Box width="40%" margin={{ right: ".35rem" }}>
                      <Field
                        name={`staffTime.${index}.staffMember`}
                        render={({ field }: FieldProps) => (
                          <StyledTextInput
                            {...field}
                            id="staffMember"
                            size="small"
                            type="text"
                          />
                        )}
                      />
                      <ErrorMessage name={`staffTime.${index}.staffMember`} />
                    </Box>
                    <Box width="15%" margin={{ right: ".35rem" }}>
                      <Field
                        name={`staffTime.${index}.hoursPerWeek`}
                        render={({ field }: FieldProps) => (
                          <StyledNumberInput
                            {...field}
                            id="hoursPerWeek"
                            placeholder="0"
                            type="number"
                            onBlur={(event) => {
                              field.onBlur(event);
                              arrayHelpers.replace(index, {
                                ...value,
                                totalValue: calculateTotalValues(
                                  "staffTime",
                                  value,
                                ),
                              });
                            }}
                          />
                        )}
                      />
                      <ErrorMessage name={`staffTime.${index}.hoursPerWeek`} />
                    </Box>
                    <Box width="15%" margin={{ right: ".35rem" }}>
                      <Field
                        name={`staffTime.${index}.numberOfWeeks`}
                        render={({ field }: FieldProps) => (
                          <StyledNumberInput
                            {...field}
                            id="numberOfWeeks"
                            placeholder="0"
                            size="small"
                            type="number"
                            onBlur={(event) => {
                              field.onBlur(event);
                              arrayHelpers.replace(index, {
                                ...value,
                                totalValue: calculateTotalValues(
                                  "staffTime",
                                  value,
                                ),
                              });
                            }}
                          />
                        )}
                      />
                      <ErrorMessage name={`staffTime.${index}.numberOfWeeks`} />
                    </Box>
                    <Box width="15%" margin={{ right: ".35rem" }}>
                      <Field
                        name={`staffTime.${index}.hourlyCost`}
                        render={({ field }: FieldProps) => (
                          <CurrencyInput
                            {...field}
                            id="hourlyCost"
                            placeholder="0.00"
                            onBlur={(event) => {
                              field.onBlur(event);
                              arrayHelpers.replace(index, {
                                ...value,
                                totalValue: calculateTotalValues(
                                  "staffTime",
                                  value,
                                ),
                              });
                            }}
                            onStateChange={(currency) => {
                              arrayHelpers.replace(index, {
                                ...value,
                                hourlyCost: currency,
                              });
                            }}
                          />
                        )}
                      />
                      <ErrorMessage name={`staffTime.${index}.hourlyCost`} />
                    </Box>
                    <Box width="15%">
                      <Field
                        name={`staffTime.${index}.totalValue`}
                        render={({ field }: FieldProps) => (
                          <StyledNumberInput
                            {...field}
                            id="totalValue"
                            placeholder="0.00"
                            type="number"
                            size="small"
                            disabled={true}
                          />
                        )}
                      />
                    </Box>
                  </RowBox>
                </div>
              ))}
            {values && (
              <AddFieldButton
                onClick={() =>
                  arrayHelpers.push({
                    ...fieldTypes["staffTime"],
                    staffMember: `Staff Member ${values.length + 1}`,
                  })
                }
                text="Add a staff member"
              />
            )}
          </React.Fragment>
        )}
      </FieldArray>
    </Wrapper>
  );
});

StaffTime.displayName = "StaffTime";

export default StaffTime;

import React from "react";
import { Link } from "@reach/router";
import { Box, Text } from "grommet";
import { Anchor } from "shared-ui";

interface Props {
  path: string;
  title: string;
  order: number;
  render?: Function;
  subtitle?: string;
}

const Step: React.FunctionComponent<Props> = ({
  path,
  title,
  subtitle,
  order,
  render,
}) => (
  <Anchor
    as={Link}
    to={path}
    getProps={({ isPartiallyCurrent }: { isPartiallyCurrent: boolean }) => ({
      style: {
        backgroundColor: isPartiallyCurrent ? "#1C3A48" : "",
        color: isPartiallyCurrent ? "white" : "#1C3A48",
        display: isPartiallyCurrent ? "block" : "",
      },
    })}
  >
    <Box pad="medium" border={{ side: "bottom", color: "#B7CCC2" }}>
      <Box direction="row" margin={{ bottom: "1rm" }} align="center">
        {order}
        <Text margin={{ horizontal: "small" }} weight="bold">
          {title}
        </Text>
      </Box>
      <Box pad={{ top: "small" }}>{render && render()}</Box>
      <Text size="small">{subtitle}</Text>
    </Box>
  </Anchor>
);

export default Step;

import * as React from "react";
import styled from "styled-components-themed";
import { Link } from "@reach/router";
import { Avatar, Anchor, media } from "shared-ui";
import { AuthService } from "authentication";
import { Button as ButtonBase } from "grommet";
import { FaSignInAlt, FaRegUser, FaBookOpen, FaRegFile } from "react-icons/fa";

interface Props {
  name: string;
  src?: string;
  alt?: string;
}

const Container = styled.div`
  position: relative;
  display: flex;
`;

const Dropdown = styled.div`
  position: absolute;
  right: 0;
  margin-top: 3.75rem;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  z-index: 1;
  border: 0.125rem solid #adc5ba;
`;

const Button = styled(ButtonBase)<{ borderBottom?: string }>`
  display: flex;
  border-bottom: ${(props) => props.borderBottom};
  padding: 1rem;
  color: ${(props) => props.theme.colors["darkText"]};
  white-space: nowrap;
  width: 100%;
`;

const MobileMenu = styled.div`
  display: block;

  ${media.large`
    display: none;
  `}
`;

const NavProfile: React.FunctionComponent<Props> = ({ src, alt, name }) => {
  const [dropdown, setDropdown] = React.useState(false);
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const handleOuterClick = ({ target }: MouseEvent) => {
    const node = dropdownRef && dropdownRef.current;
    if (node && !node.contains(target as HTMLElement)) {
      setDropdown(false);
    }
  };
  React.useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleOuterClick);
    // return function to clean up mouse event listener
    return () => {
      document.removeEventListener("mousedown", handleOuterClick);
    };
  }, []);

  return (
    <Container
      data-testid="nav-profile"
      ref={dropdownRef}
      onClick={() => setDropdown((dropdown) => !dropdown)}
    >
      <Avatar src={src} alt={alt} name={name} />
      {dropdown && (
        <Dropdown>
          <MobileMenu>
            <Anchor
              href={`${
                process.env.WP_URL
              }/guidebook/learn/about-skills-based-volunteerism/`}
              style={{ textDecoration: "none" }}
            >
              <Button
                borderBottom="2px solid #D6E2DC"
                plain
                icon={<FaBookOpen size="16px" />}
                label="Guidebook"
              />
            </Anchor>
            <Anchor
              as={process.env.APP_URL ? "a" : Link}
              href={`${process.env.APP_URL}/`}
            >
              <Button
                borderBottom="2px solid #D6E2DC"
                plain
                icon={<FaRegFile size="16px" />}
                label="My Projects"
              />
            </Anchor>
          </MobileMenu>
          <Anchor
            as={process.env.APP_URL ? "a" : Link}
            href={`${process.env.APP_URL}/account`}
          >
            <Button
              borderBottom="2px solid #D6E2DC"
              plain
              icon={<FaRegUser size="16px" />}
              label="My Account"
            />
          </Anchor>
          <Button
            data-testid="logout"
            onClick={AuthService.logout}
            plain
            icon={<FaSignInAlt size="16px" />}
            label="Log Out"
          />
        </Dropdown>
      )}
    </Container>
  );
};

export default NavProfile;

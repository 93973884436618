import React from "react";
import { RouteComponentProps } from "@reach/router";

interface Props extends RouteComponentProps {
  component: React.FunctionComponent<any> | React.ComponentClass<any>;
  auth?: any;
}

const Route: React.FunctionComponent<Props> = ({
  component: Component,
  ...rest
}) => <Component {...rest} />;

export default Route;

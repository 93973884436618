import React from "react";
import styled from "styled-components-themed";
import { generateArray } from "@rr/utils";
import Step from "./Step";

interface Props {
  total: number;
  current: number;
}

const Wrapper = styled.div`
  display: flex;
`;

const StyledStep = styled(Step)`
  position: relative;
  margin-left: 1rem;

  &:first-child {
    margin: 0;
    &:before {
      content: none;
    }
  }

  &:before {
    content: "";
    height: 0.125rem;
    width: 1rem;
    position: absolute;
    top: calc(1.25rem / 2 - 1px);
    right: 100%;
    background: ${(props) =>
      props.isActive ? "rgb(0,177,137)" : "rgb(182,204,194)"};
    transition: background 0.2s;
  }
`;

const Steps: React.FunctionComponent<Props> = (props) => {
  const { total: totalProp, current, ...restProps } = props;
  const total = React.useMemo(() => generateArray(totalProp), [totalProp]);

  return (
    <Wrapper {...restProps}>
      {total.map((n) => {
        const num = n + 1;
        return (
          <StyledStep key={num} isActive={num <= current}>
            {num}
          </StyledStep>
        );
      })}
    </Wrapper>
  );
};

export default Steps;

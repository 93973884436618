const organizationTypes = [
  "Nonprofit (U.S. Based)",
  "Non-U.S. Based NGO",
  "Social Enterprise",
  "School",
  "Government Agency",
  "Other",
];

export default organizationTypes;

import { combineReducers } from "redux";
import { organizationsReducer } from "organization";
import { projectsReducer } from "projects";
import appReducer from "./app";
import sessionReducer from "./session";
import { calculatorReducer } from "calculator";

const rootReducer = combineReducers({
  app: appReducer,
  session: sessionReducer,
  calculator: calculatorReducer,
  organizations: organizationsReducer,
  projects: projectsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

import * as Yup from "yup";

export const stringRequired = Yup.string().required("Required*");

export const consultantDescriptionSchema = Yup.object().shape({
  consultantDescription: stringRequired,
  hourlyRate: stringRequired,
  totalHours: stringRequired,
  totalValue: stringRequired,
});

export const productDescriptionSchema = Yup.object().shape({
  productDescription: stringRequired,
  totalValue: stringRequired,
});

export const descriptionSchema = Yup.object().shape({
  description: stringRequired,
  totalValue: stringRequired,
});

export const staffMemberSchema = Yup.object().shape({
  staffMember: stringRequired,
  hoursPerWeek: stringRequired,
  numberOfWeeks: stringRequired,
  hourlyCost: stringRequired,
  totalValue: stringRequired,
});

export const descriptionOfOpportunitySchema = Yup.object().shape({
  descriptionOfOpportunity: stringRequired,
  totalValue: stringRequired,
});

export const unquantifiableValueSchema = Yup.object().shape({
  description: stringRequired,
  valueWeight: stringRequired,
});

export const CalculatorSchema = Yup.object().shape({
  monetaryValueOfConsultants: Yup.array().of(consultantDescriptionSchema),
  monetaryValueOfProducts: Yup.array().of(productDescriptionSchema),
  staffTime: Yup.array().of(staffMemberSchema),
});

import React from "react";
import { Grommet, RadioButton } from "grommet";
import { deepMerge } from "grommet/utils";
import { theme } from "../../styles/theme";
import RadioGroupContext from "./RadioGroupContext";

// TODO: Refactor; move to global theme
const customTheme = deepMerge(theme, {
  global: {
    colors: {
      brand: "#01B189",
    },
  },
  radioButton: {
    size: "1.25rem",
    hover: {
      border: {
        color: "#01B189",
      },
    },
    check: {
      color: {
        light: "#01B189",
      },
    },
  },
});

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  value: string;
  id?: string;
  label?: string;
  name?: string;
  checked?: boolean;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
}

const Radio: React.FunctionComponent<Props> = (props) => {
  const {
    id,
    value,
    label,
    name: nameProp,
    checked: checkedProp,
    onChange: onChangeProp,
    ...restProps
  } = props;
  const radioGroup = React.useContext(RadioGroupContext);

  let name = nameProp;
  let checked = checkedProp;
  let onChange = onChangeProp;

  if (radioGroup) {
    name = radioGroup.name;
    checked = radioGroup.value === value;
    onChange = (event: React.FormEvent<HTMLInputElement>) => {
      radioGroup.onChange(event);
      onChangeProp && onChangeProp(event);
    };
  }

  return (
    <Grommet theme={customTheme}>
      <div {...restProps}>
        <RadioButton
          label={label || value}
          name={name || ""}
          value={value}
          checked={checked}
          onChange={onChange}
        />
      </div>
    </Grommet>
  );
};

export default Radio;

import * as React from "react";
import { Link } from "@reach/router";
import styled from "styled-components-themed";
import { Anchor as AnchorBase } from "shared-ui";

interface Props {
  amount?: string;
  href: string;
  title: string;
  match?: boolean;
}

const Badge = styled.div`
  border-radius: 50%;
  height: 1.25rem;
  width: 1.25rem;
  background-color: #f49c20;
  display: flex;
  align-items: center;
  margin-right: 0.25rem;
  justify-content: center;
  color: #fff;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1rem;
  align-items: center;
`;

const Anchor = styled(({ href, ...restProps }) => (
  <AnchorBase
    as={process.env.APP_URL ? "a" : Link}
    href={href}
    {...restProps}
  />
))`
  border-top: 0.375rem solid transparent;
  border-bottom: 0.375rem solid transparent;
  font-weight: bold;

  &:hover {
    text-decoration: none;
  }

  .active & {
    color: #00b189;
    border-bottom-color: #00b189;
  }
`;

const NavItem: React.FunctionComponent<Props> = (props) => {
  const { amount, title, href, match } = props;
  return (
    <Wrapper className={match ? "active" : undefined}>
      {amount && <Badge>{amount}</Badge>}
      <Anchor href={href}>{title}</Anchor>
    </Wrapper>
  );
};

export default NavItem;

import styled, { css } from "styled-components-themed";

interface Props {
  checked?: boolean;
}

const Tag = styled.div<Props>`
  background-color: #fff;
  border: ${(props) => props.theme.colors.blue} solid 2px;
  color: ${(props) => props.theme.colors.blue};

  padding: 0.125rem 0.9375rem;
  border-radius: 1.25rem;
  box-shadow: 0px 0.125rem #f1f1f1;
  cursor: pointer;
  margin: 0.25rem;
  display: inline;

  ${(props) =>
    props.checked &&
    css`
      background: #00b189;
      border-color: #00b189;
      color: #fff;
    `}
`;

export default Tag;

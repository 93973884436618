export const colors = {
  // Colors
  white: "#FFFFFF",
  liteGrey: "#F9F9F9",
  blue: "#238DC1",
  lightBlue: "#448cbc",
  mediumBlue: "#3a7399",
  red: "#ff3f00",

  // Text
  liteText: "#DDDDDD",
  text: "#999999",
  darkText: "#5c6e75",

  // Backgrounds
  darkBackground: "#1E1F20",

  // Border
  border: "#CCCCCC",
  assessmentBorder: "#B6CCC2",

  // Status
  success: "#2AD955",
  error: "#FF6D26",
};

export const fonts = {
  base:
    '"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue"',
  title:
    '"Istok Web", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue"',
};

export const sizes: { [key: string]: number } = {
  xlarge: 1280,
  large: 1024,
  medium: 768,
  small: 568,
};

export interface StyledTheme {
  colors: { [key in keyof typeof colors]: string };
  fonts: { [key in keyof typeof fonts]: string };
  sizes: { [key in keyof typeof sizes]: number };
  [key: string]: any;
}

const baseSpacing = 24;

export const theme: StyledTheme = {
  colors,
  fonts,
  sizes,
  global: {
    colors: {
      brand: "#238DC1",
    },
    font: {
      size: "16px",
    },
    breakpoints: {
      medium: {
        value: baseSpacing * 42.625, // 1023
        borderSize: {
          xsmall: "1px",
          small: "2px",
          medium: `${baseSpacing / 6}px`, // 4
          large: `${baseSpacing / 4}px`, // 6
          xlarge: `${baseSpacing / 2}px`, // 12
        },
        edgeSize: {
          none: "0px",
          hair: "1px", // for Chart
          xxsmall: "2px",
          xsmall: `${baseSpacing / 8}px`, // 3
          small: `${baseSpacing / 4}px`, // 6
          medium: `${baseSpacing / 2}px`, // 12
          large: `${baseSpacing}px`, // 24
          xlarge: `${baseSpacing * 2}px`, // 48
        },
        size: {
          xxsmall: `${baseSpacing}px`, // 24
          xsmall: `${baseSpacing * 2}px`, // 48
          small: `${baseSpacing * 4}px`, // 96
          medium: `${baseSpacing * 8}px`, // 192
          large: `${baseSpacing * 16}px`, // 384
          xlarge: `${baseSpacing * 32}px`, // 768
          full: "100%",
        },
      },
    },
    drop: {
      zIndex: 1000,
    },
  },
  heading: {
    font: {
      family: fonts.title,
    },
  },
  box: {
    responsiveBreakpoint: "medium",
  },
  layer: {
    zIndex: 1000,
  },
  select: {
    control: {
      extend: `
        width: 100%;
      `,
    },
  },
  button: {
    border: {
      radius: "0.25rem",
    },
    padding: {
      vertical: "0.75rem",
      horizontal: "1.5rem",
    },
    color: { dark: "#FFF", light: "#238DC1" },
    extend: `
      font-size: 1.125rem;
      font-weight: 600;
    `,
  },
};

import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import { Box, Layer, Text, Button } from "grommet";
import { MdClose } from "react-icons/md";
import { RootState } from "frontend-app/typings";
import { setNotification } from "../../reducers/app";

interface Props {
  notification: string;
  setNotificationAction: typeof setNotification;
}

const Notification: React.FunctionComponent<Props> = (props) => {
  const { notification, setNotificationAction } = props;

  React.useEffect(() => {
    const notificationTimer = notification
      ? setTimeout(() => setNotificationAction(""), 3000)
      : undefined;

    return () => {
      clearTimeout(notificationTimer);
    };
  }, [notification]);

  return (
    <Layer
      position="bottom"
      modal={false}
      margin={{ vertical: "large" }}
      onEsc={() => {
        setNotificationAction("");
      }}
      responsive={false}
      plain
    >
      <Box
        align="center"
        direction="row"
        gap="small"
        justify="between"
        round="medium"
        elevation="medium"
        pad="small"
        background="status-ok"
        data-testid="notification"
      >
        <Box
          align="center"
          direction="row"
          gap="xsmall"
          margin={{ horizontal: "xsmall" }}
        >
          <Text>{notification}</Text>
        </Box>
        <Button
          icon={<MdClose size="20px" />}
          plain
          onClick={() => {
            setNotificationAction("");
          }}
        />
      </Box>
    </Layer>
  );
};

const mapStateToProps = (state: RootState) => ({
  notification: state.app.notification,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setNotificationAction: setNotification,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notification);

import * as React from "react";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import styled from "styled-components-themed";
import { Box, Button, Heading as HeadingBase } from "grommet";
import { FaPencilAlt, FaRegTrashAlt } from "react-icons/fa";
import { MdAddCircle } from "react-icons/md";
import { Formik, Form, Field, FieldProps, FieldArray } from "formik";
import { RootState } from "frontend-app/typings";
import uniqueId from "@rr/utils/uniqueId";
import { updateProject } from "../../../reducer";
import { QA } from "../../../../typings";
import Question from "./Question";
import { Header, Heading, StyledEditButton, P } from "../styles";

interface Props {
  title: string;
  prefix: string;
  baseQuestions: QA[];
  questions?: QA[][];
  project: RootState["projects"]["project"];
  updateProjectAction: typeof updateProject;
}

const SubHeading = styled(HeadingBase)`
  max-width: none;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid #b6ccc2;
  font-size: 1.3125rem;
  font-family: ${(props) => props.theme.fonts.base};
`;

const PhaseHeader = styled.div`
  position: relative;
  margin-top: 2.5rem;
`;

const TrashButton = styled(Button)`
  position: absolute;
  top: -0.4rem;
  left: -2.25rem;
`;

const updateArray = (
  arr: any[],
  index: number,
  updater: { [key: string]: any },
) =>
  arr.map((item, itemIndex) => {
    if (itemIndex === index) {
      return { ...item, ...updater };
    }
    return item;
  });

const QuestionGroupArray: React.FunctionComponent<Props> = (props) => {
  const {
    title,
    prefix,
    baseQuestions,
    questions,
    project,
    updateProjectAction,
  } = props;

  if (!project) {
    return null;
  }

  const defaultPhases = [
    // Attach `id` to remove correct phase
    {
      id: uniqueId("phase"),
      questions: updateArray(baseQuestions, 0, { answer: "Discovery" }),
    },
    {
      id: uniqueId("phase"),
      questions: updateArray(baseQuestions, 0, { answer: "Design" }),
    },
    {
      id: uniqueId("phase"),
      questions: updateArray(baseQuestions, 0, {
        answer: "Implementation",
      }),
    },
  ];

  const updatedQuestionsWithId =
    questions &&
    questions.length > 0 &&
    questions.reduce(
      (acc, q) => [...acc, { id: uniqueId("phase"), questions: q }],
      [] as { id: string; questions: any[] }[],
    );

  const [showEditMode, setShowEditMode] = React.useState(
    !updatedQuestionsWithId,
  );

  // TODO: Should be getting this from API somewhere..
  const phases = updatedQuestionsWithId || defaultPhases;

  return (
    <Box margin={{ vertical: "medium" }}>
      <Header>
        <Heading level={3}>{title}</Heading>
        {!showEditMode && (
          <StyledEditButton
            color=" #238DC1"
            plain={true}
            icon={<FaPencilAlt size="14px" color=" #238DC1" />}
            gap="xxsmall"
            label="Edit"
            onClick={() => {
              setShowEditMode(true);
            }}
          />
        )}
      </Header>

      {showEditMode ? (
        <Formik
          initialValues={{
            projectPhases: phases,
          }}
          onSubmit={async (values, actions) => {
            await updateProjectAction(project._id!, {
              projectPhases: values.projectPhases.reduce(
                (acc, phase) => [...acc, [...phase.questions]],
                [] as QA[][],
              ),
            });
            setShowEditMode(false);
            actions.setSubmitting(false);
          }}
        >
          {(formikProps) => {
            const { values, isSubmitting } = formikProps;
            const formStateHasAnswers = values.projectPhases.every((phase) =>
              phase.questions.every((question) => question.answer !== ""),
            );

            return (
              <Form>
                <FieldArray name="projectPhases">
                  {(arrayHelpers) => (
                    <React.Fragment>
                      {values.projectPhases &&
                        values.projectPhases.length > 0 &&
                        values.projectPhases.map((phase, phaseIndex) => (
                          <Box key={phase.id} margin={{ left: "2rem" }}>
                            <PhaseHeader>
                              <TrashButton
                                onClick={() => arrayHelpers.remove(phaseIndex)}
                                icon={
                                  <FaRegTrashAlt color="#448CBC" size="14px" />
                                }
                              />
                              <SubHeading level={4}>
                                Phase {phaseIndex + 1}{" "}
                                {phase.questions[0].answer
                                  ? `- ${phase.questions[0].answer}`
                                  : ""}
                              </SubHeading>
                            </PhaseHeader>
                            {phase.questions.map((item, questionIndex) => {
                              return (
                                <div key={questionIndex}>
                                  <Field
                                    name={`projectPhases.${phaseIndex}.questions.${questionIndex}.answer`}
                                    render={({ field, form }: FieldProps) => (
                                      <Question
                                        {...field}
                                        {...item}
                                        form={form}
                                        prefix={prefix + phaseIndex}
                                        index={questionIndex}
                                      />
                                    )}
                                  />
                                </div>
                              );
                            })}
                          </Box>
                        ))}
                    </React.Fragment>
                  )}
                </FieldArray>
                <Box
                  align="center"
                  justify="between"
                  direction="row"
                  margin={{ vertical: "medium", left: "2rem" }}
                >
                  <FieldArray name="projectPhases">
                    {(arrayHelpers) => (
                      <Button
                        color=" #238DC1"
                        plain={true}
                        icon={<MdAddCircle color=" #238DC1" size="16px" />}
                        gap="xsmall"
                        label="Add a phase"
                        onClick={() =>
                          arrayHelpers.push({
                            id: uniqueId("phase"),
                            questions: baseQuestions,
                          })
                        }
                      />
                    )}
                  </FieldArray>
                  <Button
                    type="submit"
                    primary={true}
                    color="#228DC1"
                    label="Done"
                    disabled={isSubmitting || !formStateHasAnswers}
                  />
                </Box>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <React.Fragment>
          {phases.map((phase, phaseIndex) => (
            <Box key={phase.id} margin={{ left: "2rem" }}>
              <PhaseHeader>
                <SubHeading level={4}>
                  Phase {phaseIndex + 1}{" "}
                  {phase.questions[0].answer
                    ? `- ${phase.questions[0].answer}`
                    : ""}
                </SubHeading>
              </PhaseHeader>
              <Box margin={{ top: "small" }}>
                <P>{phase.questions[1].answer}</P>
                <P>
                  <em>Milestone:</em> {phase.questions[2].answer}
                </P>
              </Box>
            </Box>
          ))}
        </React.Fragment>
      )}
    </Box>
  );
};

const mapStateToProps = (state: RootState) => ({
  project: state.projects.project,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ updateProjectAction: updateProject }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuestionGroupArray);

import React from "react";
import { Text, Box } from "grommet";
import { FieldArray, Field, FieldProps } from "formik";
import {
  Header,
  CurrencyInput,
  TrashButton,
  AddFieldButton,
  ErrorMessage,
} from "../../components";
import { StyledLabel, StyledTextInput } from "../../styles/Inputs";
import { calculateGroupAmount } from "@rr/utils/calculator/totals";
import { Wrapper, RowBox } from "../../styles/Wrappers";
import fieldTypes from "../../data/fieldTypes";

interface Props {
  values?: {
    [key: string]: string;
  }[];
}

const MonetaryValueOfProducts: React.FunctionComponent<Props> = React.memo(
  (props) => {
    const { values } = props;
    return (
      <Wrapper>
        <Header
          data-testid="mvp-value"
          heading="Monetary Value of Products"
          size="medium"
          level={4}
          amount={calculateGroupAmount(values) || "$0.00"}
          tooltipContent={`<em>The up-front, fixed retail value of the product you will implement.</em>\n\nThis will only apply to some products. Some products, such as Salesforce, as free for nonprofits so the "product value" would be zero, in addition to the hourly value you\'ve calculated above.`}
        />
        <Text
          margin={{ vertical: "1rem" }}
          size="small"
          dangerouslySetInnerHTML={{
            __html:
              'Input the <u>"off-the-shelf" value</u> of any products or deliverables you will receive as part of this project.',
          }}
        />
        <FieldArray name="monetaryValueOfProducts">
          {(arrayHelpers) => (
            <React.Fragment>
              {values &&
                values.length > 0 &&
                values.map((value, index) => (
                  <div key={index}>
                    <RowBox>
                      <Box width="80%">
                        <StyledLabel
                          display={index !== 0 ? "none" : "flex"}
                          htmlFor="productDescription"
                        >
                          {"Product Description (e.g. Salesforce)"}
                        </StyledLabel>
                      </Box>
                      <Box width="20%">
                        <StyledLabel
                          display={index !== 0 ? "none" : "flex"}
                          htmlFor="totalValue"
                        >
                          {"Total Value"}
                        </StyledLabel>
                      </Box>
                    </RowBox>
                    <RowBox>
                      <TrashButton onClick={() => arrayHelpers.remove(index)} />
                      <Box width="80%" margin={{ right: ".35rem" }}>
                        <Field
                          name={`monetaryValueOfProducts.${index}.productDescription`}
                          render={({ field }: FieldProps) => (
                            <StyledTextInput
                              {...field}
                              id="productDescription"
                              size="small"
                              type="text"
                            />
                          )}
                        />
                        <ErrorMessage
                          name={`monetaryValueOfProducts.${index}.productDescription`}
                        />
                      </Box>
                      <Box width="20%">
                        <Field
                          name={`monetaryValueOfProducts.${index}.totalValue`}
                          render={({ field }: FieldProps) => (
                            <CurrencyInput
                              {...field}
                              id="totalValue"
                              placeholder="0.00"
                              onStateChange={(currency) => {
                                arrayHelpers.replace(index, {
                                  ...value,
                                  totalValue: currency,
                                });
                              }}
                            />
                          )}
                        />
                        <ErrorMessage
                          name={`monetaryValueOfProducts.${index}.totalValue`}
                        />
                      </Box>
                    </RowBox>
                  </div>
                ))}
              {values && (
                <AddFieldButton
                  onClick={() =>
                    arrayHelpers.push({
                      ...fieldTypes["monetaryValueOfProducts"],
                      productDescription: `Product ${values.length + 1}`,
                    })
                  }
                  text="Add a product"
                />
              )}
            </React.Fragment>
          )}
        </FieldArray>
      </Wrapper>
    );
  },
);

MonetaryValueOfProducts.displayName = "MonetaryValueOfProducts";

export default MonetaryValueOfProducts;

import * as React from "react";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import { Box, Button } from "grommet";
import { FaPencilAlt } from "react-icons/fa";
import { RootState } from "frontend-app/typings";
import { Tags } from "shared-ui";
import { updateProject } from "../../../reducer";
import { softSkills, hardSkills } from "@rr/utils/projects/skills";
import {
  Header,
  Heading,
  StyledEditButton,
  TextInput,
  Label,
  P,
} from "../styles";

const SkillsGroup: React.FunctionComponent<{
  label: string;
  skills: string[];
  initialChecked: string[];
  onSelect: (checked: any[], tag: string) => void;
}> = (props) => {
  const { label, skills, initialChecked, onSelect } = props;
  return (
    <Box margin={{ vertical: "small" }}>
      <Label dangerouslySetInnerHTML={{ __html: label }} />
      <Tags
        items={skills}
        initialChecked={initialChecked}
        onStateChange={({ checkedTags, tag }) => {
          onSelect(checkedTags, tag);
        }}
      />
    </Box>
  );
};

interface Props {
  initialSelectedAreas?: (keyof typeof hardSkills)[];
  initialSelectedHardSkills?: {
    [key: string]: string[];
  };
  initialSelectedSoftSkills?: string[];
  initialOtherSkills?: string;
  project: RootState["projects"]["project"];
  updateProjectAction: typeof updateProject;
}

const SkillsNeeded: React.FunctionComponent<Props> = (props) => {
  const {
    initialSelectedAreas = [],
    initialSelectedHardSkills = {},
    initialSelectedSoftSkills = [],
    initialOtherSkills = "",
    project,
    updateProjectAction,
  } = props;
  const [selectedAreas, setSelectedAreas] = React.useState(
    initialSelectedAreas,
  );
  const [selectedHardSkills, setSelectedHardSkills] = React.useState(
    initialSelectedHardSkills,
  );
  const [selectedSoftSkills, setSelectedSoftSkills] = React.useState(
    initialSelectedSoftSkills,
  );
  const [otherSkills, setOtherSkills] = React.useState(initialOtherSkills);

  const hasSelectedAreas = selectedAreas.length > 0;
  const hasSelectedHardSkills = Object.keys(selectedHardSkills).every(
    (area) => selectedHardSkills[area] && selectedHardSkills[area].length > 0,
  );
  const hasAnswers = hasSelectedAreas && hasSelectedHardSkills;
  const [showEditMode, setShowEditMode] = React.useState(!hasAnswers);

  return (
    <Box margin={{ vertical: "small" }}>
      <Header>
        <Heading level={3}>Skills Needed</Heading>
        {!showEditMode && (
          <StyledEditButton
            color=" #238DC1"
            plain={true}
            icon={<FaPencilAlt size="14px" color=" #238DC1" />}
            gap="xxsmall"
            label="Edit"
            onClick={() => {
              setShowEditMode(true);
            }}
          />
        )}
      </Header>

      {showEditMode ? (
        <React.Fragment>
          <Box margin={{ top: "small" }}>
            <Label>
              What <strong>functional skills</strong> would someone need to
              complete this project?
            </Label>
          </Box>

          <SkillsGroup
            label="1. Select your areas of need:"
            skills={Object.keys(hardSkills)}
            initialChecked={selectedAreas}
            onSelect={(selected: (keyof typeof hardSkills)[], tag: any) => {
              setSelectedAreas(selected);
              if (!selected.includes(tag)) {
                const cloneSelectedHardSkills: any = { ...selectedHardSkills };
                delete cloneSelectedHardSkills[tag];
                setSelectedHardSkills(cloneSelectedHardSkills);
              }
            }}
          />

          {selectedAreas.map((area, index) => (
            <SkillsGroup
              key={area}
              label={`${index +
                2}. Select the functional skills you need for <strong>${area}</strong>:`}
              skills={hardSkills[area]}
              initialChecked={selectedHardSkills[area]}
              onSelect={(selected: string[]) =>
                setSelectedHardSkills({
                  ...selectedHardSkills,
                  [area]: selected,
                })
              }
            />
          ))}

          <SkillsGroup
            label="What <strong>cross-functional skills</strong> would someone need to complete this project?"
            skills={softSkills}
            initialChecked={selectedSoftSkills}
            onSelect={(selected: string[]) => setSelectedSoftSkills(selected)}
          />

          <Label>
            What <strong>other skills</strong> would it be helpful for someone
            to have?
          </Label>
          <TextInput
            placeholder="Enter other skills separated by commas"
            value={otherSkills}
            onChange={(event) => {
              setOtherSkills(event.currentTarget.value);
            }}
          />

          <Box align="end" margin={{ vertical: "medium" }}>
            <Button
              type="submit"
              primary={true}
              color="#228DC1"
              label="Done"
              disabled={!hasAnswers}
              onClick={async () => {
                await updateProjectAction(project!._id!, {
                  skillsNeeded: {
                    selectedAreas,
                    selectedHardSkills,
                    selectedSoftSkills,
                    otherSkills,
                  },
                });
                setShowEditMode(false);
              }}
            />
          </Box>
        </React.Fragment>
      ) : (
        <Box margin={{ vertical: "medium" }}>
          {selectedAreas.length > 0 && (
            <P>
              <em>Areas of Need:</em> {selectedAreas.join(", ")}
            </P>
          )}
          {Object.keys(selectedHardSkills).length > 0 && (
            <P>
              <em>Functional Skills:</em>{" "}
              {Object.values(selectedHardSkills)
                .reduce((acc, cur) => [...acc, ...cur], [])
                .join(", ")}
            </P>
          )}
          {selectedSoftSkills.length > 0 && (
            <P>
              <em>Cross-Functional Skills:</em> {selectedSoftSkills.join(", ")}
            </P>
          )}
          {otherSkills && (
            <P>
              <em>Other Skills:</em> {otherSkills}
            </P>
          )}
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state: RootState) => {
  const project = state.projects.project;
  const hasSkills = !!(project && project.skillsNeeded);
  return {
    initialSelectedAreas: hasSkills ? project!.skillsNeeded!.selectedAreas : [],
    initialSelectedHardSkills: hasSkills
      ? project!.skillsNeeded!.selectedHardSkills
      : {},
    initialSelectedSoftSkills: hasSkills
      ? project!.skillsNeeded!.selectedSoftSkills
      : [],
    initialOtherSkills: hasSkills ? project!.skillsNeeded!.otherSkills : "",
    project: project,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ updateProjectAction: updateProject }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SkillsNeeded);

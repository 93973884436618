import * as React from "react";
import styled, { css } from "styled-components-themed";
import isBrowser from "@rr/utils/isBrowser";
import {
  Header as HeaderBase,
  Anchor as AnchorBase,
  Container,
  media,
} from "shared-ui";
import { Box, Text } from "grommet";
import Logo from "./Logo";
import Login, { DesktopMenu } from "./Login";

interface Props {
  heading?: string;
}

const Wrapper = styled(HeaderBase)`
  background-color: #f8fff8;
  padding: 2rem 0;
  position: relative;
  z-index: 999;
`;

const StyledContainer = styled(Container)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Anchor = styled(AnchorBase)<{ active?: boolean }>`
  ${(props) => css`
    color: ${props.theme.colors.lightBlue};
    border-top: 0.375rem solid transparent;
    border-bottom: 0.375rem solid transparent;
    font-weight: bold;

    ${props.active &&
      css`
        color: #00b189;
        border-bottom-color: #00b189;
      `}

    &:hover {
      text-decoration: none;
    }
  `}
`;

const StyledLogo = styled(Logo)`
  width: 75%;

  ${media.large`
    width: 100%;
  `}
`;

const Blurb = styled(Text)`
  display: none;
  font-size: 1rem;

  ${media.large`
    display: block;
  `}
`;

const Header: React.FunctionComponent<Props> = () => {
  return (
    <Wrapper>
      <StyledContainer>
        <Anchor href={process.env.WP_URL!}>
          <StyledLogo />
        </Anchor>
        <Blurb>Your one-stop shop for skills-based volunteerism.</Blurb>
        <Box direction="row" align="center">
          <DesktopMenu>
            <Anchor
              active={
                isBrowser && window.location.pathname.includes("/guidebook")
              }
              style={{ marginRight: "1rem" }}
              href={`${
                process.env.WP_URL
              }/guidebook/learn/about-skills-based-volunteerism/`}
            >
              Guidebook
            </Anchor>
          </DesktopMenu>
          <Login />
        </Box>
      </StyledContainer>
    </Wrapper>
  );
};

export default Header;

import * as React from "react";
import { getAssessments } from "api/assessments";
import { AssessmentType } from "../../../typings";
import reducer, {
  initialState as reducerInitialState,
  Action,
  State,
} from "./reducer";

const isBrowser = typeof window !== "undefined";

const persistState = false;

const assessmentState = isBrowser
  ? window.localStorage.getItem("assessment")
  : null;

const initialState: State =
  persistState && assessmentState !== null
    ? JSON.parse(assessmentState)
    : reducerInitialState;

export const AssessmentContext = React.createContext({
  state: initialState,
  dispatch: (() => {}) as React.Dispatch<Action>,
});

interface Props {
  parentId: string;
  type: AssessmentType;
}

const AssessmentStore: React.FunctionComponent<Props> = (props) => {
  const { children, parentId, type } = props;
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    parentId,
    type,
  });

  React.useEffect(() => {
    if (state.parentId) {
      (async () => {
        try {
          const response = await getAssessments(state.parentId);
          const { data } = response.data;
          if (data && data.length > 0) {
            dispatch({
              type: "LOAD_ASSESSMENT",
              payload: {
                ...data[0],
                type: data[0].onModel,
              },
            });
          }
          dispatch({
            type: "ASSESSMENT_LOADING",
            payload: false,
          });
        } catch (error) {
          console.log("Error: ", error);
        }
      })();
    }
  }, [state.parentId]);

  // React.useEffect(() => {
  //   if (persistState) {
  //     window.localStorage.setItem("assessment", JSON.stringify(state));
  //   }
  // }, [state.totalScore]);

  return (
    <AssessmentContext.Provider value={{ state, dispatch }}>
      {children}
    </AssessmentContext.Provider>
  );
};

export default AssessmentStore;

import React from "react";
import styled from "styled-components-themed";
import { Text, Button as ButtonBase } from "grommet";
import { MdAddCircle } from "react-icons/md";

interface Props {
  text: string;
  onClick: () => void;
}

export const Button = styled(ButtonBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0;
  margin-top: 1rem;
`;

export const AddCircleWrapper = styled.span`
  margin-right: 0.5rem;
  margin-top: 0.35rem;
`;

const AddFieldButton: React.FunctionComponent<Props> = (props) => {
  const { onClick, text } = props;
  return (
    <Button onClick={onClick}>
      <MdAddCircle color="#448cbc" size="16px" />
      <Text margin={{ left: ".5rem" }} size="small" color="#448cbc">
        {text}
      </Text>
    </Button>
  );
};

export default AddFieldButton;

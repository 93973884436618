import styled from "styled-components-themed";
import {
  Button,
  Heading as HeadingBase,
  TextInput as TextInputBase,
} from "grommet";

export const Header = styled.div`
  position: relative;
`;

export const Heading = styled(HeadingBase)`
  max-width: none;
  border-bottom: 1px solid #b6ccc2;
`;

export const StyledEditButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1rem;
`;

export const Label = styled.p`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-style: italic;
`;

export const TextInput = styled(TextInputBase)`
  font-weight: 400;
`;

export const P = styled.p`
  margin-bottom: 1rem;

  em {
    font-style: normal;
    text-decoration: underline;
  }
`;

import styled from "styled-components-themed";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0.75rem 0;
`;

export const RowBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: start;
  position: relative;
`;

import React from "react";
import styled from "styled-components-themed";
import { Box, Heading, Text } from "grommet";
import { displayUSD } from "@rr/utils";
import { calculateGroupAmount } from "@rr/utils/calculator/totals";

interface Props {
  header: string;
  sign?: string;
  items: {
    [key: string]: string;
  }[];
}

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 0.125rem solid #ebebeb;
`;

const Group: React.FunctionComponent<Props> = (props) => {
  const { header, items, sign = "" } = props;
  return (
    <Box margin={{ bottom: "2.5rem" }}>
      <HeaderWrapper>
        <Heading
          level={4}
          size="medium"
          color="#2d4a58"
          margin={{ right: "xsmall" }}
        >
          {header}
        </Heading>
        <Heading level={4} size="medium" color="#2d4a58">
          {`${sign} ${displayUSD(calculateGroupAmount(items))}`}
        </Heading>
      </HeaderWrapper>
      {items &&
        items.length > 0 &&
        items.map((item, i) =>
          item.totalValue ? (
            <HeaderWrapper key={i}>
              <Text as="p" size="small" color="#2d4a58">
                {item[Object.keys(item)[0]]}
              </Text>
              <Text as="p" size="small" color="#2d4a58">
                {`${sign} ${displayUSD(item.totalValue)}`}
              </Text>
            </HeaderWrapper>
          ) : (
            <Text as="p" size="small" color="2d4a58" key={i}>
              No values
            </Text>
          ),
        )}
    </Box>
  );
};

export default Group;

import * as React from "react";
import styled, { css } from "styled-components-themed";
import { MdInfoOutline } from "react-icons/md";
import { FaInfoCircle } from "react-icons/fa";

const TitleToolTipsContainer = styled.div`
  display: flex;

  > div {
    display: flex;
    align-items: center;
  }
`;

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  ${(props: { show?: boolean }) =>
    !props.show
      ? css`
          display: none;
        `
      : css`
          animation: fadeInDown;
          animation-duration: 500ms;
        `}

  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
      visibility: hidden;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }
`;

const TooltipContent = styled.div`
  position: absolute;
  font-size: 13px;
  padding: 1.7rem;
  line-height: 1.5rem;
  min-width: 400px;
  color: #000;
  background: #ffffff;
  border: 1px solid #c5c5c5;
  border-radius: 6px;
  bottom: 16px;
  left: 50%;
  margin-left: -208px;
  white-space: pre-line;
  z-index: 999;

  &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-top: 6px solid #c5c5c5;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
  }

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -6px;
    width: 0;
    height: 0;
    border-top: 4px solid #ffffff;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
`;

const Tooltip = (props?: any) => {
  const { italicized, children, filled, size } = props;
  const [hover, setHover] = React.useState(false);

  return (
    <TitleToolTipsContainer>
      <div
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        {filled ? (
          <FaInfoCircle color="#1A333F" size={size} />
        ) : (
          <MdInfoOutline color="#238DC1" size={size} />
        )}
        <TooltipContainer show={hover}>
          {italicized ? (
            <TooltipContent dangerouslySetInnerHTML={{ __html: children }} />
          ) : (
            <TooltipContent>{children}</TooltipContent>
          )}
        </TooltipContainer>
      </div>
    </TitleToolTipsContainer>
  );
};

export default Tooltip;

import React from "react";

export interface Context {
  name: string;
  value: string;
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
}

const RadioGroupContext = React.createContext<null | Context>(null);

export default RadioGroupContext;

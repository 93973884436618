import { createGlobalStyle } from "styled-components-themed";
import { media } from "./utils/breakpoint";
import { theme } from "./theme";

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  :root {
    height: 100%;
    font-size: 12px;
    overflow-x: hidden;

    ${media.small`
      font-size: 14px;
    `}
    ${media.large`
      font-size: 16px;
    `}
  }

  body {
    height: 100vh;
    font-family: ${theme.fonts.base};
    letter-spacing: 0.1px;
    -webkit-font-smoothing: antialiased;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
  }

  a {
    cursor: pointer;
    text-decoration: none;
    color: ${theme.colors.blue};

    &:hover {
      text-decoration: underline;
      color: ${theme.colors.mediumBlue};
    }
  }

  p {
    font-size: 1rem;
  }
`;

import ApiClient from "./";

const userPath = "/api/user";

export function getUser() {
  return ApiClient.get(userPath);
}

export function createUser(idTokenPayload: { sub: string }) {
  return ApiClient.post(`${userPath}/create`, {
    userId: idTokenPayload.sub,
  });
}

export function updateUserMetadata(payload: {
  first_name: string;
  last_name: string;
}) {
  return ApiClient.put(userPath, payload);
}

import styled from "styled-components-themed";
import { Box, Paragraph } from "grommet";
import { media } from "shared-ui";

export const Wrapper = styled(Box)`
  padding-top: 2rem;
  flex-direction: row;
`;

export const ResultsParagraph = styled(Paragraph)`
  max-width: none;
  padding-top: 1rem;
  white-space: pre-line;
  font-size: 1.125rem;
`;

export const LeftColumn = styled.div`
  margin-top: 1.5rem;
  margin-right: 2rem;

  ${media.large`
    width: 62%;
  `}
`;

export const GroupContainer = styled.div`
  margin-bottom: 2rem;
`;

export const GroupTitleContainer = styled(Box)`
  border-bottom: 1px solid grey;
  padding: 1rem 0;
`;

export const GroupTitleContainerRightColumn = styled.div`
  display: flex;
  align-items: flex-end;
  text-align: right;
  > svg {
    margin-left: 0.5rem;
  }
`;

export const RightColumn = styled.div`
  background: #f7f9f8;
  border-radius: 0.375rem;
  height: 100%;

  ${media.large`
    width: calc(38% - 2rem);
  `}
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  border-bottom: 1px solid grey;
`;

export const ScoreLabel = styled.p`
  font-size: 0.75rem;
`;

export const ScoreContainer = styled.div`
  text-align: right;
`;

export const BottomSection = styled.div`
  padding: 1.5rem;
`;

export const BottomTitle = styled.div`
  display: flex;
  font-size: 1rem;
  font-weight: bold;
`;

export const ButtonsContainer = styled.div`
  padding: 1rem 0;
  text-align: center;
  font-size: 1.125rem;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
`;

import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import styled from "styled-components-themed";
import pick from "lodash/pick";
import { FaChevronRight } from "react-icons/fa";
import { Box, Button as ButtonBase, TextInput, Select } from "grommet";
import { Formik, Form, Field, FieldProps, ErrorMessage } from "formik";
import * as Yup from "yup";
// import { Debug } from "shared-ui";
import { updateOrganization } from "organization/src/reducer";
import { RootState } from "frontend-app/typings";
import { setNotification } from "frontend-app/src/reducers/app";
import missionAreas from "../../data/missionAreas";
import organizationBudgets from "../../data/organizationBudgets";
import organizationTypes from "../../data/organizationTypes";
import states from "../../data/states";

const ErrorP = styled.p`
  margin-bottom: 0.25rem;
  color: ${(props) => props.theme.colors.red};
`;

const Button = styled(ButtonBase)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem;
  text-transform: uppercase;
  border-radius: 0 0 0.25rem 0.25rem;
  background: #238dc1;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
`;

interface Props {
  organization: RootState["organizations"]["organization"];
  updateOrganizationAction: typeof updateOrganization;
  setNotificationAction: typeof setNotification;
}

const initialValues = {
  name: "",
  type: "",
  mission: "",
  budgetSize: "",
  city: "",
  state: "",
};

const CreateAccountForm: React.FunctionComponent<Props> = (props) => {
  const {
    organization,
    updateOrganizationAction,
    setNotificationAction,
  } = props;
  const organizationValues = pick(organization, Object.keys(initialValues));

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          organization: { ...initialValues, ...organizationValues },
        }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object({
          organization: Yup.object({
            name: Yup.string()
              .trim()
              .required("Name is required."),
            type: Yup.string()
              .trim()
              .required("Type is required."),
            mission: Yup.string()
              .trim()
              .required("Mission is required."),
            budgetSize: Yup.string()
              .trim()
              .required("Budget size is required."),
            city: Yup.string()
              .trim()
              .required("City is required."),
            state: Yup.string()
              .trim()
              .required("State is required."),
          }),
        })}
        onSubmit={async (values, actions) => {
          if (organization && organization._id) {
            try {
              await updateOrganizationAction(organization._id, {
                ...values.organization,
              });
              setNotificationAction("Successfully Updated!");
            } catch (error) {
              console.log("Error updating organization! " + error);
            }
          }
          actions.setSubmitting(false);
        }}
      >
        {(formikProps) => {
          return (
            <Form>
              <Box
                pad={{ horizontal: "medium" }}
                margin={{ bottom: "medium" }}
                gap="medium"
                width="medium"
              >
                <Field
                  name="organization.name"
                  render={({ field }: FieldProps<Props["organization"]>) => (
                    <React.Fragment>
                      <ErrorMessage
                        name="organization.name"
                        component={ErrorP}
                      />
                      <TextInput
                        placeholder="Full name of organization"
                        {...field}
                      />
                    </React.Fragment>
                  )}
                />
                <Field
                  name="organization.type"
                  render={({ field }: FieldProps<Props["organization"]>) => (
                    <React.Fragment>
                      <ErrorMessage
                        name="organization.type"
                        component={ErrorP}
                      />
                      <Select
                        {...field}
                        placeholder="Organization type"
                        options={organizationTypes}
                        onChange={({ value }) => {
                          formikProps.setFieldValue("organization.type", value);
                        }}
                      />
                    </React.Fragment>
                  )}
                />
                <Field
                  name="organization.mission"
                  render={({ field }: FieldProps<Props["organization"]>) => (
                    <React.Fragment>
                      <ErrorMessage
                        name="organization.mission"
                        component={ErrorP}
                      />
                      <Select
                        {...field}
                        placeholder="Mission area (NTEE category)"
                        options={missionAreas}
                        onChange={({ value }) => {
                          formikProps.setFieldValue(
                            "organization.mission",
                            value,
                          );
                        }}
                      />
                    </React.Fragment>
                  )}
                />
                <Field
                  name="organization.budgetSize"
                  render={({ field }: FieldProps<Props["organization"]>) => (
                    <React.Fragment>
                      <ErrorMessage
                        name="organization.budgetSize"
                        component={ErrorP}
                      />
                      <Select
                        {...field}
                        placeholder="Organization budget size"
                        options={organizationBudgets}
                        onChange={({ value }) => {
                          formikProps.setFieldValue(
                            "organization.budgetSize",
                            value,
                          );
                        }}
                      />
                    </React.Fragment>
                  )}
                />
                <Field
                  name="organization.city"
                  render={({ field }: FieldProps<Props["organization"]>) => (
                    <React.Fragment>
                      <ErrorMessage
                        name="organization.city"
                        component={ErrorP}
                      />
                      <TextInput placeholder="City" {...field} />
                    </React.Fragment>
                  )}
                />
                <Field
                  name="organization.state"
                  render={({ field }: FieldProps<Props["organization"]>) => (
                    <React.Fragment>
                      <ErrorMessage
                        name="organization.state"
                        component={ErrorP}
                      />
                      <Select
                        {...field}
                        placeholder="State"
                        options={states}
                        onChange={({ value }) => {
                          formikProps.setFieldValue(
                            "organization.state",
                            value,
                          );
                        }}
                      />
                    </React.Fragment>
                  )}
                />
              </Box>
              <Button type="submit" disabled={formikProps.isSubmitting}>
                Submit{" "}
                <FaChevronRight size="14px" style={{ marginLeft: "0.25rem" }} />
              </Button>
              {/* <Debug label="Form State" state={formikProps} /> */}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  organization: state.organizations.organization,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      updateOrganizationAction: updateOrganization,
      setNotificationAction: setNotification,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateAccountForm);

import React from "react";
import { FieldArray, Field, FieldProps } from "formik";
import { Text, Box } from "grommet";
import {
  Header,
  CurrencyInput,
  AddFieldButton,
  TrashButton,
} from "../../components";
import { StyledLabel, StyledTextInput } from "../../styles/Inputs";
import { Wrapper, RowBox } from "../../styles/Wrappers";
import {
  calculateTotalValues,
  calculateGroupAmount,
} from "@rr/utils/calculator/totals";
import fieldTypes from "../../data/fieldTypes";
import { longTermBusinessValueUniqueToProBonoPlaceholders } from "../../data/placeholders";

interface Props {
  values?: {
    [key: string]: string;
  }[];
}

const LongTermBusinessValueUniqueToProBono: React.FunctionComponent<
  Props
> = React.memo((props) => {
  const { values } = props;
  return (
    <Wrapper>
      <Header
        data-testid="ltbv-pro-bono-value"
        heading="Long-term Business Value Unique to Pro Bono"
        size="medium"
        level={4}
        amount={calculateGroupAmount(values) || "$0.00"}
        tooltipContent="<em>The indirect business value you will recognize only by completing the project with skilled volunteer resources.</em>\n\nConsider the leadership, functional and project management skill development that your staff will experience working alongside volunteers (e.g. project provides equivalent skill development to a $5K training) and the long-term relationships you may develop with volunteers or companies (e.g. each solidified corporate relationship represents $10K in earned revenue)."
      />
      <Text
        margin={{ vertical: "1rem" }}
        size="small"
        dangerouslySetInnerHTML={{
          __html:
            "Below are examples of the <u>types of long-term value</u> that your organization will only recognize through pro bono, as opposed to an outside contractor or internal development.  Fill in your own based on the project you are considering.  Then, input the monetary value of each business consideration.",
        }}
      />
      <FieldArray name="longTermBusinessValueUniqueToProBono">
        {(arrayHelpers) => (
          <React.Fragment>
            {values &&
              values.length > 0 &&
              values.map((value, index) => (
                <div key={index}>
                  <RowBox>
                    <Box width="80%">
                      <StyledLabel
                        display={index !== 0 ? "none" : "flex"}
                        htmlFor="description"
                      >
                        {"Description"}
                      </StyledLabel>
                    </Box>
                    <Box width="20%">
                      <StyledLabel
                        display={index !== 0 ? "none" : "flex"}
                        htmlFor="totalValue"
                      >
                        {"Total Value"}
                      </StyledLabel>
                    </Box>
                  </RowBox>
                  <RowBox>
                    <TrashButton onClick={() => arrayHelpers.remove(index)} />
                    <Box width="80%" margin={{ right: ".35rem" }}>
                      <Field
                        name={`longTermBusinessValueUniqueToProBono.${index}.description`}
                        render={({ field }: FieldProps) => (
                          <StyledTextInput
                            {...field}
                            placeholder={
                              longTermBusinessValueUniqueToProBonoPlaceholders[
                                index
                              ] || ""
                            }
                            id="description"
                            size="small"
                            type="text"
                          />
                        )}
                      />
                    </Box>
                    <Box width="20%">
                      <Field
                        name={`longTermBusinessValueUniqueToProBono.${index}.totalValue`}
                        render={({ field }: FieldProps) => (
                          <CurrencyInput
                            {...field}
                            id="totalValue"
                            placeholder="0.00"
                            onStateChange={(currency) => {
                              arrayHelpers.replace(index, {
                                ...value,
                                totalValue: currency,
                              });
                            }}
                            onBlur={(event) => {
                              field.onBlur(event);
                              arrayHelpers.replace(index, {
                                ...value,
                                totalValue: calculateTotalValues(
                                  "longTermBusinessValueUniqueToProBono",
                                  value,
                                ),
                              });
                            }}
                          />
                        )}
                      />
                    </Box>
                  </RowBox>
                </div>
              ))}
            {values && (
              <AddFieldButton
                onClick={() =>
                  arrayHelpers.push(
                    fieldTypes["longTermBusinessValueUniqueToProBono"],
                  )
                }
                text="Add long-term value"
              />
            )}
          </React.Fragment>
        )}
      </FieldArray>
    </Wrapper>
  );
});

LongTermBusinessValueUniqueToProBono.displayName =
  "LongTermBusinessValueUniqueToProBono";

export default LongTermBusinessValueUniqueToProBono;

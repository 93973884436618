import * as React from "react";

export const useAsync = (
  asyncFn: (...args: any) => Promise<any>,
  ...args: any[]
) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [result, setResult] = React.useState<any>();
  const [error, setError] = React.useState<any>();

  React.useEffect(() => {
    (async () => {
      try {
        const returnValue = await asyncFn(...args);
        setResult(returnValue);
      } catch (e) {
        console.error("useAsync error: ", e);
        setError(e);
      }
      setIsLoading(false);
    })();
  }, []);

  return {
    isLoading,
    result,
    error,
  };
};

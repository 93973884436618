import React from "react";

import { AssessmentContext } from "../Assessment/Store";
import { getAssessmentScore } from "../Assessment/utils";

import { assessmentMap as organizationAssessmentMap } from "../../data/organization";
import { assessmentMap as projectAssessmentMap } from "../../data/project";

import ResultItem from "./ResultItem";

const ResultList: React.FunctionComponent<{}> = () => {
  const { state } = React.useContext(AssessmentContext);
  const assessmentMap =
    state.type === "Project" ? projectAssessmentMap : organizationAssessmentMap;

  return (
    <React.Fragment>
      {state.questionsData.map((group, index) => {
        const score = state.scores[index + 1];
        const sectionAssessment = getAssessmentScore(group.assessments, score);
        const maxGroupPoints = group.assessments[0].between[1];
        const {
          icon: AssessmentGroupIcon,
          color: assessmentGroupColor,
        } = assessmentMap[
          sectionAssessment.label as keyof typeof assessmentMap
        ];
        return (
          <ResultItem
            key={index}
            type={state.type}
            group={group}
            score={score}
            maxGroupPoints={maxGroupPoints}
            sectionAssessment={sectionAssessment}
            assessmentGroupIcon={AssessmentGroupIcon}
            assessmentGroupColor={assessmentGroupColor}
          />
        );
      })}
    </React.Fragment>
  );
};

export default ResultList;

import * as React from "react";
import styled from "styled-components-themed";
import { Box, Layer } from "grommet";
import { Logo as LogoSVG } from "header";
import Form from "./Form";

interface Props {
  handleClose?: () => void;
  name?: string;
}

const LogoWrapper = styled.div`
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 1rem;
  background: rgba(241, 241, 241, 0.8);
  text-align: center;
`;

const Logo = styled(LogoSVG)`
  width: auto;
  height: 58px;
`;

const Content = styled.div`
  overflow-y: auto;
`;

const CreateAccount: React.FunctionComponent<Props> = (props) => {
  const { name } = props;
  return (
    <Layer modal={true} position="center">
      <Content>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <Box pad="medium" gap="small" width="medium">
          <p>Hi, {name}!</p>
          <p>Welcome to Capacity Commons.</p>
          <p>
            Before you continue, please provide a few details about your
            organization.
          </p>
        </Box>
        <Form />
      </Content>
    </Layer>
  );
};

export default CreateAccount;

import * as React from "react";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import { Box, Button } from "grommet";
import { FaPencilAlt } from "react-icons/fa";
import { Formik, Form, Field, FieldProps } from "formik";
import { RootState } from "frontend-app/typings";
import { QA } from "../../../../typings";
import { updateProject } from "../../../reducer";
import Question from "./Question";
import { Header, Heading, StyledEditButton } from "../styles";

interface Props {
  title: string;
  prefix: string;
  questions: QA[];
  project: RootState["projects"]["project"];
  updateProjectAction: typeof updateProject;
}

const QuestionsGroup: React.FunctionComponent<Props> = (props) => {
  const { title, prefix, questions, project, updateProjectAction } = props;
  const hasAnswers = questions.every((question) => question.answer !== "");
  // Display edit mode if questions have not been answered.
  const [showEditMode, setShowEditMode] = React.useState(!hasAnswers);

  if (!project) {
    return null;
  }

  return (
    <Box margin={{ vertical: "medium" }}>
      <Header>
        <Heading level={3}>{title}</Heading>
        {!showEditMode && (
          <StyledEditButton
            color=" #238DC1"
            plain={true}
            icon={<FaPencilAlt size="14px" color=" #238DC1" />}
            gap="xxsmall"
            label="Edit"
            onClick={() => {
              setShowEditMode(true);
            }}
          />
        )}
      </Header>
      {showEditMode ? (
        <Formik
          initialValues={{
            [prefix]: questions,
          }}
          onSubmit={async (values, actions) => {
            await updateProjectAction(project._id!, {
              [prefix]: values[prefix],
            });
            setShowEditMode(false);
            actions.setSubmitting(false);
          }}
        >
          {(formikProps) => {
            const { values, isSubmitting } = formikProps;
            const formStateHasAnswers = values[prefix].every(
              (question) => question.answer !== "",
            );

            return (
              <Form>
                <React.Fragment>
                  {values[prefix] &&
                    values[prefix].length > 0 &&
                    values[prefix].map((item, index) => (
                      <div key={index}>
                        <Field
                          name={`${prefix}.${index}.answer`}
                          render={({ field, form }: FieldProps) => {
                            return (
                              <Question
                                {...field}
                                {...item}
                                form={form}
                                key={item.question}
                                prefix={prefix}
                                index={index}
                              />
                            );
                          }}
                        />
                      </div>
                    ))}
                </React.Fragment>

                <Box align="end" margin={{ vertical: "medium" }}>
                  <Button
                    type="submit"
                    primary={true}
                    color="#228DC1"
                    label="Done"
                    disabled={isSubmitting || !formStateHasAnswers}
                  />
                </Box>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Box margin={{ vertical: "medium" }}>
          {questions
            .map((item) =>
              item.textarea
                ? item.textarea.leadingText + item.answer
                : item.answer,
            )
            .join(" ")}
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state: RootState) => ({
  project: state.projects.project,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ updateProjectAction: updateProject }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuestionsGroup);

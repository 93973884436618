import React from "react";
import styled from "styled-components-themed";
import get from "lodash/get";
import { connect } from "react-redux";
import { FaShareSquare } from "react-icons/fa";
import { Button as ButtonBase } from "shared-ui";
import { Box, Text, Paragraph, Heading, Anchor } from "grommet";
import { partnerInformation } from "../../pages/volunteers/data";
import { RootState } from "frontend-app/typings";

interface Props {
  project: RootState["projects"]["project"];
  userId: string;
}

const HeaderText = styled(Text)`
  font-size: 0.75rem;
  font-style: italic;
  color: #222222;
  line-height: 18px;
`;

const Image = styled.img`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`;

const TableRow = styled(Box)`
  flex-direction: row;
  justify-content: space-between;
`;

const Description = styled(Paragraph)`
  font-size: 0.875rem;
  line-height: 1.375rem;
`;

const Button = styled(ButtonBase)`
  border-color: #238dc1;
  color: #238dc1;
  padding: 0.5rem 1rem;
`;

const MatchingPartners: React.FunctionComponent<Props> = ({
  project,
  userId,
}) => {
  const matchedPartners = get(project, "matchedPartners", []);
  return (
    <Box margin={{ top: "2rem", bottom: "4rem" }}>
      <TableRow
        border={{ color: "#dddddd", size: "1px", side: "top" }}
        pad={{ vertical: ".5rem" }}
      >
        <Box basis="1/4" align="start">
          <HeaderText>Partners</HeaderText>
        </Box>
        <Box basis="1/2" align="start" margin={{ right: "large" }}>
          <HeaderText>Description</HeaderText>
        </Box>
        {matchedPartners.length > 0 ? (
          <Box basis="1/4" direction="row">
            <Box basis="1/2">
              <HeaderText>Prospective Matches</HeaderText>
            </Box>
            <Box basis="1/2">
              <HeaderText>Project Status</HeaderText>
            </Box>
          </Box>
        ) : (
          <Box basis="1/4" align="start">
            <HeaderText>Connect to Matching Partner</HeaderText>
          </Box>
        )}
      </TableRow>
      {partnerInformation.map((partner, i) => {
        const matchedPartner = matchedPartners.find(
          (matchedPartner) => matchedPartner.partner === partner.name,
        );
        return (
          <TableRow
            border={{
              color: "#dddddd",
              size: "1px",
              side: i === partnerInformation.length - 1 ? "horizontal" : "top",
            }}
            pad={{ vertical: "1rem" }}
            key={partner.name}
          >
            <Box basis="1/4" align="start">
              <Anchor target="_blank" href={partner.image.url}>
                <Image
                  src={partner.image.src}
                  alt={partner.image.alt}
                  height={partner.image.height}
                  width={partner.image.width}
                />
              </Anchor>
            </Box>
            <Box basis="1/2" align="start" margin={{ right: "large" }}>
              <Description dangerouslySetInnerHTML={partner.description} />
            </Box>
            {matchedPartner ? (
              <Box basis="1/4" direction="row">
                <Box basis="1/2" align="start">
                  <Heading
                    color="#00B189"
                    level="3"
                    style={{ textTransform: "capitalize" }}
                  >
                    {matchedPartner.status.toLowerCase() !== "matched" &&
                      matchedPartner.status.toLowerCase() !== "completed" &&
                      matchedPartner.matches}
                  </Heading>
                </Box>
                <Box basis="1/2" align="start">
                  <Heading
                    color="#00B189"
                    level="3"
                    style={{ textTransform: "capitalize" }}
                    margin="0 0 .25rem 0"
                  >
                    {matchedPartner.status}
                  </Heading>
                  <Button
                    reverse={true}
                    icon={<FaShareSquare />}
                    label="View"
                    onClick={() => {
                      partner.onClick({
                        auth_id: userId,
                        project_id: project!._id,
                      });
                    }}
                  />
                </Box>
              </Box>
            ) : (
              <Box basis="1/4" align="start">
                <Button
                  onClick={() => {
                    partner.onClick({
                      auth_id: userId,
                      project_id: project!._id,
                    });
                  }}
                >
                  Connect
                </Button>
              </Box>
            )}
          </TableRow>
        );
      })}
    </Box>
  );
};

const mapStateToProps = (state: RootState) => ({
  userId: state.session.userId,
  project: state.projects.project,
});

export default connect(mapStateToProps)(MatchingPartners);

import React from "react";
import { Provider } from "react-redux";
import initStore from "./store";

const isBrowser = typeof window !== "undefined";

// eslint-disable-next-line react/display-name
export default ({ element }: any) => {
  const store = initStore();

  // expose redux store in Cypress
  if (isBrowser && window.Cypress) {
    window.store = store;
  }

  return <Provider store={store}>{element}</Provider>;
};

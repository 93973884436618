import * as React from "react";
import { WindowLocation } from "@reach/router";
import { Spinning } from "shared-ui";
import AuthService from "../services/Auth";

interface Props {
  location: WindowLocation;
}

const Callback: React.FunctionComponent<Props> = (props) => {
  const { location } = props;

  React.useEffect(() => {
    (async () => {
      try {
        if (/access_token|id_token|error/.test(location.hash)) {
          await AuthService.handleAuthentication();
        }
      } catch (error) {
        if (window && window.rg4js) {
          window.rg4js("send", error);
        }
        console.log("AuthService Error: ", error);
        window.location.href = "/";
      }
    })();
  }, []);

  return <Spinning />;
};

export default Callback;

import React from "react";
import { Router } from "@reach/router";
import { ProtectedRoute } from "shared-ui";
import Home from "./pages/Home";

const Routes = ({ basepath = "" }) => {
  return (
    <Router basepath={basepath} primary={false}>
      <ProtectedRoute component={Home} path="/" />
    </Router>
  );
};

export default Routes;

import * as React from "react";
import styled from "styled-components-themed";
import queryString from "query-string";
import { NavigateFn, WindowLocation } from "@reach/router";
import { createProject } from "api/projects";

interface Props {
  navigate: NavigateFn;
  location: WindowLocation;
}

const Wrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 70vh;
`;

const New: React.FunctionComponent<Props> = (props) => {
  const { navigate, location } = props;
  const parsed = queryString.parse(location.search);
  const tool = parsed.redirect || "scope";

  React.useEffect(() => {
    (async () => {
      try {
        const response = await createProject();
        const { data } = response.data;
        console.log("response", response);
        navigate(`/projects/${data._id}/${tool}`, { replace: true });
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    })();
  }, []);

  return (
    <Wrapper>
      <h2>Creating a new project...</h2>
    </Wrapper>
  );
};

export default New;

import styled from "styled-components-themed";
import { media } from "../../styles/utils/breakpoint";

const Container = styled.div`
  margin: 0 auto;
  max-width: 37.5rem;
  padding: 0 1.25rem;

  ${media.large`
    max-width: 82.8125rem;
  `};
`;

export default Container;

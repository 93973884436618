import React from "react";
import { Link } from "@reach/router";
import styled from "styled-components-themed";
import { Button } from "grommet";
import { Anchor, media } from "shared-ui";
import { Pagination as IPagination } from "../../pages/Assessment/utils";
import { MdArrowForward } from "react-icons/md";

interface Props {
  pagination: IPagination[];
  currentStep: number;
  totalSteps: number;
  isNextEnabled: boolean;
  onSubmit: () => void;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PaginationItems = styled.div`
  display: none;

  ${media.large`
    display: flex;
  `}
`;

const PaginationItem = styled.div`
  margin: 0.75rem;
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.text};

    &.active {
      color: #01b189;
      font-weight: 600;
    }
    &.clickable {
      color: ${(props) => props.theme.colors.blue};
    }
    &:not(.clickable) {
      pointer-events: none;
    }
  }
`;

const Pagination: React.FunctionComponent<Props> = (props) => {
  const {
    pagination,
    currentStep,
    totalSteps,
    isNextEnabled,
    onSubmit,
  } = props;
  return (
    <Wrapper>
      {currentStep === 1 ? (
        <Anchor as={Link} to="../">
          <Button label="Cancel" />
        </Anchor>
      ) : (
        <Anchor as={Link} to={`${currentStep - 1}`}>
          <Button label="Back" />
        </Anchor>
      )}
      <PaginationItems>
        {pagination.map((item) => (
          <PaginationItem key={item.step}>
            <Link
              to={`${item.step}`}
              getProps={({ isCurrent }) => {
                return isCurrent
                  ? { className: "active" }
                  : { className: item.step < currentStep ? "clickable" : "" };
              }}
            >
              {item.first} - {item.last}
            </Link>
          </PaginationItem>
        ))}
      </PaginationItems>
      {currentStep === totalSteps ? (
        <Button
          onClick={onSubmit}
          disabled={!isNextEnabled}
          primary={true}
          color="#228DC1"
          label="Submit"
        />
      ) : (
        <Anchor as={Link} to={`${currentStep + 1}`}>
          <Button
            label="Next"
            primary={true}
            disabled={!isNextEnabled}
            color="#228DC1"
            icon={<MdArrowForward />}
            reverse={true}
          />
        </Anchor>
      )}
    </Wrapper>
  );
};

export default Pagination;

export default {
  "Defined Project Goals": {
    blurb:
      "Having defined project goals means you are able to articulate how the skills-based volunteering project will contribute to your long-term success and address your core needs. Projects that have clearly defined goals result in quality final deliverables that have a measurable, lasting impact on your organization and the communities you serve.",
    resources: [
      "Walk through a guided exercise with our <a target='_blank' href='https://capacitycommons.org/guidebook/scope/scope-your-project/'>Project Scoping Tool</a> to anchor your project in key strategic objectives.",
      "<a target='_blank' href='https://www.smartsheet.com/blog/essential-guide-writing-smart-goals'>SMART Goals</a> - We encourage you to develop project goals that are specific, measurable, attainable, relevant and time-bound. Learn more about SMART Goals.",
    ],
  },
  "Defined Project Scope": {
    blurb:
      "A defined project scope keeps volunteers focused on the core mandate for the project and helps to counteract risk of “scope creep,” which can dilute a volunteer’s ability to meet your most pressing needs in a timely manner. Before your project kicks off, you should be able to clearly articulate what success will look like on this project, down to the deliverable or “thing” you’ll have in hand. Projects that are cohesive, address a core issue within your organization and take into account the time commitment and capacity of the volunteer(s) are ready to move forward.",
    resources: [
      "Walk through a guided exercise with our <a target='_blank' href='https://capacitycommons.org/guidebook/scope/scope-your-project/'>Project Scoping Tool</a> to define concise, clear project focus and boundaries.",
      "Refer to our <a target='_blank' href='https://capacitycommons.org/guidebook/scope/view-sample-projects/'>library of sample projects</a> for some examples of common project scopes that have been successful for peer organizations.",
    ],
  },
  "Project Prioritization": {
    blurb:
      "Pro bono projects require an investment of time and expertise from your staff and from volunteers. This investment is worthwhile if you have guaranteed project prioritization. You should be certain that the project will render a significant impact on your organization’s operations or programs and your leadership and staff should be willing to invest the necessary resources to see that impact to fruition.",
    resources: [
      "Walk through a guided exercise with our <a target='_blank' href='https://capacitycommons.org/guidebook/scope/scope-your-project/'>Project Scoping Tool</a> to anchor your project in key strategic objectives.",
      "Refer to this <a target='_blank' href='https://capacitycommons.org/guidebook/prepare/make-the-internal-business-case/'>internal business case</a> to gain the necessary buy-in from your organization’s leadership for skills-based volunteering and capacity-building.",
      "Utilize our <a target='_blank' href='https://capacitycommons.org/guidebook/scope/value-your-project/'>Pro Bono Calculator</a> to quantify the value of your proposed project.",
    ],
  },
  "Project Leadership": {
    blurb:
      "Every project needs quality project leadership and a skills-based volunteering engagement is no different! The individual who manages your pro bono project should have extensive knowledge and experience in the challenge or set of challenges that the project seeks to address, the determined capacity to lead the project and the authority within your organization to make or swiftly facilitate informed decisions.",
    resources: [
      "See our <a target='_blank' href='https://capacitycommons.org/guidebook/implement/identify-the-right-project-manager/'>tips for identifying the right project manager</a> as you’re selecting the right person for the job.",
      "Check out these <a target='_blank' href='https://capacitycommons.org/guidebook/implement/manage-your-project-effectively/'>project management best practices</a> to set your project leadership up for success.",
    ],
  },
  "Post-Project Support Plan": {
    blurb:
      "The end of a skills-based volunteer project is not the end of the project itself. After your volunteer(s) hand off the deliverable, it will be up to you as an organization to ensure it  is implemented. To do so, you will need a strategic and thoughtful post-project support plan. This requires a commitment to find the right resources (budget, staff, expertise) to support the roll out, maintenance, and evolution of the project deliverable.",
    resources: [
      "Refer to our <a target='_blank' href='https://capacitycommons.org/guidebook/implement/prepare-for-your-project-hand-off/'>project hand-off checklist</a> to ensure you are planning proactively for the volunteer to pass-off of the project.",
      "Consider how effective <a target='_blank' href='https://capacitycommons.org/guidebook/evaluate/tell-your-pro-bono-story/'>storytelling</a> could result in additional funding or support for your project.",
    ],
  },
};

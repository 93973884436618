import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import styled, { css } from "styled-components-themed";
import { Link } from "@reach/router";
import { RootState } from "frontend-app/typings";
import { Project } from "../../../typings";
import {
  volunteerInformation,
  imgsToBeResized,
} from "../../pages/volunteers/data";
import { loadProject } from "../../reducer";
import { Box, Heading, Text, Paragraph } from "grommet";
import { FaShareSquare, FaHome } from "react-icons/fa";
import { Anchor, Breadcrumbs, Tooltip, useEventSource } from "shared-ui";
import defaultImage from "frontend-app/src/images/img-discover-volunteer.svg";
import MatchingPartners from "./MatchingPartners";

const backgroundColors = ["#e6f9f8", "#fff", "#f0fff0"];

const RightColumn = styled.div``;

const TitleToolTipsHeading = styled(Heading)`
  max-width: 100%;
  margin-right: 0.75rem;
`;

const VolunteersParagraph = styled(Paragraph)`
  max-width: none;
  padding: 0.5rem 0;
  font-size: 1.125rem;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const ExtendedBox = styled(Box)`
  background-color: ${(props: { colorIndex?: any }) =>
    backgroundColors[props.colorIndex]};
  height: 100%;
  position: relative;

  :before {
    content: "";
    background-color: ${(props: { colorIndex?: any }) =>
      backgroundColors[props.colorIndex]};
    position: absolute;
    height: 100%;
    width: 250rem;
    left: -125rem;
    z-index: -1;
  }
`;

const SquareCard = styled(Box)`
  border: 1px solid #b6ccc2;
  background-color: white;
  width: 12.5rem;
  height: 12.5rem;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
  margin-right: 0.625rem;
  margin-bottom: 0.625rem;
  position: relative;

  > img {
    ${(props: { removeImgWidth?: boolean }) => {
      return (
        props.removeImgWidth &&
        css`
          width: initial;
        `
      );
    }}
  }
`;

const LinkContainer = styled.a`
  color: #000;

  &:hover {
    color: #000;
    text-decoration: none;
  }
`;

const ShareIcon = styled.div`
  position: absolute;
  right: 0.625rem;
  bottom: 0.25rem;
`;

interface Props {
  project?: Project;
  projectId: string;
  loadProjectAction: typeof loadProject;
}

const VolunteerRightColumn: React.FunctionComponent<Props> = ({
  project,
  projectId,
  loadProjectAction,
}) => {
  const data = useEventSource({
    endpoint: `/api/projects/${projectId}/updates`,
    eventName: "project-event",
  });

  React.useEffect(() => {
    (async () => {
      try {
        if (data) {
          await loadProjectAction(data._id);
        }
      } catch (error) {
        console.log(`Error loading project ${error}`);
      }
    })();
  }, [data]);

  if (!project) {
    return null;
  }

  return (
    <RightColumn>
      <Breadcrumbs>
        <Anchor href="https://www.capacitycommons.org/">
          <FaHome size="16px" color="#238DC1" />
        </Anchor>
        <Anchor as={Link} to="/">
          My Projects
        </Anchor>
        <div>{project.name}</div>
      </Breadcrumbs>
      <Box direction="row" margin={{ vertical: "medium" }}>
        <Box basis="2/3" margin={{ right: "2rem" }}>
          <Heading
            level="1"
            margin={{ bottom: "1.5rem", top: "1rem" }}
            color="#244B5A"
          >
            Discover Volunteer Sources
          </Heading>
        </Box>
        <Box basis="1/3">
          <Image alt="Find volunteer image" src={defaultImage} />
        </Box>
      </Box>
      <Box>
        <Box margin={{ bottom: "2rem" }}>
          <VolunteersParagraph>
            Finding the right skills-based volunteer is typically a more
            involved process than finding a hands-on volunteer. You{"'"}re not
            just looking for an alignment of availability but also an alignment
            of expertise, perspective and interest. Don{"'"}t let this
            intimidate you - there are several common sources of pro bono
            support, which will simplify your search for the right volunteer. If
            you feel you need additional guidance to land a volunteer that suits
            your needs, you might also consider leveraging the support of a{" "}
            <Anchor
              target="_blank"
              href="https://capacitycommons.org/guidebook/source/volunteer-recruiter-job-description/"
            >
              Volunteer Recruiter
            </Anchor>{" "}
            to support you with this process.
          </VolunteersParagraph>
          <VolunteersParagraph>
            As you consider the source that will serve you best, we recommend
            beginning with individuals or institutions with which you already
            have connections. It may be easier to broach the subject of
            receiving pro bono services with a board member, donor, volunteer or
            corporate partner who is already invested in your organization{"'"}s
            success.
          </VolunteersParagraph>
          <VolunteersParagraph>
            When considering your volunteer options, be discriminating until you
            {"'"}ve found a strong two-way fit.
            <Anchor
              target="_blank"
              href="https://capacitycommons.org/guidebook/source/assess-volunteer-fit/"
            >
              {" "}
              Read more about assessing volunteer fit here
            </Anchor>
            .
          </VolunteersParagraph>
        </Box>
      </Box>
      <Heading
        level="2"
        margin={{ bottom: "1.5rem", top: "1rem" }}
        color="#244B5A"
      >
        Connect To Our Matching Partners
      </Heading>
      <VolunteersParagraph>
        Post the projects you{"'"}ve developed on Capacity Commons to an online
        matching platform to be connected with qualified volunteers. You{"'"}ll
        be able to monitor the status of your pro bono projects on Capacity
        Commons by tracking the prospective volunteer matches that come in
        across different platforms and projects.
      </VolunteersParagraph>
      <MatchingPartners />
      <Heading
        level="2"
        margin={{ bottom: "1.5rem", top: "1rem" }}
        color="#244B5A"
      >
        Common Sources of Skilled Volunteers
      </Heading>
      {volunteerInformation.map((dataGroup, dataGroupIndex) => {
        return (
          <ExtendedBox key={dataGroup.mainTitle} colorIndex={dataGroupIndex}>
            <Box
              border={{ side: "bottom", color: "grey" }}
              margin={{ top: "3rem", bottom: "2rem" }}
            >
              <Heading level="3" color="#00B189">
                {dataGroup.mainTitle}
              </Heading>
            </Box>

            {dataGroup.data.map((data, index) => {
              return (
                <div key={index}>
                  <Box direction="row" margin={{ bottom: "1rem" }}>
                    <TitleToolTipsHeading level="4" color="#244B5A">
                      <div>{data.title}</div>
                    </TitleToolTipsHeading>
                    <Tooltip filled size="16px">
                      {data.tooltipInfo}
                    </Tooltip>
                  </Box>
                  <VolunteersParagraph margin={{ bottom: "3rem" }}>
                    {data.description}
                  </VolunteersParagraph>
                  <Box direction="row" wrap={true} margin={{ bottom: "4rem" }}>
                    {data.images.map((image?: any, imageIndex?: any) => {
                      const { imageTitle, imageUrl } = data;
                      return (
                        <div key={imageIndex}>
                          {imageUrl[imageIndex] ? (
                            <LinkContainer
                              target="_blank"
                              href={imageUrl[imageIndex]}
                            >
                              <SquareCard
                                removeImgWidth={imgsToBeResized.includes(
                                  imageTitle[imageIndex],
                                )}
                              >
                                <Image
                                  alt={imageTitle[imageIndex]}
                                  src={image}
                                />
                                <Text>{imageTitle[imageIndex]}</Text>
                                <ShareIcon>
                                  <FaShareSquare color="#bbb" />
                                </ShareIcon>
                              </SquareCard>
                            </LinkContainer>
                          ) : (
                            <SquareCard
                              removeImgWidth={imgsToBeResized.includes(
                                imageTitle[imageIndex],
                              )}
                            >
                              <Image alt={imageTitle[imageIndex]} src={image} />
                              <Text margin={{ top: "0.3125rem" }}>
                                {imageTitle[imageIndex]}
                              </Text>
                            </SquareCard>
                          )}
                        </div>
                      );
                    })}
                  </Box>
                </div>
              );
            })}
          </ExtendedBox>
        );
      })}
      <ExtendedBox colorIndex="2">
        <VolunteersParagraph margin={{ bottom: "4rem" }}>
          Utilize this resource from Taproot Foundation to understand the{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://my.taprootfoundation.org/docs/competencies_map/AppendicesofOccupations.pdf"
          >
            skills associated with different job titles
          </a>
        </VolunteersParagraph>
      </ExtendedBox>
    </RightColumn>
  );
};

const mapStateToProps = (state: RootState) => ({
  project: state.projects.project,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      loadProjectAction: loadProject,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VolunteerRightColumn);

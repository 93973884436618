import React from "react";
import styled from "styled-components-themed";

interface Props {
  src?: string;
  alt?: string;
  name: string;
  width?: string;
  height?: string;
}

const CircleContainer = styled.div<{ height?: string; width?: string }>`
  border-radius: 50%;
  overflow: hidden;
  height: ${(props) => props.height || "3.125rem"};
  width: ${(props) => props.width || "3.125rem"};
  cursor: pointer;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

const DefaultAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.lightBlue};
  color: #fff;
`;

const Avatar: React.FunctionComponent<Props> = (props) => {
  const { src, alt, name, width, height } = props;
  const initials = name
    .trim()
    .split(" ")
    .map((name, i, array) => {
      if (i === 0 || i === array.length - 1) {
        return name.charAt(0).toUpperCase();
      }
    })
    .join("");
  return (
    <CircleContainer width={width} height={height}>
      {src ? (
        <StyledImage src={src} alt={alt} />
      ) : (
        <DefaultAvatar>{initials}</DefaultAvatar>
      )}
    </CircleContainer>
  );
};

export default Avatar;

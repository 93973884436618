import axios from "axios";
import { AuthService } from "authentication";
const API_URL = process.env.API_URL || "http://localhost:3000";
const MAX_RETRY_ATTEMPT = 10;

let retryAttemptCount = MAX_RETRY_ATTEMPT;

const ApiClient = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
  },
});

ApiClient.interceptors.response.use(
  (response: any) => {
    retryAttemptCount = MAX_RETRY_ATTEMPT;
    return response;
  },
  async (error) => {
    if (
      error.config &&
      error.response &&
      error.response.status === 401 &&
      retryAttemptCount > 0
    ) {
      console.warn(
        `Refreshing session and trying again... (${retryAttemptCount} attempts left)`,
      );
      retryAttemptCount = retryAttemptCount - 1;
      await AuthService.checkSession();
      return ApiClient.request(error.config);
    }
    return Promise.reject(error);
  },
);

export const setAuthToken = (token: string | undefined) => {
  ApiClient.defaults.headers.common["Authorization"] = Boolean(token)
    ? `Bearer ${token}`
    : undefined;
};

export default ApiClient;

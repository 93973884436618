import * as React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { Grommet } from "grommet";
import styled from "styled-components-themed";
import { GlobalStyles, theme, Container, Footer, Spinning } from "shared-ui";
import { useAuth } from "authentication";
import { CreateAccount } from "account";
import isBrowser from "@rr/utils/isBrowser";
import Header from "header";
import { setSession, Session } from "../reducers/session";
import { RootState } from "../reducers";
import Notification from "../components/Notification";

interface Props {
  session: Session;
  organization: RootState["organizations"]["organization"];
  setSessionAction: typeof setSession;
  notification: string;
}

const StyledContainer = styled(Container)`
  min-height: calc(100vh - 31.25rem);
`;

const Layout: React.FunctionComponent<Props> = ({
  children,
  session,
  organization,
  setSessionAction,
  notification,
}) => {
  const [showCreateAccount, setShowCreateAccount] = React.useState(false);
  const {
    isLoading,
    isLoggedIn,
    email,
    firstName,
    lastName,
    roles,
    userId,
  } = useAuth();

  React.useEffect(() => {
    setShowCreateAccount(Boolean(organization && !organization.name));
  }, [organization]);

  React.useEffect(() => {
    // Call setSessionAction only when one of the dependency changes.
    if (
      session.isLoading !== isLoading ||
      session.isLoggedIn !== isLoggedIn ||
      session.email !== email ||
      session.firstName !== firstName ||
      session.lastName !== lastName ||
      session.roles !== roles ||
      session.userId !== userId
    ) {
      setSessionAction({
        isLoading,
        isLoggedIn,
        email,
        firstName,
        lastName,
        roles,
        userId,
      });

      if (window && window.rg4js) {
        window.rg4js("setUser", {
          identifier: userId,
          isAnonymous: false,
          email: email,
          firstName: firstName,
          fullName: `${firstName} ${lastName}`,
        });
      }
    }
  }, [
    isLoading,
    isLoggedIn,
    email,
    firstName,
    lastName,
    roles,
    setSessionAction,
    userId,
  ]);

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(): JSX.Element => (
        <Grommet theme={theme} plain={true}>
          <Helmet>
            <script type="text/javascript">
              {`
  !function(a,b,c,d,e,f,g,h){a.RaygunObject=e,a[e]=a[e]||function(){
  (a[e].o=a[e].o||[]).push(arguments)},f=b.createElement(c),g=b.getElementsByTagName(c)[0],
  f.async=1,f.src=d,g.parentNode.insertBefore(f,g),h=a.onerror,a.onerror=function(b,c,d,f,g){
  h&&h(b,c,d,f,g),g||(g=new Error(b)),a[e].q=a[e].q||[],a[e].q.push({
  e:g})}}(window,document,"script","//cdn.raygun.io/raygun4js/raygun.min.js","rg4js");
`}
            </script>
          </Helmet>
          <GlobalStyles />
          {isBrowser && <Header />}
          <StyledContainer>
            <main>{isLoading ? <Spinning /> : children}</main>
          </StyledContainer>
          {isBrowser && <Footer />}
          {showCreateAccount && (
            <CreateAccount name={session.fullName || email} />
          )}
          {notification && <Notification />}
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `
  rg4js('apiKey', 'KZNBsbFWAXdIp56PJnnMkA');
  rg4js('enableCrashReporting', true);`,
            }}
          />
        </Grommet>
      )}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  session: state.session,
  organization: state.organizations.organization,
  notification: state.app.notification,
});

const mapDispatchToProps = {
  setSessionAction: setSession,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Layout);

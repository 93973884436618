import React from "react";
import styled from "styled-components-themed";
import { NavigateFn } from "@reach/router";
import {
  OrganizationLeftColumn,
  OrganizationRightColumn,
} from "../components/Organization";
import { ProjectHome } from "../components/Project";
import { AssessmentContext } from "./Assessment/Store";
import { media } from "shared-ui";

const Wrapper = styled.div`
  padding-top: 2rem;
  margin-bottom: 4rem;

  ${media.large`
    display: flex;
  `}
`;

const Home: React.FunctionComponent<{ navigate: NavigateFn }> = (props) => {
  const { navigate } = props;
  const { state } = React.useContext(AssessmentContext);

  React.useEffect(() => {
    if (state.assessmentLoaded) {
      navigate("../results", { replace: true });
    }
  }, [state.assessmentLoaded]);

  return (
    <Wrapper>
      {state.type === "Organization" ? (
        <React.Fragment>
          <OrganizationLeftColumn />
          <OrganizationRightColumn />
        </React.Fragment>
      ) : (
        <ProjectHome />
      )}
    </Wrapper>
  );
};

export default Home;

const organizationBudgets = [
  "Less than $500,000",
  "$500,000 to $999,999",
  "$1 million to $2.99 million",
  "$3 million to $4.99 million",
  "$5 million to $10 million",
  "$10 million +",
];

export default organizationBudgets;

import React from "react";
import { Router } from "@reach/router";
import { Route } from "shared-ui";
// import useAuth from "./hooks/useAuth";
// import Home from "./pages/Home";
import Callback from "./pages/Callback";

const Routes = ({ basepath = "" }) => {
  return (
    <Router basepath={basepath}>
      {/* <Route component={Home} path="/" auth={auth} /> */}
      <Route component={Callback} path="callback" />
    </Router>
  );
};

export default Routes;

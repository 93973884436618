export const toCents = (string: string) => {
  if (Number.isNaN(parseFloat(string))) {
    return 0;
  }
  return Number.parseFloat(string) * 100;
};

export const toDecimalString = (number: number) => {
  let inDollars = number / 100;
  if (Number.isNaN(inDollars)) {
    return "0.00";
  }
  return inDollars.toFixed(2);
};

export const toUSD = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

export const displayUSD = (value: any) => {
  let amount;
  if (typeof value === "string") {
    amount = toCents(value) / 100;
  } else {
    amount = value / 100;
  }
  return toUSD.format(amount);
};
